import axios, {AxiosResponse} from "axios"
import {Mocks} from '../../NegotiationPortal/components/Receivable/ListPanel/mocks'
import { delay } from "../utils/delay"
import { GatewayError, TGatewayError } from "./errors"
import negotiationOptionConverter, {TNegotiationOptionResponse} from "./converters/negotiationOptionConverter"
import { NegotiationOption } from "../entities/NegotiationOption/NegotiationOption"
import { Receivable } from "../entities/Receivable/Receivable"
import { Agreement } from "../entities/Agreement/Agreement"
import agreementConverter, { TAgreementResponse } from "./converters/agreementConverter"
import { Settings } from '../settings'

interface INegotiationOptionGateway {
    documentNumber: string
}
interface IAgreementsGateway {
    documentNumber: string
}


export const negotiationOptionGateway = async (
    {
        documentNumber
    }: INegotiationOptionGateway
): Promise<[NegotiationOption[], Receivable[]] | TGatewayError> => {
    if(Settings.env == 'production') {
        if(Settings.SERVER_HOST) {
            const walletUUID = Settings.walletUUID
            const response: AxiosResponse<TNegotiationOptionResponse> = await axios.get(
                `${Settings.SERVER_HOST}/api/v1/${walletUUID}/client/${documentNumber}/negotiation_options`,
            )
            if(response.data.payload){
                return negotiationOptionConverter(response.data)
            }else{
                return GatewayError('EXT01')
            }
            
        } else {
            return GatewayError('DEV101')
        }
    } else {
        await delay(1000)
        // return [Mocks.negotiationOptions, Mocks.receivables]
        return negotiationOptionConverter(Mocks.getNegotiationOptionsResponse)
    }
}


export const agreementsGateway = async (
    {
        documentNumber
    }: IAgreementsGateway
): Promise<[Agreement[], Receivable[]] | TGatewayError> => {
    if(Settings.env == 'production') {
        const serverHost: string | undefined = Settings.SERVER_HOST
        const walletUUID = Settings.walletUUID
        if(serverHost) {
            const response: AxiosResponse<TAgreementResponse> = await axios.post(
                `${serverHost}/api/v1/${walletUUID}/client/${documentNumber}/agreements`,
            )

            return agreementConverter(response.data)
        } else {
            return GatewayError('DEV101')
        }
    } else {
        await delay(1000)
        return [Mocks.agreements, Mocks.receivables]
    }
}