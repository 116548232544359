import { Agreement } from "../../entities/Agreement/Agreement";
import { Installment } from "../../entities/Receivable/Installment";
import { Payment } from "../../entities/Receivable/Payment";
import { Receivable } from "../../entities/Receivable/Receivable";


type TAgreementDTO = {
    uuid: string,
    original_receivables_uuids: string[] | null
    current_receivable_uuid: string
}

type TReceivableDTO = {
    original_receivable_value: number,
    product: {
        name: string,
        description: string | null
    },
    fee: number,
    interest: number,
    fine: number,
    taxes: number,
    current_value: number,
    status: string,
    installments: TInstallmentDTO[],
}

type TInstallmentDTO = {
    uuid: string,
    installment_number: number,
    initial_value: number,
    fee: number,
    interest: number,
    fine: number,
    taxes: number,
    current_value: number,
    due_date: string,
    payment?: {
        payment_info? : {
            digit_line: string | null,
            due_date: string | null,
            url: string | null
        },
        downpayment?: boolean | null
        payment_date: string | null,
        paid_value: number | null,
        payment_method: string,
    } | null,
    payment_option_data?: any,
    status: string
}

export type TAgreementResponse = { 
    payload: {
        agreements: TAgreementDTO[],
        aggregates: {
            receivables: { [uuid: string]: TReceivableDTO } | null
        }
    },
    errors: [] | null
}

const agreementConverter = (
    dto: TAgreementResponse
    ): [
        Agreement[], 
        Receivable[]
    ] => {
    
    const receivableDTOs = dto.payload.aggregates.receivables
    const receivables: Receivable[] = []

    if(receivableDTOs){
        const receivableKeys = Object.keys(receivableDTOs)

        const buildReceivable = (receivableDTO: TReceivableDTO, uuid: string): Receivable => {
            return {
                uuid: uuid,
                // @ts-ignore
                currentValue: receivableDTO.current_value,
                // @ts-ignore
                downpayment: receivableDTO.downpayment,
                // @ts-ignore
                fee: receivableDTO.fee,
                // @ts-ignore
                fine: receivableDTO.fine,
                // @ts-ignore
                product: receivableDTO.product,
                // @ts-ignore
                status: receivableDTO.status,
                // @ts-ignore
                installments: receivableDTO.installments.map((dto) => (
                    {
                        type: 'Installment',
                        uuid: dto.uuid,
                        installmentNumber: dto.installment_number.toString(),
                        dueDate: dto.due_date,
                        fee: dto.fee,
                        fine: dto.fine,
                        interest: dto.interest,
                        taxes: dto.taxes,
                        initialValue: dto.initial_value,
                        currentValue: dto.current_value,
                        status: dto.status,
                        originalReceivableValue: dto.initial_value,
                        payment: {
                            paidValue: dto.payment?.paid_value,
                            paymentDate: dto.payment?.payment_date,
                            paymentInfo: dto.payment?.payment_info,
                            paymentMethod: dto.payment?.payment_method
                        } as Payment
                    }
                )) as Installment[]
            } as Receivable
        }

        for(const key in receivableKeys) {
            const uuid = receivableKeys[key]
            const receivable = receivableDTOs[uuid as keyof typeof receivableDTOs]
            if(receivable) {
                receivables.push(
                    buildReceivable(receivable, uuid)
                )
            }
        }
    }

    const agreements = dto.payload.agreements.map(agreement => {

        return {
            type: 'Agreement',
            uuid: agreement.uuid,
            originalReceivablesUUID: agreement.original_receivables_uuids,
            currentReceivableUUID: agreement.current_receivable_uuid
        } as Agreement
    })

    return [agreements, receivables]
}


export default agreementConverter;