import React, { ReactNode } from "react";
import { PreAgreement } from "../../../../entities/PreAgreement/PreAgreement";
import { formatBRValue } from "../../../../utils/valueFormatters/formatBRValue";
import { InstallmentsOptionsSectionType } from "./interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDivide } from "@fortawesome/free-solid-svg-icons";
import LoadingComponent from "../../../LoadingComponent";
import { NegotiationOption } from "../../../../entities/NegotiationOption/NegotiationOption";
import { string } from "prop-types";

export const InstallmentsOptionsSection = ({
    negotiationOption,
    handleChangeFunction,
    isLoading,
    selectedInstallmentNumber,
    preAgreement,
    selectedPaymentMethod,
    negotiationLimits
} : InstallmentsOptionsSectionType) => {

    const _returnInstallmentsOptions = (): ReactNode => {

        let listOfInstallmentsOptions: any = []
        let downpaymentInstallment = preAgreement?.installments.filter((installment) => installment.installmentNumber == 1)
    
        negotiationLimits.installmentsInfo.forEach((i) =>{
            let currentInstallment = preAgreement?.installments.filter((installment) => installment.installmentNumber == i) 
            if(i == 1){
                listOfInstallmentsOptions.push(
                    <option
                        value={1}
                        selected={selectedInstallmentNumber == i}
                    >
                        À vista
                        {downpaymentInstallment && downpaymentInstallment.length > 0 && selectedInstallmentNumber == i && (
                            ` (R$ ${formatBRValue(downpaymentInstallment[0].currentValue)})`
                        )}
                    </option>
                )
            }else{
                listOfInstallmentsOptions.push(
                    <option 
                        value={i} selected={selectedInstallmentNumber == i}
                    >
                        {preAgreement && `Entrada `}
                        { downpaymentInstallment && downpaymentInstallment.length > 0 && selectedInstallmentNumber == i &&
                         `(R$ ${formatBRValue(downpaymentInstallment[0].currentValue)})`
                        }
                        {` + ${i - 1}x`}
                        {currentInstallment && currentInstallment.length > 0  && selectedInstallmentNumber == i && (
                            ` (R$ ${formatBRValue(currentInstallment[0]?.currentValue)})`
                        )}
                    </option>
                )
            }
        })
        
        return listOfInstallmentsOptions
    }

    if(selectedPaymentMethod == 'BANKSLIP' || selectedPaymentMethod == 'CREDIT_CARD'){
        return(
            <div className='w-full h-auto p-6 bg-white rounded-md my-3' style={{ boxShadow: '3px 3px 10px #b9b9b9' }}>
                <p className='font-bold text-2xl text-gray-700'>
                    <FontAwesomeIcon icon={faDivide} /> &nbsp;
                    Em quantas parcelas deseja pagar este acordo?
                </p>
                {isLoading && (<LoadingComponent />)}
                {!isLoading && (
                    <div className="">
                        <select style={{
                            marginTop: "17px",
                            padding: "3px 0px 0px 11px;",
                            paddingLeft: "15px"
                        }}
                            onChange={(e) => {handleChangeFunction(e)}}
                        >
                            {
                             _returnInstallmentsOptions()   
                            }
                        </select>
                    </div>
                )}
            </div>
        )
    } else {
        return null
    }
    
}
