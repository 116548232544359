import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { Limits, PreAgreement } from "../../../../common/entities/PreAgreement/PreAgreement";
import { Receivable } from "../../../../common/entities/Receivable/Receivable";
import { NegotiationOption } from "../../../../common/entities/NegotiationOption/NegotiationOption";
import { CreateAgreementResponse } from "../../../../common/entities/Agreement/Agreement";
import { CreateAgreementThunk, getAvailablePaymentDates, getNegotiationLimitsThunk, getNegotiationReceivablePaymentThunk, getPreagreementThunk, getTCDandCADThunk } from "./negotiationSliceThunk";
import { TGatewayError } from "../../../../common/gateways/errors";
import { Payment } from "../../../../common/entities/Receivable/Payment";
import { AdimplereError } from "../../../../common/entities/Errors/Error";


type TInitialState = {
    isLoading: boolean,
    negotiationOption: NegotiationOption,
    negotiationLimits: Limits | null,
    preAgreements: PreAgreement[],
    selectedPreAgreement: PreAgreement | null,
    installmentNumber: number,
    paymentMethod: string,
    paymentDate: string,
    selectedReceivablesUUID: string[],
    extraReceivablesUUID: string[];
    additionalReceivablesUUID: string[];
    selectedNegotiationOptionItemsUUID: string[]
    clientGeoLocation: {latitude: string, longitude: string} | null;
    isAgreementCompleted: boolean
    completedAgreement: CreateAgreementResponse | null
    currentPayment: Payment | null,
    cadURL: string;
    isLoadingCAD: boolean,
    tcdURL: string;
    isLoadingTCD: boolean;
    error: AdimplereError | null;
    rematError: boolean;
    availablePaymentDates: string[];
    contactInfo: {
        email: string | null;
        phone: string | null;
        isEmailValid: boolean;
        isPhoneValid: boolean;
    }
}
const initialState: TInitialState = {
    isLoading: false,
    negotiationOption: {
        type: "NegotiationOption",
        uuid: '',
        description: '',
        obligatoryReceivablesUUID: [],
        negotiationOptionItems: [],
        numberOfInstallments: 0,
        paymentMethods: [],
        creditor: {
            name: '',
            logo: ''
        }
    },
    negotiationLimits: null,
    preAgreements: [],
    selectedPreAgreement: null,
    installmentNumber: 1,
    paymentMethod: 'PIX',
    paymentDate: "",
    selectedReceivablesUUID: [],
    extraReceivablesUUID: [],
    additionalReceivablesUUID: [],
    selectedNegotiationOptionItemsUUID: [],
    clientGeoLocation: {
        latitude: '0',
        longitude: '0'
    },
    isAgreementCompleted: false,
    completedAgreement: null,
    currentPayment: null,
    cadURL: '',
    isLoadingCAD: false,
    tcdURL: '',
    isLoadingTCD: false,
    rematError: false,
    error: null,
    availablePaymentDates: [],
    contactInfo: {
        email: '',
        phone: '',
        isEmailValid: true,
        isPhoneValid: true
    }
}


const negotiationSlice = createSlice({
    name: 'negotiationPanel',
    initialState,
    reducers: {
        resetNegotiation: (state) => initialState,
        setNegotiationOption: (state: TInitialState, action: PayloadAction<{negotiationOption: NegotiationOption, receivables: Receivable[]}>) => {
            state.negotiationOption = action.payload.negotiationOption
            state.selectedReceivablesUUID = action.payload.negotiationOption.obligatoryReceivablesUUID
            state.additionalReceivablesUUID = []
            state.paymentDate = ''
            // state.paymentMethod = action.payload.paymentMethods[0]
            //state.paymentDate = action.payload.availablePaymentDates ? action.payload.availablePaymentDates[0] : ''
            //TODO Remove ExtraReceivableUUID or change it to AdditionalReceivablesUuid logic
            const obligatory_remat = action.payload.negotiationOption.negotiationOptionItems.filter(
                (item) => {
                    if(item) {
                        return item.extraDomain?.data.remat
                    }
                    return false
                }
            )
            if(obligatory_remat) {
                const additional_remat = action.payload.negotiationOption.negotiationOptionItems.filter(
                    (item) => {
                        if(item && item.groupCounter && obligatory_remat.length > 0) { 
                            // @ts-ignore
                            if(item?.groupCounter < obligatory_remat[0].groupCounter){
                                return true
                            }
                        }
                        const receivableList = action.payload.receivables.filter(receivable => receivable.uuid == item.receivableUUID)
                        if(
                            receivableList.length > 0 &&
                            receivableList[0].installments && 
                            receivableList[0].installments.length > 0 
                        ){
                            try{
                                return new Date(Date.parse(receivableList[0].installments[0].dueDate)) < new Date()
                            } catch {
                                return false
                            }
                        }
                    }
                )
                const extraReceivablesUUID = [
                    ...obligatory_remat.map(item => item.receivableUUID),
                    ...additional_remat.map(item => item.receivableUUID)
                ]
                state.extraReceivablesUUID = extraReceivablesUUID.filter((value, index, array) => array.indexOf(value) === index)
            }
        },
        setInstallmentNumber: (state: TInitialState, action: PayloadAction<number>) => {
            state.installmentNumber = action.payload
            // if(action.payload == 1){
            //     if(!state.paymentMethod.includes('a Vista')){
            //         state.paymentMethod = `${state.paymentMethod} a Vista`
            //     }
            // }else{
            //     if(state.paymentMethod.includes('a Vista')){
            //         state.paymentMethod = state.paymentMethod.replace('a Vista', '')
            //     }
            // }
        },
        setPaymentMethod: (state: TInitialState, action: PayloadAction<string>) => {
            state.paymentMethod = action.payload
            // state.installmentNumber = 1
        },
        setPaymentDate: (state: TInitialState, action: PayloadAction<string>) => {
            state.paymentDate = action.payload
        },
        setClientGeoLocation: (state: TInitialState, action: PayloadAction<{latitude: string, longitude: string}>) => {
            state.clientGeoLocation = action.payload
        },
        removeReceivable: (state: TInitialState, action: PayloadAction<Receivable>) => {
            state.selectedReceivablesUUID =  state.selectedReceivablesUUID.filter(
                uuid => uuid != action.payload.uuid
            )
        },
        setReceivables: (state: TInitialState, action: PayloadAction<Receivable[]>) => {
            const newUUIDS: string[] = action.payload.map(
                receivable => receivable.uuid
            ).filter(
                receivableUUID => !state.selectedReceivablesUUID.includes(receivableUUID)
            )

            state.selectedReceivablesUUID = state.selectedReceivablesUUID.concat(newUUIDS)
        },
        addAdditionalReceivableUUID: (state: TInitialState, action: PayloadAction<string[]>) => {
            state.additionalReceivablesUUID =  state.additionalReceivablesUUID.concat(action.payload)
        },
        removeAdditionalReceivableUUID: (state: TInitialState, action: PayloadAction<string>) => {
            state.additionalReceivablesUUID =  state.additionalReceivablesUUID.filter((uuid) => uuid != action.payload)
        },
        addSelectedNegotiationOptionItemUUID: (state: TInitialState, action: PayloadAction<string[]>) => {
            state.selectedNegotiationOptionItemsUUID =  state.selectedNegotiationOptionItemsUUID.concat(action.payload)
        },
        removeSelectedNegotiationOptionItemUUID: (state: TInitialState, action: PayloadAction<string>) => {
            state.selectedNegotiationOptionItemsUUID =  state.selectedNegotiationOptionItemsUUID.filter((uuid) => uuid != action.payload)
        },
        setEmail: (state: TInitialState, action: PayloadAction<string>) => {
            state.contactInfo.email = action.payload
        },
        setPhone: (state: TInitialState, action: PayloadAction<string>) => {
            state.contactInfo.phone = action.payload
        },
        setIsPhoneValid: (state:TInitialState, action: PayloadAction<boolean> ) => {
            state.contactInfo.isPhoneValid = action.payload
        },
        setIsEmailValid: (state:TInitialState, action: PayloadAction<boolean> ) => {
            state.contactInfo.isEmailValid = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPreagreementThunk.pending, (state: TInitialState) => {
                state.isLoading = true
            })
            .addCase(getPreagreementThunk.fulfilled, (
                state: TInitialState, action: PayloadAction<PreAgreement | TGatewayError>
            ) => {
                if(action.payload.type == 'PreAgreement'){
                    state.preAgreements.push(action.payload)
                    state.selectedPreAgreement = action.payload
                    state.isLoading = false
                    //TODO
                    //state.paymentDate = action.payload.installments[0].dueDate

                    state.error = null
                }else{
                    state.selectedPreAgreement = null
                    state.isLoading = false

                    if(action.payload.code == 'REMAT01'){
                        state.additionalReceivablesUUID = []
                        state.selectedNegotiationOptionItemsUUID = []
                        state.rematError = true
                    }else{
                        state.error = {errorCode: 'EXT01', errorMessage: action.payload.description}
                    }
                }
            })
            .addCase(getPreagreementThunk.rejected, (state: TInitialState) => {
                state.isLoading = false
                state.selectedPreAgreement = null
                state.error = {errorCode: 'EXT01', errorMessage: 'Error when fetching pre agreements'}
            })
            .addCase(getNegotiationLimitsThunk.pending, (state: TInitialState) => {
                state.isLoading = true
            })
            .addCase(getNegotiationLimitsThunk.fulfilled, (
                state: TInitialState, action: PayloadAction<Limits | TGatewayError>
            ) => {
                if(action.payload.type == 'Limits'){
                    state.negotiationLimits = action.payload
                    state.paymentDate = action.payload.availableDates[0]
                    state.installmentNumber = action.payload.installmentsInfo[0]
                }
            })
            .addCase(CreateAgreementThunk.pending, (state: TInitialState) => {
                state.isLoading = true
            })
            .addCase(CreateAgreementThunk.fulfilled, (
                state: TInitialState, action: PayloadAction<any>
            ) => {
                if(action.payload[0].uuid != null){
                    state.isAgreementCompleted = true
                    state.completedAgreement = action.payload[0]
                }
                state.isLoading = false
            })
            .addCase(CreateAgreementThunk.rejected, (
                state: TInitialState,
                action: any
            ) => {
                state.isLoading = false 
                state.error = {errorCode: 'AGR_ERR', errorMessage: action.payload.description}
            })
            .addCase(getTCDandCADThunk.pending, (state: TInitialState) => {
                state.isLoadingCAD = true
                state.isLoadingTCD = true
            })
            .addCase(getTCDandCADThunk.fulfilled, (
                state: TInitialState, action: PayloadAction<any>
            ) => {
                state.isLoadingCAD = false
                state.isLoadingTCD = false
                if(action.payload){
                    state.cadURL = action.payload.cad.url
                    state.tcdURL = action.payload.tcd.url
                }
            })
            .addCase(getTCDandCADThunk.rejected, (
                state: TInitialState,
                action: any
            ) => {
                state.isLoadingCAD = false
                state.error = {errorCode: 'CAD_ERR', errorMessage: 'error when fetching CAD or TCD'}
            })
            .addCase(getAvailablePaymentDates.pending, (state: TInitialState) => {
                state.isLoading = true
            })
            .addCase(getAvailablePaymentDates.fulfilled, (
                state: TInitialState, action: PayloadAction<string[] | TGatewayError>
            ) => {
                state.isLoading = false
                if(Array.isArray(action.payload)){
                    state.availablePaymentDates = action.payload
                }else{
                    state.availablePaymentDates = []
                }
                
            })
            .addCase(getNegotiationReceivablePaymentThunk.fulfilled, 
            (state, action: PayloadAction<[string, number, Payment]  | TGatewayError>) => {
                if(Array.isArray(action.payload)){
                    if(state.completedAgreement && state.completedAgreement.currentReceivable.uuid == action.payload[0]){
                        state.currentPayment = action.payload[2]
                    }
                }
            })
    }
})

export const selectRematError = (state: RootState) => state.negotiation.rematError
export const selectNegotiationError = (state: RootState) => state.negotiation.error
export const selectSelectedNegotiationOption = (state: RootState) => state.negotiation.negotiationOption
export const selectNegotiationLimits = (state: RootState) => state.negotiation.negotiationLimits
export const selectIsLoading = (state: RootState) => state.negotiation.isLoading
export const selectPreAgreement = (state: RootState) => state.negotiation.selectedPreAgreement
export const selectExtraReceivablesUUID = (state: RootState) => state.negotiation.extraReceivablesUUID
export const selectAdditionalReceivablesUUID = (state: RootState) => state.negotiation.additionalReceivablesUUID
export const selectSelectedReceivablesUUID = (state: RootState) => state.negotiation.selectedReceivablesUUID
export const selectInstallmentNumber = (state: RootState) => state.negotiation.installmentNumber
export const selectPaymentMethod = (state: RootState) => state.negotiation.paymentMethod
export const selectPaymentDate = (state: RootState) => state.negotiation.paymentDate
export const selectClientGeoLocation = (state: RootState) => state.negotiation.clientGeoLocation
export const selectIsAgreementCompleted = (state: RootState) => state.negotiation.isAgreementCompleted
export const selectCompletedAgreement = (state: RootState) => state.negotiation.completedAgreement
export const selectCurrentPayment = (state: RootState) => state.negotiation.currentPayment
export const selectCadURL = (state: RootState) => state.negotiation.cadURL
export const selectTcdURL = (state: RootState) => state.negotiation.tcdURL
export const selectIsLoadingCAD = (state: RootState) => state.negotiation.isLoadingCAD
export const selectIsLoadingTCD = (state: RootState) => state.negotiation.isLoadingTCD
export const selectEmail = (state: RootState) => state.negotiation.contactInfo.email
export const selectPhone = (state: RootState) => state.negotiation.contactInfo.phone
export const selectIsPhoneValid = (state: RootState) => state.negotiation.contactInfo.isPhoneValid
export const selectIsEmailValid = (state: RootState) => state.negotiation.contactInfo.isEmailValid
export const selectSelectedNegotiationOptionItemsUUID = (state: RootState) => state.negotiation.selectedNegotiationOptionItemsUUID
export const selectSelectedReceivables = (state: RootState) => state.negotiation.selectedReceivablesUUID.map(
    uuid => (
        state.receivables.receivables.filter(receivable => receivable.uuid == uuid)[0]
    )
)
export const selectIsRemat = (state: RootState) => { 

    const remat_option = state.negotiation.negotiationOption.negotiationOptionItems.filter(option => (
        option.extraDomain?.data.remat
    ))
    if(remat_option && remat_option.length > 0) {
        return state.negotiation.additionalReceivablesUUID.includes(remat_option[0].receivableUUID)
    }

    return false
}
export const selectExtraReceivables = (state: RootState) => state.negotiation.extraReceivablesUUID.map((uuid) => (
    state.receivables.receivables.filter(receivable => receivable.uuid == uuid)[0]
))
export const selectAvailablePaymentDates = (state: RootState) => state.negotiation.availablePaymentDates

export const { resetNegotiation, addSelectedNegotiationOptionItemUUID, removeAdditionalReceivableUUID, addAdditionalReceivableUUID, removeSelectedNegotiationOptionItemUUID, setEmail, setPhone, setIsEmailValid, setIsPhoneValid, setReceivables, removeReceivable, setNegotiationOption, setPaymentDate, setInstallmentNumber, setPaymentMethod, setClientGeoLocation } = negotiationSlice.actions;

export default negotiationSlice.reducer;