import React from "react";
import CardFooter from "../base/CardFooter";
import { NegotiationOptionButtonFilled, NegotiationOptionButtonWithBorder } from "./NegotiationOptionCardButtons";
import { NegotiationOptionCardFooterType } from "./interfaces";
import Modal from "../../modal";
import { NegotiationOptionCardFooterModal } from "./NegotiationOptionCardFooterModal";
import { useCookies } from "react-cookie";
import { useAppDispatch } from "../../../../NegotiationPortal/hooks";
import { postLogThunk } from "../../../../NegotiationPortal/components/logs/logsThunk";


const NegotiationOptionCardFooter = ({
    negotiationOption,
    receivables,
    clickCallback,
    creditor,
    client
} : NegotiationOptionCardFooterType) => {
    const dispatch = useAppDispatch()
    const [cookies] = useCookies(['accessUUID']);
    const accessUUID = cookies.accessUUID


    return (
        <>
            <CardFooter>
                <div className="w-full h-auto flex md:flex-row flex-col justify-around items-center">
                    <NegotiationOptionCardFooterModal 
                        negotiationOption={negotiationOption}
                        receivables={receivables}
                        creditor={creditor}
                        client={client}
                        accessUUID={accessUUID}
                    />
                    <NegotiationOptionButtonFilled
                        onClick={() => {
                            dispatch(postLogThunk(
                                {
                                    event: 'PRESSED_CHECK_OFFER_BUTTON',
                                    documentNumber: client.documentNumber,
                                    formData: null,
                                    details: {
                                        negotiationOptionUUID: negotiationOption.uuid
                                    },
                                    accessUUID: accessUUID
                                }
                            ))
                            clickCallback(negotiationOption)
                        }}
                    >
                        Ver Oferta
                    </NegotiationOptionButtonFilled>
                </div>
            </CardFooter>
        </>
    )
}

export default NegotiationOptionCardFooter