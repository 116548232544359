import {
  createBrowserRouter,
} from "react-router-dom";
import "../App.css"
import LandingPage from "./pages/LandingPage";
import CreditorsPage from "./pages/CreditorsPage";
import ReceivablesPanelPage from "./pages/ReceivablesPanelPage";
import NegotiationPage from "./pages/NegotiationPage";
import { SuccessPage } from "./pages/SuccessPage";
import SummaryPage from "./pages/SummaryPage";
import { AccessLinkPage } from "./pages/AccessLinkPage";


const router = createBrowserRouter([
  {
    path: '/',
    element: (<LandingPage />)
  },
  {
    path: '/credores',
    element: (<CreditorsPage />)
  },
  {
    path: '/contratos',
    element: (<ReceivablesPanelPage />)
  },
  {
    path: '/negociacao',
    element: (<NegotiationPage />)
  },
  {
    path: '/sucesso',
    element: (<SuccessPage />)
  },
  {
    path: '/resumo',
    element: (<SummaryPage />)
  },
  {
    path: '/access_link/:token',
    element: (<AccessLinkPage />)
  }

])

export default router;