import React from "react";
import CardFooter from "../base/CardFooter";
import { AgreementCardFooterType } from "./interfaces";
import AgreementCardTableAggregation from "./table/AgreementCardTableAggregation";


const AgreementCardFooter = ({
    agreement,
    receivables,
    clickCallback,
    originalReceivables,
    currentReceivable,
    creditor,
    paymentsLoading
}: AgreementCardFooterType) => {
    return (
        <>
            <CardFooter>
                <div className="w-full h-auto flex md:flex-row flex-col justify-around items-center">
                    <AgreementCardTableAggregation 
                        receivables={receivables}
                        agreement={agreement}
                        creditor={creditor}
                        originalReceivables={originalReceivables}
                        currentReceivable={currentReceivable}
                        paymentsLoading={paymentsLoading}
                    />
                </div>
            </CardFooter>
        </>
    )
}

export default AgreementCardFooter