import axios, {AxiosResponse} from "axios"
import {Mocks} from '../../NegotiationPortal/components/Receivable/ListPanel/mocks'
import { delay } from "../utils/delay"
import { GatewayError, TGatewayError } from "./errors"
import negotiationOptionConverter from "./converters/negotiationOptionConverter"
import { NegotiationOption } from "../entities/NegotiationOption/NegotiationOption"
import { Receivable } from "../entities/Receivable/Receivable"
import { PortalCreditor } from "../../NegotiationPortal/entities/creditor/PortalCreditor"
import walletConverter, { TCreditorResponse } from "./converters/walletConverter"
import { Wallet } from "../entities/Wallet/Wallet"
import { Settings } from '../settings'

interface ILogsGateway {
    domain: string,
    path: string,
    event: string,
    documentNumber: string | null,
    accessUUID: string,
    formData: { [key: string]: any } | null,
    details: { [key: string]: any } | null
}

export const logsGateway = async (
    {
        domain, path, event, documentNumber, accessUUID, formData, details
    }: ILogsGateway 
) => {
    if(Settings.env == 'production') {
        if(Settings.LOG_HOST) {
            const response = await axios.post(
                `${Settings.LOG_HOST}/api/v1/events/frontend_events`,
                {
                    version: 1,
                    event_type: 'frontend_tracking',
                    domain: domain,
                    path: path,
                    event: event,
                    document_number: documentNumber,
                    wallet_uuid: Settings.walletUUID,
                    access_uuid: accessUUID,
                    form_data: formData,
                    details: details
                }
            )
        } else {
            return GatewayError('DEV101')
        }
    } else {
        await delay(1000)
        console.log({
            version: 'v1',
            eventType: 'frontend_tracking',
            domain: domain,
            path: path,
            event: event,
            documentNumber: documentNumber,
            walletUUID: Settings.walletUUID,
            accessUUID: accessUUID,
            formData: formData,
            details: details
        })
    }
}