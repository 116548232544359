import React, { useEffect } from "react";
import Header from '../../partials/Header';
import { CreditorsListPanel } from '../../partials/CreditorsListPanel';
import { BackgroundPanelPages, PanelPagesContentContainer, SuccessPageContentContainer } from '../style';
import { SuccessPanel } from "../../partials/SuccessPanel";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectDocumentNumber } from "../../../common/features/AuthForm/authFormSlice";
import { useCookies } from "react-cookie";
import { postLogThunk } from "../../components/logs/logsThunk";
import { MetaPixel } from "../../../common/utils/helmet/MetaPixel";


export const SuccessPage = () => {
    const dispatch = useAppDispatch()
    const documentNumber = useAppSelector(selectDocumentNumber)
    const [cookies] = useCookies(['accessUUID']);

    useEffect(() => {
        let accessUUID = cookies.accessUUID
        
        dispatch(postLogThunk(
            {
                event: 'ENTERED_SUCCESS_PAGE',
                documentNumber: documentNumber,
                formData: null,
                details: null,
                accessUUID: accessUUID
            }
        ))
    }, [])

    return(
        <>
            <BackgroundPanelPages>
                <MetaPixel />
                <Header 
                    showLogout={true}
                />  
                <SuccessPageContentContainer>
                    <SuccessPanel /> 
                </SuccessPageContentContainer>
            </BackgroundPanelPages>
        </>
    )
}