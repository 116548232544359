import axios, { AxiosResponse } from 'axios'
import { Settings, ErrorCodes} from "../../settings"
import { validateDocumentNumber, maskDocumentNumber, TMaskedDocumentNumber } from '../../utils/formatters/DocumentNumber';
import mocker from '../../utils/mocks/client';
import { devEnviron } from '../../utils/environment';


export interface CheckUserResponse {
    uuid?: string
    name?: string
    documentNumber: string
    verified?: boolean
    errorMessage?: string
    error?: {
        message: string
    }
}

export interface SignInResponse {
    success: boolean
    accessToken: string
    error?: {
        message: string
    }
}

export interface SignUpResponse {
    success: boolean
    uuid: string
    name: string
    email: string
    accessToken?: string
    error?: {
        message: string
    }
}

export interface AuthFormServiceInterface {
    checkUserFunction: (documentNumber: string) => Promise<CheckUserResponse>,
    signInUserFunction: (documentNumber: string, password: string) => Promise<SignInResponse>,
    signUpUserFunction: (documentNumber: string, password: string, email: string, name?: string) => Promise<SignUpResponse>,
    validateDocumentNumber: (documentNumber: string) => boolean | null,
    maskDocumentNumber: (documentNumber: string | null) => TMaskedDocumentNumber,
}

const AuthFormService: AuthFormServiceInterface = {
    validateDocumentNumber: validateDocumentNumber,
    maskDocumentNumber: maskDocumentNumber,
    checkUserFunction: async (documentNumber: string): Promise<CheckUserResponse>=> {
        try {
            if(devEnviron()) {
                return mocker({documentNumber: documentNumber}).checkUserResponse
            }
            const resp: AxiosResponse<CheckUserResponse, any> = await axios.get(
                `${Settings.auth.getUser}/${documentNumber}`
            )

            return resp.data
        } catch(e) {
            return {
                documentNumber: documentNumber,
                error: ErrorCodes.error_api
            }
        } 
    },
    signInUserFunction: async (
        documentNumber: string,
        password: String
    ): Promise<SignInResponse> => {
        try {
            if(devEnviron()) {
                return mocker({documentNumber: documentNumber}).signInResponse
            }
            const resp: AxiosResponse<SignInResponse, any> = await axios.post(
                `${Settings.auth.postSignIn}`,
                {
                    document_number: documentNumber,
                    password: password
                }
            )

            return resp.data
        } catch(e) {
            return {
                success: false,
                accessToken: '',
                error: ErrorCodes.error_api
            }
        }
    },
    signUpUserFunction: async (
        documentNumber: string,
        password: string,
        email: string,
        name?: string
    ): Promise<SignUpResponse> =>  {
        try {
            if(devEnviron()) {
                return mocker({documentNumber: documentNumber}).signUpResponse
            }
            const resp: AxiosResponse<SignUpResponse, any> = await axios.post(
                `${Settings.auth.postSignIn}`,
                {
                    document_number: documentNumber,
                    password: password,
                    email: email,
                    name: name
                }
            )
            return resp.data
        } catch(e) {
            return {
                uuid: "",
                name: "",
                email: "",
                error: ErrorCodes.error_api,
                success: false
            }
        }
    }
}

export default AuthFormService;