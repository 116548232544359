import React, { useEffect } from 'react';
import Header from '../../partials/Header';
import MainBanner from '../../partials/MainBanner';
import AuthForm from '../../../common/features/AuthForm' //Form for Adimplere Identity API
import { CreditorsSection } from '../../../common/features/section/CreditorsSection';
import { NegotiationSteps } from '../../../common/features/section/NegotiationSteps';
import { Footer } from '../../../common/features/footer';
import { CreditorsSectionTypeDefault } from '../../../common/features/section/CreditorsSection/interfaces';
import { FooterTypeDefault } from '../../../common/features/footer/interfaces';
import { useAppCookies, useAppDispatch } from '../../hooks';
import { resetAuthForm } from '../../../common/features/AuthForm/authFormSlice';
import { resetNegotiation } from '../../components/Receivable/Negotiation/negotiationSlice';
import { resetReceivablesList } from '../../components/Receivable/ListPanel/receivableListSlice';
import { postLogThunk } from '../../components/logs/logsThunk';
import { useCookies } from 'react-cookie';


interface Props {
    backgroundColor: string
}

const LandingPage = (props: Props) => {
    const dispatch = useAppDispatch()

    const [cookies] = useCookies(['accessUUID']);
    const accessUUID = cookies.accessUUID

    useEffect(() => {
        dispatch(resetAuthForm())
        dispatch(resetNegotiation())
        dispatch(resetReceivablesList())    
        dispatch(postLogThunk(
            {
                event: 'ENTERED_LANDING_PAGE',
                documentNumber: null,
                formData: null,
                details: null,
                accessUUID: accessUUID
            }
        ))
    }, [])
    

    return (
        <>
            <Header />
            <MainBanner backgroundColor='#F29129'>
                <AuthForm />
            </MainBanner>
            <CreditorsSection 
                landingPageSettings={CreditorsSectionTypeDefault.landingPageSettings}
            />
            <NegotiationSteps 
                background_color={'#fff'}
                ball_color= {"#C4B5FD"}
                ball_text_color= {"#8E53EA"}
                step_header_color= {"#000"}
                step_content_color= {"#000"}
            />
            {/* <Footer 
                {...FooterTypeDefault}
            /> */}
        </>
    )

}


export default LandingPage;