import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { persistor, store } from './NegotiationPortal/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import {
  RouterProvider,
} from "react-router-dom";
import router from './NegotiationPortal/router';
import { PersistGate } from 'redux-persist/integration/react';
import { useCookies } from "react-cookie";
import { v4 as uuidv4 } from 'uuid';


const container = document.getElementById('root')!;
const root = createRoot(container);

const ReturnRouter = () => {
  const [cookies, setCookie] = useCookies(['accessUUID']);
  let accessUUID = ''

  if(!cookies.accessUUID){ 
      accessUUID = uuidv4()
      setCookie("accessUUID", accessUUID, { path: '/' });
  }
  return <RouterProvider router={router} />
}

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
        <ReturnRouter />
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
