import { cpf, cnpj } from 'cpf-cnpj-validator';


export const validateDocumentNumber = (documentNumber: string): boolean | null => {
    if(documentNumber.length < 11 || documentNumber.length > 14) {
        return cpf.isValid(documentNumber) || cnpj.isValid(documentNumber)
    }
    return null
}

export type TMaskedDocumentNumber = {
    original: string,
    masked: string
}

export const maskDocumentNumber = (documentNumber: string | null): TMaskedDocumentNumber => {
    if(documentNumber === null || documentNumber === undefined) {
        return {
            original: '',
            masked: ''
        }
    }
    let v: string = documentNumber.replace(/\D/g, "")

    if (v.length <= 11) {
        v = v.replace(/(\d{3})(\d)/, "$1.$2")
        v = v.replace(/(\d{3})(\d)/, "$1.$2")
        v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    } else {
        v = v.substring(0, 14); // limita em 14 números
        v = v.replace(/^(\d{2})(\d)/, "$1.$2")
        v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
        v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")
        v = v.replace(/(\d{4})(\d)/, "$1-$2")
    }

    return {
        original: documentNumber,
        masked: v
    }
}
