import React from "react";
import { NegotiationOptionCardHeaderSectionType } from "./interfaces";

const NegotiationOptionCardHeaderSection = ({
    title='',
    description=''
} : NegotiationOptionCardHeaderSectionType) => {
    return (
        <div className="flex flex-col justify-center items-center my-2">
            <p className="font-bold text-lg text-gray-700 text-center">
                {title}
            </p>
            <p className="font-semibold text-xl text-gray-500 -mt-2 text-center">
                {description}
            </p>
        </div>
)
}

export default NegotiationOptionCardHeaderSection