import axios, {AxiosResponse} from "axios"
import { GatewayError, TGatewayError } from "./errors"
import {Mocks as Mocks2} from '../../NegotiationPortal/components/Receivable/ListPanel/mocks'
import { Settings } from "../settings"
import paymentConverter, { TPaymentResponse } from "./converters/paymentConverter"
import { Payment } from "../entities/Receivable/Payment"
import { delay } from "../utils/delay"


export interface IpaymentGateway {
    receivableUUID: string
    installmentNumber: number

}

export const paymentGateway = async ({
    receivableUUID,
    installmentNumber
}: IpaymentGateway): Promise<[string, number, Payment] | TGatewayError> => {
    if(Settings.env == 'production') {
        if(Settings.SERVER_HOST) {
            const response: AxiosResponse<TPaymentResponse, any> = await axios.get(
                `${Settings.SERVER_HOST}/api/v1/${Settings.walletUUID}/receivables/${receivableUUID}/installments/${installmentNumber}/payment`,
            )

            return [receivableUUID, installmentNumber, paymentConverter(response.data)]
        } else {
            return GatewayError('DEV101')
        }
    } else {
        await delay(10000)
        {
            return [receivableUUID, installmentNumber, paymentConverter(Mocks2.paymentPixResponse)]
        }
        // {
        //     return [receivableUUID, installmentNumber, paymentConverter(Mocks2.paymentCreditCardResponse)]
        // }
        // {
        //     return [receivableUUID, installmentNumber, paymentConverter(Mocks2.paymentBankslipResponse)]
        // }
    }
}

