import React from "react";
import tw from 'tailwind-styled-components';

const HeaderDiv = tw.div`
    w-full
    h-auto
    p-2
    pt-2
    border-b
`

const CardHeader = ({children}) => {
    return (
        <>
            <HeaderDiv>
                {children}
            </HeaderDiv>
        </>
    )
}

export default CardHeader