import React, {useEffect, useState} from 'react'
import { useAppDispatch, useAppSelector } from '../../../../NegotiationPortal/hooks';
import { Formik, Form, Field  } from 'formik';
import { useNavigate } from "react-router-dom";
import { signUpThunk } from '../authFormSlice';
import { selectUserIsAuthenticated } from '../authFormSlice';



const ClientNotFoundWarning = () => {

    const userIsAuthenticated = useAppSelector(selectUserIsAuthenticated)
    const navigate = useNavigate()

    useEffect(() => {
        if(userIsAuthenticated) {
            navigate('/contratos')
        }
    }, [userIsAuthenticated])

    return (
        <div className='w-full flex justify-center items-center py-10 px-2 flex-col text-center'>
            <p className='text-2xl font-bold' >
                Cliente não encontrado!
            </p>
            <p className='text-lg font-base text-center pt-5' >
                Não encontramos dados com o CPF informado em nossa base de dados.
            </p>
        </div>
       
    )
}

export default ClientNotFoundWarning;