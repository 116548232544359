import { LandingPageSettings } from "../../../../NegotiationPortal/entities/settings/LandingPageSettings"

export interface CreditorsSectionType {
    landingPageSettings: LandingPageSettings
}

export const CreditorsSectionTypeDefault = {landingPageSettings:{creditorsLogos:[
    "media/img/creditors_logos/anhanguera.jpg",
    "media/img/creditors_logos/ampli.png",
    "media/img/creditors_logos/lfg.png",
    "media/img/creditors_logos/pitagoras.jpg",
    "media/img/creditors_logos/unic.jpg",
    "media/img/creditors_logos/uniderp.jpg",
    "media/img/creditors_logos/unime.jpg",
    "media/img/creditors_logos/unopar.jpg",
]
}}