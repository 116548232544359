import React from "react";
import CardHeader from "../base/CardHeader";
import NegotiationOptionCardHeaderSection from "./NegotiationOptionCardHeaderSection";
import { NegotiationOptionCardHeaderType } from "./interfaces";
import { formatBRValue } from '../../../utils/valueFormatters/formatBRValue';


const NegotiationOptionCardHeader = ({
    negotiationOption,
    creditor,
    receivables,
} : NegotiationOptionCardHeaderType) => {
    const _getOriginalValue = () => {
        let value = 0
        receivables.forEach((receivable) => {
            if(receivable) {
                if(receivable.currentValue){
                    value += receivable.currentValue
                }else{
                    value += receivable.originalReceivableValue
                }
            }
        })
        return value.toString()
    }

    return (
        <>
            <CardHeader>
                <div className="w-full h-full flex md:flex-row flex-col">
                    <div className="flex flex-col items-center justify-between w-full md:w-1/2 ">
                        <div className="w-full h-24 flex items-center justify-center pb-2 md:hidden">
                            <img 
                                src={negotiationOption.creditor.logo} 
                                className="h-full w-auto"
                            />
                        </div>
                        <NegotiationOptionCardHeaderSection 
                            title="Dívida com a empresa"
                            description={negotiationOption.creditor ? negotiationOption.creditor.name : ''}
                        />
                        <NegotiationOptionCardHeaderSection 
                            title="Valor original"
                            description={`R$ ${formatBRValue(_getOriginalValue())}`}
                        />
                        <NegotiationOptionCardHeaderSection 
                            title="Contrato"
                            description={negotiationOption.description}
                        />
                    </div>
                    <div className="flex flex-col items-center justify-between w-full md:w-1/2 h-auto md:h-full">
                        <div className="w-7/8 h-32 items-center justify-center hidden md:flex">
                            <img 
                                src={negotiationOption.creditor ? negotiationOption.creditor.logo : ''} 
                                className="h-auto w-auto"
                            />
                        </div>
                        <NegotiationOptionCardHeaderSection 
                            title="Curso"
                            description={receivables[0]?.product ? receivables[0].product.name : '-'}
                        />
                    </div>
                </div>
                
            </CardHeader>
        </>
    )
}

export default NegotiationOptionCardHeader