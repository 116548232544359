import React from "react"
import { CreditorCard } from "../../card/CreditorCard";
import getKey from "../../../utils/key/getKey";
import { Loadings } from "../../loadings/Loadings";
import { _returnCreditorIsLoadingContainerType, _returnCreditorsListContainerType } from "./interfaces";



export const _returnCreditorIsLoadingContainer = ({isLoading} : _returnCreditorIsLoadingContainerType) => {
    if(isLoading){
        return(<div className="w-full flex items-center justify-center flex-col py-5">
            <Loadings 
                variant="bars"
                colorHex="#3B82F6"
                opacity="0.8"
            />
            <p 
                className='text-2xl md:text-2xl text-center font-bold mx-2 800:mx-0 mb-4 md:mb-0 pb-1 md:pb-3' 
                color='blue' 
            >
                    Carregando suas pendências...
            </p>
        </div>)
    }
}

export const _returnCreditorsListContainer = ({isLoading, creditors, clickCallback} : _returnCreditorsListContainerType) => {
    if(creditors.length > 0){
        return(
            <div className="w-full flex items-center justify-center flex-col md:flex-row md:justify-center flex-wrap">
                { 
                    creditors.map((creditor) => {
                        if(!(typeof creditor == 'undefined')){
                            return(
                                <CreditorCard
                                    creditor={creditor}
                                    clickCallback={clickCallback}
                                    key={getKey()}
                                ></CreditorCard>
                            )
                        }
                    })
                }
            </div>
        )
        
    }else if (!isLoading){
        return(
            <div className="w-full flex items-center justify-center">
                <p 
                    className='text-2xl md:text-2xl text-center font-bold mx-2 800:mx-0 mb-4 md:mb-0 pb-1 md:pb-3' 
                    color='blue' 
                >
                    Não foram encontradas pendências em nossos parceiros.
                </p>
            </div>
        )
    }
    
}