import React from "react";
import { Card } from "../Card";
import { NegotiationOptionCardType } from "./interfaces";
import NegotiationOptionCardFooter from "./NegotiationOptionCardFooter";
import NegotiationOptionCardHeader from "./NegotiationOptionCardHeader";


const NegotiationOptionCard = ({
    negotiationOption,
    creditor,
    receivables,
    clickCallback,
    client
} : NegotiationOptionCardType) => {
    return (
        <>
            <Card
                hasHeader={false}
                topLine={true}
                topLineColor={'#E99F49'}
                className={'bg-white'}
                backgroundColor={'#FFFFFF'}
            >

                <NegotiationOptionCardHeader 
                    negotiationOption={negotiationOption}
                    creditor={creditor}
                    receivables={receivables}
                />

                <NegotiationOptionCardFooter 
                    negotiationOption={negotiationOption}
                    creditor={creditor}
                    receivables={receivables}
                    clickCallback={clickCallback}
                    client={client}
                />

            </Card>
        </>
    )
}

export default NegotiationOptionCard