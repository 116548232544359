import React, {useState, useRef, useEffect} from 'react';
import { modalType, modalTypeDefault } from './interfaces';


const Modal = ({modalButtonClassname = modalTypeDefault.modalButtonClassname, 
                modalClassname = modalTypeDefault.modalClassname, 
                children = modalTypeDefault.children,
                modalButtonText = modalTypeDefault.modalButtonText,
                modalOpeningLogFunction
            }: modalType) => {

    const [openModal, setOpenModal] = useState(false)

    const closeModal = async (e: any) => {
        e.preventDefault()
        setOpenModal(false)
    }

    const clickModalButton= async (e: any) => {
        e.preventDefault()
        setOpenModal(true)
    }

    useEffect(() => {
        if(openModal && modalOpeningLogFunction){
            modalOpeningLogFunction()
        }
    }, [openModal])

    return (
        <>  
            <button
                onClick={(e) => clickModalButton(e)}
                className={`${modalButtonClassname}`}
            >
                {modalButtonText}
            </button>
            
            <div
            onClick={(e) => closeModal(e)}
            className={`
                ${openModal ? '' : 'hidden'}
                fixed
                top-0
                left-0
                right-0
                z-50
                w-full
                p-4
                overflow-x-hidden
                overflow-y-auto
                md:inset-0
                h-[calc(100%-1rem)]
                md:h-full
                bg-[#000000a3]
                flex
                justify-center
                items-center
            `} >
                <div tabIndex={-1} className='w-full flex items-center justify-center'>
                    <div className="relative w-full h-full md:h-auto flex items-center justify-center">
                        <div className={` bg-white rounded-lg shadow dark:bg-gray-700 h-[99%] fixed top-[1%] overflow-y-scroll ${modalClassname}`}>
                            <button
                                type="button"
                                onClick={(e) => closeModal(e)}
                                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                                data-modal-hide="authentication-modal"
                            >
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="px-6 py-6 lg:px-8 dark:bg-gray-50">

                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Modal;

