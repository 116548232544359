import tw from 'tailwind-styled-components';

export const NegotiationOptionCardTable = tw.table`
    text-lg
    font-semibold
    w-full
    my-5
`

export const NegotiationOptionCardTableHeader = tw.thead`
    font-bold 
    text-left
    contents
`

export const NegotiationOptionCardTableHead = tw.th`
    font-bold 
    text-center
    text-xl
    text-gray-700
`

export const NegotiationOptionCardTableCell = tw.td`
    px-2
    text-center
    font-medium
    text-gray-600
`

export const NegotiationOptionCardTableBody = tw.tbody`
`

export const NegotiationOptionCardTableRow = tw.tr`
    flex 
    table-row
    py-3
    border-none
`