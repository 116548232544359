import React from 'react'
import tw from 'tailwind-styled-components';


export const CardContainer = tw.div`
w-full
rounded-md
bg-gray-50 
relative
`

interface CardTopLineType {
  topline: Boolean | void,
  backgroundColor: string | void
}
export const CardTopLine = tw.div`
${({topline, backgroundColor} : CardTopLineType) => {
    switch (topline) {
      case false: return 'hidden'
      case true: return `bg-[${backgroundColor}]`;
    }
  }}
w-full
rounded-t-md
h-2
absolute
`

interface CardContentType {
  header: Boolean | void,
  direction: string | void
}
export const CardContent = tw.div`
  w-full
  flex
  ${({header} : CardContentType) => {
    switch (header) {
      case false: return 'pt-3';
    }
  }}
  ${({direction} : CardContentType) => {
    switch (direction) {
      case 'row': return 'flex-row';
      default: return 'flex-col';
    }
  }}
`

interface CardHeaderType {
  header: Boolean | void,
  direction: string | void
}
export const CardHeader = tw.div`
${({header} : CardHeaderType) => {
    switch (header) {
      case false: return 'hidden';
      case true: return 'bg-adimplereBlue text-white p-5'
    }
  }}
${({direction} : CardHeaderType) => {
  switch (direction) {
    case 'row': return 'p-5 rounded-l-md rounded-r-none';
    default: return 'w-full rounded-t-md rounded-b-none';
  }
}}
text-3xl
font-bold
`
export const CardBody = tw.div`
w-full
rounded-b-md
text-gray-700
text-lg
font-medium
`

// export const CardBody = tw.div`
// w-full
// bg-gray-50 
// rounded-b-md
// ${({header}) => {
//   switch (header) {
//     case false: return 'px-5 pb-5 pt-2';
//     case true: return 'p-5'
//   }
// }}
// text-gray-700
// text-lg
// font-medium
// `
