import React from "react";
import { Card } from "../Card";
import AgreementCardFooter from "./AgreementCardFooter";
import AgreementCardHeader from "./AgreementCardHeader";
import { AgreementCardType } from "./interfaces";


const AgreementCard = ({
    agreement,
    creditor,
    receivables,
    currentReceivable,
    originalReceivables,
    clickCallback,
    paymentsLoading
} : AgreementCardType) => {
    return (
        <>
            <Card
                hasHeader={false}
                topLine={true}
                topLineColor={'#5374CC'}
                className={'bg-white'}
                backgroundColor={'#FFFFFF'}
            >

                <AgreementCardHeader 
                    agreement={agreement}
                    creditor={creditor}
                    receivables={receivables}
                    originalReceivables={originalReceivables}
                    currentReceivable={currentReceivable}
                />

                <AgreementCardFooter 
                    agreement={agreement}
                    creditor={creditor}
                    receivables={receivables}
                    clickCallback={clickCallback}
                    originalReceivables={originalReceivables}
                    currentReceivable={currentReceivable}
                    paymentsLoading = {paymentsLoading}
                />

            </Card>
        </>
    )
}

export default AgreementCard