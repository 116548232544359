import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Receivable } from "../../../../common/entities/Receivable/Receivable";
import { Agreement } from "../../../../common/entities/Agreement/Agreement";
import { NegotiationOption, NegotiationOptionItem } from "../../../../common/entities/NegotiationOption/NegotiationOption";
import ReceivableService, {TGetNegotiationOptionsResponse} from "./services";
import { RootState } from "../../../store";
import { Creditor } from "../../../../common/entities/Creditor/Creditor";

import { agreementsGateway, negotiationOptionGateway } from "../../../../common/gateways/negotiationOptionGateway";
import { TGatewayError } from "../../../../common/gateways/errors";
import { walletGateway } from "../../../../common/gateways/walletGateway";
import { Wallet } from "../../../../common/entities/Wallet/Wallet";
import { getPortalCreditorFromWallet } from "../../../entities/creditor/portalCreditorConverter";
import { Settings } from '../../../../common/settings';
import { paymentGateway } from "../../../../common/gateways/paymentGateway";
import { Payment } from "../../../../common/entities/Receivable/Payment";
import { string } from "prop-types";


type TReceivableListState = {
    selectedNegotiationOption: NegotiationOption | null,
    selectedReceivables: Receivable[],
    loadingNegotiationOptions: boolean,
    loadingAgreements: boolean,
    loadingCreditors: boolean,
    paymentsLoading: string[],
    receivables: Receivable[],
    agreements: Agreement[],
    negotiationOptions: NegotiationOption[],
    creditors: Creditor[],
    selectedCreditor: Creditor | null,
    wallets: Wallet[],
    receivableError: boolean,
    payments: Payment[]
    noReceivable: boolean | null
}

const initialState: TReceivableListState = {
    selectedNegotiationOption: null,
    selectedReceivables: [],
    loadingNegotiationOptions: false,
    loadingAgreements: false,
    loadingCreditors: false,
    paymentsLoading: [],
    receivables: [],
    agreements: [],
    negotiationOptions: [],
    creditors: [],
    selectedCreditor: null,
    wallets: [],
    receivableError: false,
    payments: [],
    noReceivable: null
}

export const getNegotiationOptionsThunk = createAsyncThunk(
    'Receivable/ListPanel/getNegotiationOptions',
    async (documentNumber: string): Promise<[NegotiationOption[], Receivable[]] | TGatewayError> => {
        return await negotiationOptionGateway({documentNumber})
    }
)

export const getAgreementsThunk = createAsyncThunk(
    'Receivable/ListPanel/getAgreements',
    async (documentNumber: string): Promise<[Agreement[], Receivable[]] | TGatewayError> => {
        return await agreementsGateway({documentNumber})
    }
)

export const getCreditorsThunk = createAsyncThunk(
    'Receivable/ListPanel/getCreditorsThunk',
    async (documentNumber: string): Promise<Wallet[] | TGatewayError> => {
        return await walletGateway({documentNumber})
    }
)

export const getReceivableListPaymentThunk = createAsyncThunk(
    'Receivable/ListPanel/getReceivableListPaymentThunk',
    async ({receivableUUID, installmentNumber}: {receivableUUID: string; installmentNumber: number}): Promise<[string, number, Payment]  | TGatewayError> => {
        return await paymentGateway({receivableUUID, installmentNumber})
    }
)

const ReceivableListSlice = createSlice({
    name: 'receivablePanel',
    initialState,
    reducers: {
        resetReceivablesList: (state) => initialState,
        negotiate: (state, action: PayloadAction<NegotiationOption>) => {
            state.selectedNegotiationOption = action.payload
        },
        setCreditor: (state, action: PayloadAction<Creditor>) => {
            state.selectedCreditor = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getNegotiationOptionsThunk.pending, (state) => {
            state.loadingNegotiationOptions = true
        })
        .addCase(
            getNegotiationOptionsThunk.fulfilled,
            (state, action: PayloadAction<[NegotiationOption[], Receivable[]] | TGatewayError>) => {
                if(Array.isArray(action.payload)) {
                    state.negotiationOptions = action.payload[0]
                    state.receivables = state.receivables.concat(action.payload[1])
                    state.loadingNegotiationOptions = false
                    if(action.payload[1].length ==  0) {
                        state.noReceivable = true
                    }
                }else{
                    state.loadingNegotiationOptions = false 
                    state.negotiationOptions = []
                }
        })
        .addCase(
            getNegotiationOptionsThunk.rejected,
            (state) => {
                state.receivableError = true
        })
        .addCase(getAgreementsThunk.pending, (state) => {
            state.loadingAgreements = true
        })
        .addCase(
            getAgreementsThunk.fulfilled,
            (state, action: PayloadAction<[Agreement[], Receivable[]] | TGatewayError>) => {
                if(Array.isArray(action.payload)) {
                    state.agreements = action.payload[0]
                    state.receivables = state.receivables.concat(action.payload[1])
                    state.loadingAgreements = false
                } 
        })
        .addCase(getCreditorsThunk.pending, (state) => {
            state.loadingCreditors = true
        })
        .addCase(
            getCreditorsThunk.fulfilled,
            (state, action: PayloadAction<Wallet[] | TGatewayError>) => {
                if(Array.isArray(action.payload)) {
                    const portalCreditors = getPortalCreditorFromWallet(action.payload)
                    state.wallets = action.payload
                    state.creditors = portalCreditors
                    state.loadingCreditors = false
                } 
        })
        .addCase(
            getReceivableListPaymentThunk.pending,
            (state, {meta}) => {
                
                //TODO
                const selectedReceivable = state.receivables.filter(e => e.uuid == meta.arg.receivableUUID) 
                if(selectedReceivable.length > 0 && selectedReceivable[0].installments){
                    const selectedInstallmentUUID = selectedReceivable[0].installments[meta.arg.installmentNumber - 1].uuid 
                    state.paymentsLoading = state.paymentsLoading.concat([selectedInstallmentUUID])
                }        
        })
        .addCase(
            getReceivableListPaymentThunk.fulfilled,
            (state, action: PayloadAction<[string, number, Payment]  | TGatewayError>) => {
                if(Array.isArray(action.payload)) { 
                    const receivableUUID = action.payload[0]
                    const receivableIndex = state.receivables.findIndex(receivable => receivable.uuid == receivableUUID)

                    if(receivableIndex){
                        let selectedReceivable = state.receivables[receivableIndex]

                        if(selectedReceivable.installments){
                            selectedReceivable.installments[action.payload[1] - 1].payment = action.payload[2]
                            const installmentUUID = selectedReceivable.installments[action.payload[1] - 1].uuid
                            state.paymentsLoading = state.paymentsLoading.filter(e => e != installmentUUID)
                        }

                        state.receivables[receivableIndex] = selectedReceivable
                        
                    }
                }
                
        })
    }
})

const filterUniqueObjectsWithUUID = (objects: {[uuid: string]: any}) => {
    const uuidMap: {[uuid: string]: any} = {}

    // @ts-ignore
    objects.forEach(obj => {
        uuidMap[obj.uuid] = obj
    })

    return Object.keys(uuidMap).map(key => {
        return uuidMap[key]
    })
}


export const selectLoadingCreditors = (state: RootState) => state.receivables.loadingCreditors;
export const selectLoadingAgreements = (state: RootState) => state.receivables.loadingAgreements;
export const selectPaymentsLoading = (state: RootState) => state.receivables.paymentsLoading;
export const selectNegotiationOptions = (state: RootState) => state.receivables.negotiationOptions;
export const selectLoadingNegotiationOptions = (state: RootState) => state.receivables.loadingNegotiationOptions;
export const selectAgreements = (state: RootState) => state.receivables.agreements
export const selectReceivables = (state: RootState) => filterUniqueObjectsWithUUID(state.receivables.receivables)
export const selectCreditors = (state: RootState) => state.receivables.creditors
export const selectWallets = (state: RootState) => state.receivables.wallets
export const selectReceivableError = (state: RootState) => state.receivables.receivableError
export const selectNoReceivables = (state: RootState)  => state.receivables.noReceivable
export const selectReceivablesInNegotiation = (negotiationUUID: string | undefined) => (state: RootState) => {
    const negotiationOption = state.receivables.negotiationOptions.filter(
        (negotiationOption) => negotiationOption.uuid == negotiationUUID
    )
    if(negotiationOption.length > 0) {
        const obligatoryReceivables = state.receivables.receivables.filter(receivable => (
            negotiationOption[0].obligatoryReceivablesUUID.includes(
                receivable.uuid
            )
        ))

        const extraUUIDS = negotiationOption[0].negotiationOptionItems.map(item => item.receivableUUID)
        const extraReceivables = state.receivables.receivables.filter(receivable => (
            extraUUIDS.includes(receivable.uuid)
        ))

        return [
            ...obligatoryReceivables,
            ...extraReceivables
        ]
    }

    return []
}

export const { resetReceivablesList, negotiate, setCreditor } = ReceivableListSlice.actions

export default ReceivableListSlice.reducer;