
import React from "react";
import { AgreementCardTableAggregationType } from "../interfaces";
import AgreementCardTableBodyAggregation from "./AgreementCardTableBodyAggregation";
import AgreementCardTableHeaderAggregation from "./AgreementCardTableHeaderAggregation";
import { AgreementCardTable, AgreementCardTableBody, AgreementCardTableCell, AgreementCardTableHead, AgreementCardTableHeader, AgreementCardTableRow } from "./AgreementCardTableStyle";


const AgreementCardTableAggregation = ({
    agreement,
    receivables,
    creditor,
    currentReceivable,
    originalReceivables,
    paymentsLoading
} : AgreementCardTableAggregationType) => {
    return (
        <>
            <AgreementCardTable className="mt-0 md:mt-2">
                <AgreementCardTableHeaderAggregation />

                <AgreementCardTableBodyAggregation 
                    agreement={agreement}
                    receivables={receivables}
                    originalReceivables={originalReceivables}
                    currentReceivable={currentReceivable}
                    paymentsLoading={paymentsLoading}
                />
            </AgreementCardTable>
        </>
    )
}

export default AgreementCardTableAggregation