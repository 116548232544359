import tw from "tailwind-styled-components";

//cheat sheet https://tailwindcomponents.com/cheatsheet/ 
export const MainBannerContainer = tw.div`
    w-full
    600:h-[400px]
    400:h-[70%]
`

export const Container = tw.div`
    h-full
    w-full
    overflow-hidden
    flex
    flex-wrap-reverse
`

export const FormContainer = tw.div`
    flex
    h-full
    w-full
    items-center
    justify-center
    flex-col
    py-8
    md:items-start
    md:w-9/12
    md:h-full
    md:pl-[25%]
    md:pr-6
    z-[1]
`

export const BannerContainer = tw.div`
    flex
    justify-center
    h-full
    w-full
    overflow-hidden
    md:w-full
    md:h-full
    md:justify-start
    md:items-end
`



export const Image = tw.img`
    h-max
    aspect-auto
    md:h-full
    max-h-[120%]
`

export const ModalContainer = tw.div`
    flex
    w-full
    items-center
    justify-center
    flex-col
    py-8
    px-4
    md:w-full
    md:h-full
`

export const SignUpContainer = tw.div`
  p-3
  rounded
  flex
  justify-center
  font-semibold
  break-normal
  outline-none
  overflow-hidden
  flex-wrap
  font-roboto
`

export const FormTitle = tw.div`
  text-left
  justify-left
  text-4xl
  m-1
  md:text-left
`

export const Form2ndTitle = tw.div`
  text-left
  justify-center
  text-3xl
  m-1
  md:text-left
`

export const FormLabel = tw.div`
  text-left
  text-base
  p-1
  m-1
  font-normal
  md:text-left
`

export const ButtonInputContainer = tw.div`
  flex
  flex-wrap
  w-full
  place-content-center
  md:flex-nowrap
`
export const AltButtonInputContainer = tw.div`
  flex
  flex-wrap
  w-full
  place-content-center
`

export const FormBackground = tw.div`
  max-w-sm
  py-2
  px-5
  m-3
  ml-0
  h-10
  rounded
  font-thin
  w-full
`

export const FormButton = tw.button`
  w-52
  h-10
  m-3
  py-1
  px-5
  text-md
  rounded
  flex
  font-normal
  justify-center
  items-center
  overflow-hidden
`

export const FormError = tw.div`
font-normal
w-11/12
`
