import React, {ChangeEvent, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../../hooks';
import {
    selectReceivables,
    selectReceivablesInNegotiation,
} from '../ListPanel/receivableListSlice';
import {
    selectIsLoading,
    selectPreAgreement,
    selectSelectedReceivables,
    setNegotiationOption,
    selectInstallmentNumber,
    selectPaymentMethod,
    selectPaymentDate,
    setPaymentMethod,
    setInstallmentNumber,
    setPaymentDate,
    selectSelectedNegotiationOption,
    selectSelectedReceivablesUUID,
    selectIsRemat,
    selectAvailablePaymentDates,
    selectAdditionalReceivablesUUID,
    setEmail,
    setPhone,
    selectIsEmailValid,
    selectIsPhoneValid,
    selectExtraReceivablesUUID,
    selectSelectedNegotiationOptionItemsUUID,
    selectNegotiationLimits,
    selectNegotiationError
} from './negotiationSlice';
import { Receivable } from '../../../../common/entities/Receivable/Receivable';
import { NegotiationOption } from '../../../../common/entities/NegotiationOption/NegotiationOption';
import { NegotiationOptionItem } from '../../../../common/entities/NegotiationOption/NegotiationOption';
import { Limits, PreAgreement } from '../../../../common/entities/PreAgreement/PreAgreement';
import LoadingComponent from '../../../../common/features/LoadingComponent';
import NegotiationExtras from '../../../../common/features/NegotiationExtras';
import { NegotiationOptionsSection } from '../../../../common/features/section/NegotiationSection/NegotiationOptionsSection';
import { PaymentMethodOptionsSection } from '../../../../common/features/section/NegotiationSection/PaymentMethodOptionsSection';
import { InstallmentsOptionsSection } from '../../../../common/features/section/NegotiationSection/InstallmentsOptionsSection'; 
import PaymentDateSection from '../../../../common/features/section/NegotiationSection/PaymentDateSection';
import { getAvailablePaymentDates, getNegotiationLimitsThunk, getPreagreementThunk } from './negotiationSliceThunk';
import { ContactOptionsSection } from '../../../../common/features/section/NegotiationSection/ContactOptionsSection';
import { phoneMask, removePhoneMask } from '../../../../common/utils/mask/phoneMask';
import { useCookies } from 'react-cookie';
import { postLogThunk } from '../../logs/logsThunk';
import { selectDocumentNumber } from '../../../../common/features/AuthForm/authFormSlice';


const NegotiationPanel = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [cookies] = useCookies(['accessUUID']);
    const accessUUID = cookies.accessUUID

    const negotiationOption: NegotiationOption = useAppSelector(
        selectSelectedNegotiationOption
    )

    const selectedReceivables: Receivable[] | null = useAppSelector(
        selectSelectedReceivables
    )

    const receivables: Receivable[] = useAppSelector(
        selectReceivables
    )

    const isLoading: boolean = useAppSelector(
        selectIsLoading
    )

    const isRemat: boolean = useAppSelector(
        selectIsRemat
    )

    const preAgreement: PreAgreement | null = useAppSelector(
        selectPreAgreement
    )

    const selectedInstallmentNumber: number = useAppSelector(
        selectInstallmentNumber
    )
    const selectedPaymentMethod: string = useAppSelector(
        selectPaymentMethod
    )
    const selectedPaymentDate: string = useAppSelector(
        selectPaymentDate
    )

    const extraReceivablesUUID: string[] = useAppSelector(selectExtraReceivablesUUID)
    const selectedAdditionalReceivablesUUID: string[] = useAppSelector(selectAdditionalReceivablesUUID)
    const selectedNegotiationOptionItemsUUID: string[] = useAppSelector(selectSelectedNegotiationOptionItemsUUID)
    const negotiationLimits : Limits | null = useAppSelector(selectNegotiationLimits)
    const selectedReceivablesUUID: string[] = useAppSelector(
        selectSelectedReceivablesUUID
    )
    const availablePaymentDates: string[] = useAppSelector(selectAvailablePaymentDates);
    const isEmailValid = useAppSelector(selectIsEmailValid)
    const isPhoneValid = useAppSelector(selectIsPhoneValid)
    const documentNumber = useAppSelector(selectDocumentNumber)
    const negotiationError = useAppSelector(selectNegotiationError)

    if(negotiationError && negotiationError.errorCode == 'EXT01'){
        navigate('/contratos')
    }

    const _logFunction = (
        eventName: string,
        formData: {[key: string]: any} | null,
        details: {[key: string]: any} | null
    ) => {
        dispatch(postLogThunk(
            {
                event: eventName,
                documentNumber: documentNumber,
                formData: formData,
                details: details,
                accessUUID: accessUUID
            }
        ))
    }

    const negotiationOptionFunction = (negotiationOption: NegotiationOption, 
        negotiationOptionItem: NegotiationOptionItem) => {
        return('')
    }
    const installmentsFunction = (event: ChangeEvent<HTMLSelectElement>) => {
        dispatch(setInstallmentNumber(Number.parseInt(event.target.value)))
        _logFunction(
            'CHANGED_INSTALLMENT_NUMBER_NEGOTIATION',
            {installmentNumber: Number.parseInt(event.target.value)},
            {negotiationOptionUUID: negotiationOption.uuid}
        )
        return('')
    }
    const paymentFunction = (paymentMethod: string) => {
        dispatch(setPaymentMethod(paymentMethod))
        _logFunction(
            'CHANGED_PAYMENT_METHOD_NEGOTIATION',
            {paymentMethod: paymentMethod},
            {negotiationOptionUUID: negotiationOption.uuid}
        )
        return('')
    }
    const paymentDateFunction = (event: ChangeEvent<HTMLSelectElement>) => {
        dispatch(setPaymentDate(event.target.value))
        _logFunction(
            'CHANGED_PAYMENT_DATE_NEGOTIATION',
            {paymentDate: event.target.value},
            {negotiationOptionUUID: negotiationOption.uuid}
        )
        return('')
    }

    const handleEmailChangeFunction = (event: ChangeEvent<HTMLSelectElement>) => {
        dispatch(setEmail(event.target.value))
    }

    const handlePhoneChangeFunction = (event: ChangeEvent<HTMLSelectElement>) => {
        dispatch(setPhone(removePhoneMask(event.target.value)))
        event.target.value = phoneMask(event.target.value)
    }
    
    const fixPaymentMethod = (paymentMethod: string, installmentNumber: number, remat: boolean): string => {
        let tempPaymentMethod = paymentMethod
        const pluralMethodsTransformMapping = {
            'Boleto': 'Boleto a Vista',
            'Cartao': 'Cartao',
        }
        const singularMethodsTransformMapping = {
            'PIX a Vista': 'PIX a Vista',
            'Cartao': 'Cartao',
            'Boleto a Vista': 'Boleto'
        }

        if(installmentNumber > 1 && Object.keys(singularMethodsTransformMapping).includes(paymentMethod)) {
            tempPaymentMethod = singularMethodsTransformMapping[
                paymentMethod as keyof typeof singularMethodsTransformMapping
            ]
        } else if(installmentNumber == 1 && Object.keys(pluralMethodsTransformMapping).includes(paymentMethod)){
            tempPaymentMethod = pluralMethodsTransformMapping[
                paymentMethod as keyof typeof pluralMethodsTransformMapping
            ]
        } else {
            if(installmentNumber > 1) {
                tempPaymentMethod = 'Cartao'
            } else {
                tempPaymentMethod = 'Boleto'
            }
        }

        if(remat) {
            tempPaymentMethod = `${tempPaymentMethod} Rematricula`
        }

        return tempPaymentMethod
    }

    const mappedReceivables: {[uuid: string]: Receivable} = {}
    receivables.forEach(receivable => mappedReceivables[receivable.uuid] = receivable)
    const additionReceivablesMapped = selectedAdditionalReceivablesUUID.map(uuid => mappedReceivables[uuid])
    const cleanAdditionalReceivables = additionReceivablesMapped.filter((uuid) => uuid != undefined)

    const _selectedReceivables = [
        ...selectedReceivables,
        ...(cleanAdditionalReceivables.length > 0 ? cleanAdditionalReceivables : [])
    ]

    useEffect(() => {
        if(negotiationOption && receivables && negotiationLimits) {
            dispatch(getPreagreementThunk({
                negotiationOptionUUID: negotiationOption.uuid,
                negotiationOptionItemsUuids: selectedNegotiationOptionItemsUUID,
                paymentMethod: selectedPaymentMethod,
                numberOfInstallments: selectedInstallmentNumber,
                paymentDate: selectedPaymentDate
            }))
        } else {
            if(!negotiationOption || !receivables)
            navigate('/contratos')
        }
    }, [
        selectedInstallmentNumber,
        selectedPaymentDate,
        negotiationLimits
    ])

    useEffect(() => {
        if(negotiationOption && receivables) {
            dispatch(getNegotiationLimitsThunk({
                negotiationOptionUUID: negotiationOption.uuid,
                paymentMethod: selectedPaymentMethod,
                negotiationOptionItemsUUIDs: selectedNegotiationOptionItemsUUID,
                documentNumber:documentNumber
            }))
        } else {
            navigate('/contratos')
        }
    }, [
        selectedPaymentMethod,
        selectedNegotiationOptionItemsUUID,
        isRemat
    ])

    return (
        <>
            {isLoading && (
                <div style={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: "#3b2f2fd1",
                    position: "absolute",
                    zIndex: 10,
                    top: 0,
                    left: 0,
                    color: 'white',
                    fontSize: 'larger'
                    }}
                >
                    <div className="grid h-screen place-items-center">
                        <LoadingComponent />
                    </div>
                </div>
            )}
            <NegotiationOptionsSection 
                handleChangeFunction={negotiationOptionFunction}
                negotiationOption={negotiationOption}
                receivables={receivables}
                isLoading={isLoading}
                extraReceivablesUUID={extraReceivablesUUID}
                selectedReceivables={_selectedReceivables}
            />
            <PaymentMethodOptionsSection 
                negotiationOption={negotiationOption}
                handleChangeFunction={paymentFunction}
                isLoading={isLoading}
                selectedPaymentMethod={selectedPaymentMethod}
                preAgreement={preAgreement}
                selectedReceivables={selectedReceivablesUUID}
                selectedInstallmentNumber={selectedInstallmentNumber}
                rematReceivablesUUID={
                    negotiationOption.negotiationOptionItems.filter(item => item.extraDomain?.data.remat).map(item => item.receivableUUID)
                }
            />
            {
                negotiationLimits && 
                <InstallmentsOptionsSection 
                    handleChangeFunction={installmentsFunction}
                    isLoading={isLoading}
                    negotiationOption={negotiationOption}
                    selectedInstallmentNumber={selectedInstallmentNumber}
                    preAgreement={preAgreement}
                    selectedPaymentMethod={selectedPaymentMethod}
                    negotiationLimits={negotiationLimits}
                />
            }
            {
                (negotiationLimits && negotiationLimits.availableDates.length > 1) && 
                <PaymentDateSection
                    isLoading={isLoading}
                    handleChangeFunction={paymentDateFunction}
                    selectedPaymentDate={selectedPaymentDate}
                    negotiationOption={negotiationOption}
                    availablePaymentDates={availablePaymentDates}
                    negotiationLimits={negotiationLimits}
                />
            }
            
            
        </>
    )
}

export default NegotiationPanel;