import React from 'react';
import { range } from '../../../../common/utils/range';
import { Limits, PreAgreement, PreAgreementInstallment } from '../../../../common/entities/PreAgreement/PreAgreement';
import { Product } from '../../../../common/entities/Product/Product';
import { Installment } from '../../../../common/entities/Receivable/Installment';
import { CreateAgreementResponse } from '../../../../common/entities/Agreement/Agreement';
import { CAD, TCD } from '../../../../common/entities/Negotiation/Documents';
import { TCreateAgreementResponse } from '../../../../common/gateways/converters/createAgreementConverter';


export const Builder = {
    preAgreement: (installmentNumber: number, preagreementUUID: string, renrollment: boolean) => (  
        {
            type: "PreAgreement",
            uuid: preagreementUUID,
            installments: range(installmentNumber).map((n) => (
                {
                    type: "PreAgreementInstallment",
                    installmentNumber: installmentNumber,
                    initialValue: 10000,
                    fee: 100,
                    fine: 100,
                    interest: 100,
                    taxes: 100,
                    currentValue: (10000 + (100 * installmentNumber) + (renrollment ? 50000 : 0)),
                    dueDate: `2023/0${1 + installmentNumber}/04`
                }
            )) as PreAgreementInstallment[],
            discount: 0,
            fee: 0,
            fine: 0,
            interest: 0,
            taxes: 0,
            originalReceivableValue: 100,
            product:{
                type: "Product",
                name: '',
                description: ''
            } as Product,
            currentValue: 200,
            downpayment: 102,
            downpayment_date: '2023-04-24',
            status: 'PENDING',
            paymentOption: "BANKSLIP"
        } as PreAgreement
    )
}

export const Mocks = {
    getPreAgreements: (
        negotiationOptionUUID: string,
        receivablesUUID: string[],
        installmentNumber: number
    )  => {
            return Builder.preAgreement(
                installmentNumber, negotiationOptionUUID, receivablesUUID.includes('R4')
            )
    },
    getNegotiationLimits: () => {
        return{
            type: 'Limits',
            uuid: '12345678',
            availableDates: [
                '2023-05-05',
                '2023-06-05',
                '2023-07-05',
                '2023-08-05'
            ],
            installmentsInfo: [1, 2, 3]
        } as Limits
    },
    createAgreement: ()  => {
        return {
            type: "CreateAgreementResponse",
            uuid: '33213232',
            originalReceivablesUUID: [],
            emissionDate: '2023-10-10',
            currentReceivable: {
                uuid: 'R1',
                type: "Receivable",
                originalReceivableValue: 0,
                product: {
                    type: "Product",
                    name: 'Fatura 10-11-2022',
                    description: 'Mensalidades de 2023'
                } as Product,
                fee: 0,
                fine: 0,
                interest: 0,
                taxes: 0,
                currentValue: 1000,
                status: 'PENDING',
                downpayment: {
                    value: 100, 
                    payment: {
                        paymentDate: '2023-05-05',
                        paidValue: 100,
                        paymentMethod: 'CREDIT_CARD',
                        paymentInfo: {url: 'https://google.com'}
                    }
                },
                installments: [
                    { 
                        type: "Installment",
                        uuid: 'I1',
                        installmentNumber: 0,
                        fee: 0,
                        fine: 0,
                        interest: 0,
                        taxes: 0,
                        initialValue: 0,
                        currentValue: 0,
                        dueDate: '2023-05-05',
                        status: 'PENDING',
                        payment: {
                            type: "Payment",
                            paymentDate: '2023-05-05',
                            paidValue: 0,
                            paymentMethod: '',
                            paymentInfo: ''
                        }
                    } as Installment
                ]
            
            }
        } as CreateAgreementResponse
    },
    getCAD: () => {
        return {
            type: 'CAD',
            url: 'https://youtube.com.br'
        } as CAD
    },
    getTCD: () => {
        return {
            type: 'TCD',
            url: 'https://youtube.com.br'
        } as TCD
    },
    createAgreementDTO: () => {
        return {
            "payload": {
                "agreement": {
                    "uuid": "c481db92-cc90-44ae-bdfd-f9923b1f833b",
                    "original_receivables_uuids": ["c49ff474-dd7c-4a08-9604-06a321c3c2ad", "88483ef8-171b-45ab-9423-7dded0295229"],
                    "current_receivable_uuid": "9df844c7-e901-4dae-b0bd-5551de36055d"
                },
                "aggregates": {
                    "receivables": {
                        "c49ff474-dd7c-4a08-9604-06a321c3c2ad": {
                            "original_receivable_value": 19670,
                            "product": {
                                "name": "053 - GRADUA EAD SUPERIOR DE TECNOLOGIA EM GESTÃO DE RECURSOS HUMANOS",
                                "description": "4/2023"
                            },
                            "fee": 0,
                            "interest": 0,
                            "fine": 0,
                            "taxes": 0,
                            "current_value": 19670,
                            "downpayment": {
                                "value": 19670,
                                "payment": null
                            },
                            "installments": [{
                                "uuid": "819466b2-cef6-4320-a3af-d544a0eb4f2c",
                                "installment_number": 1,
                                "initial_value": 19670,
                                "fee": 0,
                                "interest": 0,
                                "fine": 0,
                                "taxes": 0,
                                "current_value": 19670,
                                "due_date": "2023-04-17",
                                "payment": null,
                                "status": "NEGOTIATED"
                            }],
                            "status": "NEGOTIATED"
                        },
                        "88483ef8-171b-45ab-9423-7dded0295229": {
                            "original_receivable_value": 19670,
                            "product": {
                                "name": "053 - GRADUA EAD SUPERIOR DE TECNOLOGIA EM GESTÃO DE RECURSOS HUMANOS",
                                "description": "3/2023"
                            },
                            "fee": 0,
                            "interest": 0,
                            "fine": 0,
                            "taxes": 0,
                            "current_value": 19670,
                            "downpayment": {
                                "value": 19670,
                                "payment": null
                            },
                            "installments": [{
                                "uuid": "cb593f7d-8303-4bda-bad4-a686edf8f99d",
                                "installment_number": 1,
                                "initial_value": 19670,
                                "fee": 0,
                                "interest": 0,
                                "fine": 0,
                                "taxes": 0,
                                "current_value": 19670,
                                "due_date": "2023-03-15",
                                "payment": null,
                                "status": "NEGOTIATED"
                            }],
                            "status": "NEGOTIATED"
                        },
                        "9df844c7-e901-4dae-b0bd-5551de36055d": {
                            "original_receivable_value": 39340,
                            "product": {
                                "name": "Negociação Kroton",
                                "description": null
                            },
                            "fee": 0,
                            "interest": 0,
                            "fine": 0,
                            "taxes": 0,
                            "current_value": 39340,
                            "downpayment": {
                                "value": 0,
                                "payment": null
                            },
                            "installments": [{
                                "uuid": "413de3a7-afc6-479e-bbd5-87901aa21b68",
                                "installment_number": 1,
                                "initial_value": 39340,
                                "fee": 0,
                                "interest": 0,
                                "fine": 0,
                                "taxes": 0,
                                "current_value": 39340,
                                "due_date": "2023-04-28",
                                "payment": null,
                                "status": "OPENED"
                            }],
                            "status": "AWAITING_PAYMENT"
                        }
                    }
                }
            },
            "errors": null
        } as TCreateAgreementResponse
    }
}