import React, {useEffect} from 'react'
import { NegotiationOption } from '../../../../../common/entities/NegotiationOption/NegotiationOption';
import { Receivable } from '../../../../../common/entities/Receivable/Receivable';
import NegotiationOptionCard from '../../../../../common/features/card/NegotiationOptionCard';
import { Creditor } from '../../../../../common/entities/Creditor/Creditor';
import getKey from '../../../../../common/utils/key/getKey';
import { getObligatoryReceivablesFromNegotiationOption, getReceivablesFromNegotiationOption } from '../../../../../common/entities/NegotiationOption/filter';
import { Client } from '../../../../../common/entities/Client/Client';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { selectNoReceivables } from '../receivableListSlice';

export interface INegotiationOptionList {
    negotiationOptions: NegotiationOption[],
    receivables: Receivable[],
    creditor: Creditor,
    client: Client,
    clickCallback: (arg1: NegotiationOption) => void
}

const NegotiationOptionList = (
    {
        negotiationOptions,
        receivables,
        creditor,
        client,
        clickCallback,
    }:  INegotiationOptionList
) => {
    if(negotiationOptions.length <= 0) {
        return (<>
            <div
                className="mb-4 rounded-lg bg-success-100 px-6 py-5 text-base text-success-700"
                role="alert"
            >
                Não encontramos registros de dívidas em nossa base de dados
            </div>
        </>)
    }
    return (
        <>
            { 
                negotiationOptions.map((negotiationOption) => {
                    const selectedReceivables = getReceivablesFromNegotiationOption(
                        {negotiationOption, receivables}
                    )
                    
                    return(
                        <div className="w-full py-4" key={getKey()}>
                            <NegotiationOptionCard 
                                negotiationOption={negotiationOption}
                                receivables={selectedReceivables}
                                creditor={creditor}
                                clickCallback={clickCallback}
                                client={client}
                            />
                        </div>
                    )
                })
            }

        </>
    )
}

export default NegotiationOptionList;
