import axios, { AxiosResponse } from 'axios'
import { TGatewayError, GatewayError, APIErrorCodes } from './errors'
import { clientConverter } from './converters/clientConverter'
import { Client } from '../entities/Client/Client'
import { delay } from '../utils/delay'
import { Settings } from '../../common/settings';


interface ISignInGateway {
    username: string
    password?: string
    dateOfBirth?: string
}

interface ISignUpGateway  {
    documentNumber: string
    name: string
    email: string
    password: string
}

interface ICheckUserGateway {
    documentNumber: string
}

interface IAccessLinkValidationGateway {
    token: string
}

type TEmailDTO = {
    email: string,
    verified: boolean,
    main: boolean
}

type TPhoneDTO = {
    phone_number: string,
    verified: boolean,
    main: boolean
}

type TClientDTO = {
    uuid: string,
    documentNumber: string,
    dateOfBirth: string,
    name: string,
    emails: TEmailDTO[],
    phones: TPhoneDTO[],
    verified: boolean,
    accessToken?: string
}

type TAPIError = {
    code: keyof typeof APIErrorCodes,
    message: string
}

type TAuthGatewayResponse = {
    payload: {
        client: TClientDTO | null,
        accessToken?: string
    },
    errors?: TAPIError[]
}

type TIAccessLinkValidationResponse = {
    payload: {
        documentNumber: string
    },
    error?: TAPIError | null
}

const MockedSignInGatewayResponse: Client = {
    type: "Client",
    uuid: "CLI01",
    documentNumber: "32762751802",
    dateOfBirth: "1989-07-29",
    name: "Ricardo Kiyoshi Sasaki",
    emails: [
        "ricardo@adimplere.com.br",
    ],
    phones: [],
    verified: true,
    accessToken: '123.123.123'
}

export const signInGateway = async (
    {username, password, dateOfBirth}: ISignInGateway
): Promise<TGatewayError | Client> => {
    const serverHost: string | undefined = Settings.SERVER_HOST

    let data = {}

    if(password){
        data = {
            username: username,
            password: password
        }
    }else if(dateOfBirth){
        data = {
            auth_type: 'CHECK_DATE_OF_BIRTH',
            document_number: username,
            date_of_birth: dateOfBirth
        }
    }else{
        data = {
            auth_type: 'CHECK_DOCUMENT_NUMBER',
            document_number: username,
            date_of_birth: ''
        }
    }

    if(Settings.env == 'production') {
        if(serverHost === undefined) {
            return GatewayError('DEV101')
        }

        const response: AxiosResponse<TAuthGatewayResponse, any> = await axios.post(
            `${serverHost}/api/v1/auth/sign_in`,
            data
        )
        if(response.data.errors) {
            return GatewayError(response.data.errors[0].code)
        } else {
            if(response.data.payload.client) {
                const client =  clientConverter(response.data.payload.client) 
                client.accessToken = response.data.payload.accessToken

                return client
            } else {
                return GatewayError('CLI404')
            }
        }
    } else {
        await delay(1000)
        return MockedSignInGatewayResponse
    }
}


export const signUpGateway = async ({
    documentNumber,
    name,
    email,
    password
}: ISignUpGateway): Promise<Client | TGatewayError> => {
    const serverHost: string | undefined = process.env.SERVER_HOST
    if(process.env.NODE_ENV == "production") { 
        if(serverHost === undefined) {
            return GatewayError('DEV101')
        }

        const response: AxiosResponse<TAuthGatewayResponse, any> = await axios.post(
            `${serverHost}/api/v2/auth/sign_up`,
            {
                documentNumber,
                name, 
                email,
                password
            }
        )
        if(response.data.errors) {
            return GatewayError(response.data.errors[0].code as keyof typeof APIErrorCodes)
        } else {
            if(response.data.payload.client) {
                return clientConverter(response.data.payload.client)
            } else {
                return GatewayError('CLI404')
            }
        }

    } else {
        return {
            type: "Client",
            uuid: "CLI02",
            documentNumber: documentNumber,
            name: name,
            dateOfBirth: '1989-07-29',
            emails: [
                email,
            ],
            phones: [],
            verified: false
        }
    }
}

export const checkUserGateway = async ({documentNumber}: ICheckUserGateway): Promise<Client | TGatewayError> => {
    const serverHost = Settings.SERVER_HOST
    const env = Settings.env

    if(env == "production") { 
        if(serverHost === undefined) {
            return GatewayError('DEV101')
        }

        const response: AxiosResponse<TAuthGatewayResponse, any> = await axios.post(
            `${serverHost}/api/v1/auth/check`,
            {
                documentNumber,
            }
        )
        if(response.data.errors) {
            return GatewayError(response.data.errors[0].code)
        }

        if(response.data.payload.client) {
            return clientConverter(response.data.payload.client)
        }
        return GatewayError('CLI404')
    } else {
        if(MockedSignInGatewayResponse.documentNumber == documentNumber) {
            return MockedSignInGatewayResponse
        } else {
            return GatewayError('CLI404')
        }
    }
}


export const accessLinkValidationGateway = async ({token}: IAccessLinkValidationGateway): Promise<string | TGatewayError> => {
    const serverHost = Settings.SERVER_HOST
    const env = Settings.env

    if(env == "production") { 
        if(serverHost === undefined) {
            return GatewayError('DEV101')
        }

        const response: AxiosResponse<TIAccessLinkValidationResponse, any> = await axios.get(
            `${serverHost}/api/v1/auth/validate_token/${token}`
        )
        if(response.data.error) {
            return GatewayError(response.data.error.code)
        }

        if(response.data.payload) {
            return response.data.payload.documentNumber
        }

        return GatewayError('USER404')
    } else {
        return '32762751802'
    }
}
