import React, { useEffect } from "react";
import './download_icon.png' 
import { SuccessSection } from "../../../common/features/section/SuccessSection";
import { Product } from "../../../common/entities/Product/Product";
import { Installment } from "../../../common/entities/Receivable/Installment";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { resetNegotiation, selectCadURL, selectClientGeoLocation, selectCompletedAgreement, selectCurrentPayment, selectIsLoadingCAD, selectIsLoadingTCD, selectTcdURL } from "../../components/Receivable/Negotiation/negotiationSlice";
import LoadingComponent from "../../../common/features/LoadingComponent";
import { getTCDandCADThunk, getNegotiationReceivablePaymentThunk } from "../../components/Receivable/Negotiation/negotiationSliceThunk";
import { resetAuthForm, selectDocumentNumber, selectUserIsAuthenticated } from "../../../common/features/AuthForm/authFormSlice";
import { resetReceivablesList } from "../../components/Receivable/ListPanel/receivableListSlice";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

export const SuccessPanel = ({}) => {
    const dispatch = useAppDispatch()
    const agreement = useAppSelector(selectCompletedAgreement)
    const agreementPayment = useAppSelector(selectCurrentPayment)
    const isLoadingCad = useAppSelector(selectIsLoadingCAD)
    const isLoadingTcd = useAppSelector(selectIsLoadingTCD)
    const userIsAuthenticated = useAppSelector(selectUserIsAuthenticated)
    const cadURL = useAppSelector(selectCadURL)
    const tcdURL = useAppSelector(selectTcdURL)
    const clientGeoLocation = useAppSelector(selectClientGeoLocation)
    const documentNumber = useAppSelector(selectDocumentNumber)
    const navigate = useNavigate()

    const [cookies] = useCookies(['accessUUID']);
    const accessUUID = cookies.accessUUID

    const receivable = agreement?.currentReceivable

    useEffect(() => {
        if(agreement){
            dispatch(getNegotiationReceivablePaymentThunk(
                {
                    receivableUUID: agreement.currentReceivable.uuid,
                    installmentNumber: 1
                }
            ))
        }

        if(agreement && clientGeoLocation){

            dispatch(getTCDandCADThunk({
                agreement:agreement, 
                clientGeoLocation: clientGeoLocation,
                dispatch: dispatch,
                accessUUID: accessUUID,
                documentNumber: documentNumber
            }))
            
        }

    }, [])

    useEffect(()=> {
        if(!userIsAuthenticated){
            dispatch(resetNegotiation())
            dispatch(resetAuthForm())
            dispatch(resetReceivablesList())
            navigate('/')
        }
    }, [userIsAuthenticated])

    if(receivable){
        return(
            <>
                {(isLoadingCad || isLoadingTcd) && (
                    <div style={{
                        height: "100%",
                        width: "100%",
                        backgroundColor: "#3b2f2fd1",
                        position: "absolute",
                        zIndex: 10,
                        top: 0,
                        left: 0,
                        color: 'white',
                        fontSize: 'larger'
                        }}
                    >
                        <div className="grid h-screen place-items-center">
                            <LoadingComponent />
                        </div>
                    </div>
                )}
                <div className="w-full flex flex-col rounded-xl mt-24 bg-white"  style={{boxShadow:'3px 3px 10px #b9b9b9'}}>
                    <div className="w-full
                    rounded-t-md
                    h-2
                    inherit
                    bg-[#35b048]
                    " />
                    
                    <SuccessSection 
                        receivable={receivable}
                        tcdURL={tcdURL}
                        cadURL={cadURL}
                        isLoadingCad={isLoadingCad}
                        isLoadingTcd={isLoadingTcd}
                        agreementPayment={agreementPayment}
                    />
                </div>
            </>
        )
    }else{
        return null
    }
    
}