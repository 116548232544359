import React from "react";
import tw from 'tailwind-styled-components';

export const NegotiationOptionButtonFilled = tw.button`
    bg-[#E99F49]
    text-white
    fw-bold
    fs-lg
    hover:bg-gray-500
    md:w-5/12
    w-9/12
    flex
    justify-center
    items-center
    py-1
    rounded-md
    cursor-pointer
`
export const NegotiationOptionButtonWithBorder = tw.button`
    border
    border-gray-400
    border-2
    text-gray-400
    fw-bold
    fs-lg
    hover:bg-gray-100
    md:w-5/12
    w-9/12
    flex
    justify-center
    items-center
    py-1
    rounded-md
    mb-3
    md:mb-0
    cursor-pointer
`