import { Agreement } from "../../entities/Agreement/Agreement";
import { Installment } from "../../entities/Receivable/Installment";
import { Payment } from "../../entities/Receivable/Payment";
import { Receivable } from "../../entities/Receivable/Receivable";


type TAgreementDTO = {
    uuid: string,
    original_receivables_uuid: string[],
    current_receivable_uuid: string
}

type TReceivableDTO = {
    uuid: string,
    original_receivable_value: number,
    product: {
        name: string,
        description: string
    },
    fee: number,
    interest: number,
    fine: number,
    taxes: number,
    current_value: number,
    status: string,
    installments: TInstallmentDTO[],
}

type TInstallmentDTO = {
    uuid: string,
    installment_number: number,
    initial_value: number,
    fee: number,
    interest: number,
    fine: number,
    taxes: number,
    current_value: number,
    due_date: string,
    payment?: {
        payment_date: string,
        paid_value: number,
        payment_method: string,
    },
    payment_option_data: any,
    status: string
}

export type TPaymentResponse = { 
    payload: {
        payment: {
            downpayment: boolean
            payment_date: string
            paid_value: number
            payment_method: string
            payment_info: {
                due_date: string
                digit_line?: string
                url: string
            }
    },
    errors: [] | null
    }
}

const paymentConverter = (
    dto: TPaymentResponse
    ): Payment => {
    
    const paymentDTO = dto.payload.payment

    const payment: Payment = {
        type: 'Payment',
        paymentDate: paymentDTO.payment_date,
        paidValue: paymentDTO.paid_value,
        paymentMethod: paymentDTO.payment_method,
        paymentInfo: paymentDTO.payment_info,
        isDownpayment: paymentDTO.downpayment
    }

    return payment
}


export default paymentConverter;