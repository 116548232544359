import React, { ChangeEvent } from "react";
import { Limits, PreAgreement } from "../../../../entities/PreAgreement/PreAgreement";
import { formatBRValue } from "../../../../utils/valueFormatters/formatBRValue";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingComponent from "../../../LoadingComponent";
import { NegotiationOption } from "../../../../entities/NegotiationOption/NegotiationOption";
import { convertDateToBRFormat } from "../../../../utils/date/convert";

interface IPaymentDateSection {
    isLoading: boolean
    handleChangeFunction: (event: ChangeEvent<HTMLSelectElement>) => {}
    negotiationOption: NegotiationOption
    selectedPaymentDate: string
    availablePaymentDates: string[]
    negotiationLimits: Limits
}

const PaymentDateSection = ({isLoading, negotiationLimits, handleChangeFunction, negotiationOption, selectedPaymentDate, availablePaymentDates}: IPaymentDateSection) => {

    if(negotiationLimits && negotiationLimits.availableDates.length > 0
        ){
        // return (
        //     <>
        //         <div className='w-full h-auto p-6 bg-white rounded-md my-3' style={{ boxShadow: '3px 3px 10px #b9b9b9' }}>
        //             <p className='font-bold text-2xl text-gray-700'>
        //                 <FontAwesomeIcon icon={faCalendar} /> &nbsp;
        //                 Qual é a melhor data para o pagamento?
        //             </p>
        //             {isLoading && (<LoadingComponent />)}
        //             {!isLoading && negotiationOption.availablePaymentDates &&
        //                 <select style={{paddingLeft: "15px"}} onChange={(e) => {handleChangeFunction(e)}}>
        //                     {negotiationOption.availablePaymentDates.map(paymentDate => (
        //                         <option value={paymentDate} selected={selectedPaymentDate == paymentDate}>{convertDateToBRFormat(paymentDate)}</option>
        //                     ))}
        //                 </select>
        //             }
        //         </div>
        //     </>
        // )

        return (
            <>
                <div className='w-full h-auto p-6 bg-white rounded-md my-3' style={{ boxShadow: '3px 3px 10px #b9b9b9' }}>
                    <p className='font-bold text-2xl text-gray-700'>
                        <FontAwesomeIcon icon={faCalendar} /> &nbsp;
                        Qual é a melhor data para o pagamento?
                    </p>
                    {isLoading && (<LoadingComponent />)}
                    {!isLoading && negotiationLimits.availableDates &&
                        <select style={{paddingLeft: "15px", marginTop: "17px"}} onChange={(e) => {handleChangeFunction(e)}}>
                            {negotiationLimits.availableDates.map((date) => {
                                return(
                                    <option value={date} selected={selectedPaymentDate == date}>{convertDateToBRFormat(date)}</option>
                                )
                            })}
                        </select>

                    }
                </div>
            </>
        )
    }else{
        return null
    }

}

export default PaymentDateSection
