export type TGatewayError = {
    type: "TGatewayError",
    code: keyof typeof APIErrorCodes,
    description: string
}

export const GatewayError = (code: string): TGatewayError => {
    return {
        type: "TGatewayError",
        code: code as keyof typeof APIErrorCodes,
        description: APIErrorCodes[code as keyof typeof APIErrorCodes]
    }
}

export const APIErrorCodes = {
    'USER_404':"User not found",
    'USER404':"User not found",
    'DEV101': "AUTH_SERVER_HOST not configured in env",
    'DEV500': "Undefined Error",
    'DEV404': "Type Error in SignIn Thunk",
    'CLI404': "Client not found",
    'CLI403': "Validation error",
    'EXT01': "API returned an error",
    'REMAT01' : "Client remat is not available"
}
