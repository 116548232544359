import React from "react";
import './loadings.css'

export const Loadings = ({variant='bars', colorHex='#fff', opacity='1'}) => {
    switch (variant) {
        case 'bars':
            return(
                <div className={`loading-bars`} style={{opacity: opacity}}>
                    <div style={{background: colorHex}}></div>
                    <div style={{background: colorHex}}></div>
                    <div style={{background: colorHex}}></div>
                </div>
            )
            break;
    
        case 'circle':
            return(
                <div className={`loading-ring bg-[${colorHex}]`}>
                    <div style={{borderColor:`${colorHex} transparent transparent transparent`}}></div>
                    <div style={{borderColor:`${colorHex} transparent transparent transparent`}}></div>
                    <div style={{borderColor:`${colorHex} transparent transparent transparent`}}></div>
                    <div style={{borderColor:`${colorHex} transparent transparent transparent`}}></div>
                </div>
            )
            break;
        
        default:
            return null
    }
}