import React from "react";
import { _returnCreditorsListContainer, _returnCreditorIsLoadingContainer } from "./creditorsListFunctions";
import { CreditorPanelType } from "./interfaces";
import { CreditorsPanelHeader } from "./CreditorsPanelHeader";


export const CreditorsPanel = ({isLoading, creditors, clickCallback, client} : CreditorPanelType) => {

    return(
        <div className="w-full h-auto p-3">
            <CreditorsPanelHeader client={client}/>
            {_returnCreditorIsLoadingContainer({isLoading:isLoading})}
            {_returnCreditorsListContainer({
                isLoading:isLoading, 
                creditors:creditors, 
                clickCallback:clickCallback
                })
            }
        </div>
    )

}
