import { NegotiationOption, NegotiationOptionItem } from "../../entities/NegotiationOption/NegotiationOption"
import { Installment } from "../../entities/Receivable/Installment"
import { Payment } from "../../entities/Receivable/Payment"
import { Receivable } from "../../entities/Receivable/Receivable"
import { CreditorSettings } from "../../entities/Creditor/Creditor"
import { Settings } from "../settings"


type TNegotiationOptionItemDTO = {
    name: string,
    counter: number,
    extra_domain: {
        type: string,
        data: any
    },
    receivable_uuid: string
}

type TNegotiationOptionDTO = {
    uuid: string,
    obligatory_receivables_uuids: string[],
    negotiation_option_items_uuids: string[],
    creditor_uuid: string, //keyof typeof CreditorSettings,
    payment_methods: string[]
    description: string,
    available_dates?: string[]
}

type TInstallmentDTO = {
    uuid: string,
    installment_number: number,
    initial_value: number,
    fee: number,
    interest: number,
    fine: number,
    taxes: number,
    current_value: number,
    due_date: string,
    payment?: {
        payment_date: string,
        paid_value: number,
        payment_method: string,
    } | null,
    payment_option_data?: any,
    status: string
}

type TReceivableDTO = {
    original_receivable_value: number,
    product: {
        name: string,
        description: string
    },
    downpayment: {
        value: number
    }
    fee: number,
    interest: number,
    fine: number,
    taxes: number,
    current_value: number,
    status: string,
    installments: TInstallmentDTO[],
}

export type TNegotiationOptionResponse = { 
    payload: {
        negotiation_options: TNegotiationOptionDTO[],
        aggregates: {
            options_items: {
                [negotiation_option_item_uuid: string]: TNegotiationOptionItemDTO
            },
            receivables: {
                [receivable_uuid: string]: TReceivableDTO
            }
        }
    },
    errors: [] | null
}
const negotiationOptionConverter = (dto: TNegotiationOptionResponse): [NegotiationOption[] , Receivable[]] => {
    const optionsData = dto.payload.aggregates.options_items

    const receivableDTOs = dto.payload.aggregates.receivables
    const receivables: Receivable[] = []
    const receivableKeys = Object.keys(receivableDTOs)

    const buildReceivable = (receivableDTO: TReceivableDTO, uuid: string): Receivable => {
        return {
            uuid: uuid,
            // @ts-ignore
            currentValue: receivableDTO.current_value,
            // @ts-ignore
            downpayment: receivableDTO.downpayment,
            // @ts-ignore
            fee: receivableDTO.fee,
            // @ts-ignore
            fine: receivableDTO.fine,
            // @ts-ignore
            product: receivableDTO.product,
            // @ts-ignore
            installments: receivableDTO.installments.map((dto) => (
                {
                    type: 'Installment',
                    uuid: dto.uuid,
                    installmentNumber: dto.installment_number.toString(),
                    dueDate: dto.due_date,
                    fee: dto.fee,
                    fine: dto.fine,
                    interest: dto.interest,
                    taxes: dto.taxes,
                    initialValue: dto.initial_value,
                    currentValue: dto.current_value,
                    status: dto.status,
                    originalReceivableValue: dto.initial_value,
                    payment: {
                        paidValue: dto.payment?.paid_value,
                        paymentDate: dto.payment?.payment_date,
                        paymentInfo: {},
                        paymentMethod: dto.payment?.payment_method
                    } as Payment
                }
            )) as Installment[]
        } as Receivable
    }

    for(const key in receivableKeys) {
        const uuid = receivableKeys[key]
        const receivable = receivableDTOs[uuid as keyof typeof receivableDTOs]
        if(receivable) {
            receivables.push(
                buildReceivable(receivable, uuid)
            )
        }
    }

    const negotiationOptions = dto.payload.negotiation_options.map(option => {
        const optionItems: NegotiationOptionItem[] = []
        if(optionsData && option.negotiation_option_items_uuids) {
            for(const key in option.negotiation_option_items_uuids) {
                const uuid = option.negotiation_option_items_uuids[key]
                optionItems.push(
                    {
                        uuid: uuid,
                        receivableUUID: optionsData[uuid].receivable_uuid,
                        groupname: optionsData[uuid].name,
                        groupCounter: optionsData[uuid].counter,
                        extraDomain: optionsData[uuid].extra_domain
                    }
                )
            
                const selectedReceivable = receivables.filter((receivable)=>receivable.uuid == optionsData[uuid].receivable_uuid)
                if(selectedReceivable.length > 0){
                    receivables.push(
                        selectedReceivable[0]
                    )
                }
                
            }
        }

        return {
            type: 'NegotiationOption',
            uuid: option.uuid,
            description: option.description,
            obligatoryReceivablesUUID: option.obligatory_receivables_uuids ? option.obligatory_receivables_uuids : [],
            negotiationOptionItems: optionItems,
            creditor: CreditorSettings[option.creditor_uuid as keyof typeof CreditorSettings],
            availablePaymentDates: option.available_dates,
            paymentMethods: option.payment_methods
        } as NegotiationOption
    })

    return [negotiationOptions, receivables]
}

export default negotiationOptionConverter;