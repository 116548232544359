import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState, AppThunk } from '../../../NegotiationPortal/store';
import { accessLinkValidationGateway, checkUserGateway, signInGateway, signUpGateway } from "../../gateways/authGateway";
import { Client } from "../../entities/Client/Client";
import { GatewayError, TGatewayError } from "../../gateways/errors";
import { AppDispatch } from "../../../NegotiationPortal/store";
import { postLogThunk } from "../../../NegotiationPortal/components/logs/logsThunk";

interface IFetchClientThunk {
    documentNumber: string 
    accessUUID: string 
    dispatch: AppDispatch
}

export const fetchClientThunk = createAsyncThunk(
    'authForm/fetchClient',
    async ({
        documentNumber,
        accessUUID,
        dispatch
    } : IFetchClientThunk
    ) =>  {
        let rawDocumentNumber = documentNumber
            .replaceAll('-', '')
            .replaceAll('.', '')
            .replaceAll('/', '')

        const response: Client | TGatewayError = await checkUserGateway({
            documentNumber: rawDocumentNumber
        }).catch(e => {
            return GatewayError('DEV500')
        })

        dispatch(postLogThunk(
            {
                event: 'FETCHING_CLIENT_GATEWAY',
                documentNumber: documentNumber,
                formData: {documentNumber: rawDocumentNumber},
                details: {response: response},
                accessUUID: accessUUID
            }
        ))
            
        return response
    }
)

export interface ISignIn {
    documentNumber: string,
    password?: string,
    dateOfBirth?: string
    accessUUID: string
    dispatch: AppDispatch
}
export const signInThunk = createAsyncThunk(
    'authForm/signin',
    async (
        {
            documentNumber,
            password,
            dateOfBirth,
            accessUUID,
            dispatch
        }: ISignIn
    ) => {
        let rawDocumentNumber = documentNumber
            .replaceAll('-', '')
            .replaceAll('.', '')
            .replaceAll('/', '')
        
        let response: TGatewayError | Client

        if(password){
            response = await signInGateway(
                {
                    username: rawDocumentNumber,
                    password: password
                }
            )
        }else if(dateOfBirth && dateOfBirth.length > 0){
            response = await signInGateway(
                {
                    username: rawDocumentNumber,
                    dateOfBirth: dateOfBirth
                }
            )
        }else{
            response = await signInGateway(
                {
                    username: rawDocumentNumber
                }
            )
        }

        dispatch(postLogThunk(
            {
                event: 'AUTHENTICATING_CLIENT_GATEWAY',
                documentNumber: documentNumber,
                formData: {
                    documentNumber: rawDocumentNumber,
                    dateOfBirth: dateOfBirth ? dateOfBirth : null,
                    hasPassword: password ? true : false 
                },
                details: {response: response},
                accessUUID: accessUUID
            }
        ))

        return response
    }
)


export const accessLinkValidationThunk = createAsyncThunk(
    'authForm/accessLinkValidation',
    async (
        token: string
    ) => {
        const response: string | TGatewayError = await accessLinkValidationGateway({
            token: token
        }).catch(e => {
            return GatewayError('DEV500')
        })

        return response
})


export interface ISignUp {
    documentNumber: string
    password: string
    name: string
    email: string
}
export const signUpThunk = createAsyncThunk(
    'authForm/signup',
    async (
        {
            documentNumber,
            password,
            name,
            email
        }: ISignUp
    ) => {
        let rawDocumentNumber = documentNumber
            .replaceAll('-', '')
            .replaceAll('.', '')
            .replaceAll('/', '')

        const response: Client | TGatewayError = await signUpGateway({
            documentNumber: rawDocumentNumber,
            password: password,
            email: email,
            name: name
        })

        return response
    }
)

const initialState = {
    uuid: "",
    rawDocumentNumber: "",
    documentNumber: "",
    email: "",
    name: "",
    loadingUser: false,
    openModal: false,
    errorMessage: "",
    formType: "",
    showError: false,
    userAuthenticated: false,
    accessToken: '',
    accessLinkError: false
}

const authFormSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetAuthForm: (state) => initialState,
        setDocumentNumber: (state: typeof initialState, action: PayloadAction<string>) => {
             state.rawDocumentNumber = action.payload
        },
        setEmail: (state: typeof initialState, action: PayloadAction<string>) => {
            state.email = action.payload;
        },
        setName: (state: typeof initialState, action: PayloadAction<string>) => {
            state.name = action.payload;
        },
        load: (state: typeof initialState) => {
            state.loadingUser = true
        },
        finishLoad: (state: typeof initialState) => {
            state.loadingUser = false
        },
        openModal: (state: typeof initialState) => {
            state.loadingUser = false
            state.openModal = true
        },
        closeModal: (state: typeof initialState) => {
            state.openModal = false
        },
        showError: (state: typeof initialState) => {
            state.showError = true
        },
        hideError: (state: typeof initialState) => {
            state.errorMessage = ""
            state.showError = false
        },
        setError: (state: typeof initialState, action: PayloadAction<string>) => {
            state.errorMessage = action.payload
        },
        showSignInForm: (state: typeof initialState) => {
            state.formType = 'signin'
            state.openModal = true
        },
        showSignUpForm: (state: typeof initialState) => {
            state.formType = 'signup'
            state.openModal = true
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchClientThunk.pending, (state: typeof initialState) => {
                state.loadingUser = true
            })
            .addCase(
                fetchClientThunk.fulfilled, (state: typeof initialState, action: PayloadAction<Client | TGatewayError>) => {
                    state.loadingUser = false
                    switch(action.payload.type) {
                            case "Client": {
                                state.formType = "signin"
                                state.documentNumber = action.payload.documentNumber
                                state.rawDocumentNumber = action.payload.documentNumber
                                if(action.payload.emails) {
                                    state.email = action.payload.emails[0]
                                }
                                state.name = action.payload.name
                                state.loadingUser = false
                                state.openModal = true

                                break;
                            }
                            case "TGatewayError": {
                                if(action.payload.code == "CLI404") {
                                    state.formType = "signup"
                                    state.loadingUser = false
                                    state.openModal = true
                                } else if(action.payload.code == "CLI403") {
                                    state.formType = "signin"
                                    state.loadingUser = false
                                    state.openModal = true
                                    state.errorMessage = 'Data de nascimento inválida'
                                }
                                 else {
                                    state.errorMessage = "Não foi possível continuar a operação. Nossa equipe técnica já foi avisada do problema e em breve normalizaremos o serviço"
                                }
                                break;
                            }
                    }
                    state.openModal = true
            })
            .addCase(fetchClientThunk.rejected, (state: typeof initialState) => {
                state.loadingUser = false
            })
            .addCase(signInThunk.pending, (state: typeof initialState) => {
                state.loadingUser = true
            })
            .addCase(
                signInThunk.fulfilled,
                (
                    state: typeof initialState,
                    action: PayloadAction<Client | TGatewayError>
                ) => {
                    if('documentNumber' in action.payload) {
                        state.userAuthenticated = true
                        state.name = action.payload.name
                        if(action.payload.accessToken) {
                            state.accessToken = action.payload.accessToken
                        }
                    } else if(action.payload.code == "CLI403") {
                        state.formType = "signin"
                        state.loadingUser = false
                        state.openModal = true
                        state.errorMessage = 'Data de nascimento inválida'
                    } else {
                        state.userAuthenticated = false
                        state.errorMessage = 'Cliente não encontrado na nossa base de dados'
                        state.accessLinkError = true
                        state.loadingUser = false
                    }
            })
            .addCase(
                signUpThunk.pending, (state: typeof initialState) => {
                    state.loadingUser = true
                }
            )
            .addCase(
                signUpThunk.fulfilled, (state: typeof initialState, action: PayloadAction<Client | TGatewayError>) => {
                    if(action.payload.type == "Client") {
                        state.loadingUser = false
                        state.userAuthenticated = true
                        if(action.payload.accessToken) {
                            state.accessToken = action.payload.accessToken
                        }
                    } else {
                        state.errorMessage = "Houve um problema no cadastro, por favor, tente novamente!"
                    }
                }
            )
            .addCase(
                accessLinkValidationThunk.pending, (state: typeof initialState) => {
                    state.accessLinkError = false
                }
            )
            .addCase(
                accessLinkValidationThunk.fulfilled, (state: typeof initialState, action: PayloadAction<string | TGatewayError>) => {
                    if(typeof action.payload == 'string'){
                        state.documentNumber = action.payload
                        state.rawDocumentNumber = action.payload
                    }else{
                        state.accessLinkError = true
                    }
                }
            )
            .addCase(
                accessLinkValidationThunk.rejected, (state: typeof initialState) => {
                    state.accessLinkError = true
                }
            )
    }
})


export const selectDocumentNumber = (state: RootState) => state.auth.documentNumber
export const selectRawDocumentNumber = (state: RootState) => state.auth.rawDocumentNumber
export const selectEmail = (state: RootState) => state.auth.email
export const selectName = (state: RootState) => state.auth.name
export const selectLoadingUser = (state: RootState) => state.auth.loadingUser
export const selectOpenModal = (state: RootState) => state.auth.openModal
export const selectErrorMessage = (state: RootState) => state.auth.errorMessage
export const selectFormType = (state: RootState) => state.auth.formType
export const selectShowErrorMessage = (state: RootState) => state.auth.showError
export const selectUserIsAuthenticated = (state: RootState) => state.auth.userAuthenticated
export const selectAccessLinkError = (state:RootState) => state.auth.accessLinkError

export const {resetAuthForm, closeModal, setDocumentNumber, showError, setError, hideError} = authFormSlice.actions;

export default authFormSlice.reducer;
