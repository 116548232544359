import React from "react"
import { CreditorCardType } from "./interfaces";


export const CreditorCard = ({
    creditor,
    clickCallback
}: CreditorCardType) => {
    
    return (
        <a
            onClick={() => {
                clickCallback(creditor)
            }}
        >
            <div className="
                bg-white
                p-5 
                md: m-5
                m-2
                w-64 
                h-44 
                flex 
                justify-center 
                items-center 
                shadow-xl
                hover:shadow-md
                active:shadow-inner
                rounded-lg
            ">
                    <img 
                        src={creditor.logo} 
                        className="h-auto w-full max-h-full object-contain"
                        alt="profile-picture" 
                    />
            </div>
        </a>
    );

}