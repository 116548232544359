import { Creditor } from "../../entities/Creditor/Creditor"
import { Partner } from "../../entities/Partner/Partner"
import { Wallet } from "../../entities/Wallet/Wallet"


type TWalletDTO = {
    uuid: string, 
    partner_uuid: string, 
    creditor_uuid: string, 
    name: string
}

type TPartnerDTO = {
    uuid: string, 
    name: string, 
    logo: string
    document_number: string, 
    email: string
}

type TCreditorDTO = {
    uuid: string, 
    partner_uuid: string, 
    name: string, 
    logo: string
}

type TCreditorPayload = {
    wallets: TWalletDTO[],
    aggregates: {
        partners: {
            [partnerUUID: string]: TPartnerDTO
        },
        creditors: {
            [creditorUUID: string]: TCreditorDTO
        }
    }

}

export type TCreditorResponse = {
    payload: TCreditorPayload,
    errors: string[]
}


const walletConverter = (dto: TCreditorResponse): Wallet[] => {
    return dto.payload.wallets.map(walletDTO => {
        const creditors = dto.payload.aggregates.creditors
        const partners = dto.payload.aggregates.partners

        const creditorDTO = dto.payload.aggregates.creditors[
            walletDTO.creditor_uuid as keyof typeof creditors
        ]
        const partnerDTO = dto.payload.aggregates.partners[
            walletDTO.partner_uuid as keyof typeof partners
        ]

        return {
            type: "Wallet",
            uuid: walletDTO.uuid,
            name: walletDTO.name,
            creditor: {
                type: 'Creditor',
                uuid: creditorDTO.uuid,
                name: creditorDTO.name,
                logo: creditorDTO.logo,
                partner: {
                    type: 'Partner',
                    uuid: partnerDTO.uuid,
                    name: partnerDTO.name,
                    logo: partnerDTO.logo,
                    documentNumber: partnerDTO.document_number,
                    creditors: [],
                    email: partnerDTO.email,
                }
            },
        }
    })
}

export default walletConverter;
