import React from 'react';
import Header from '../../partials/Header';
import { CreditorsListPanel } from '../../partials/CreditorsListPanel';
import { BackgroundPanelPages, PanelPagesContentContainer } from '../style';

interface Props {
    backgroundColor: string
}

const CreditorsPage = (props: Props) => {
    return (
        <>  
            <BackgroundPanelPages>
                <Header 
                    showLogout={true}
                />  
                <PanelPagesContentContainer>
                    <CreditorsListPanel /> 
                </PanelPagesContentContainer>
            </BackgroundPanelPages>
            
        </>
    )

}


export default CreditorsPage;