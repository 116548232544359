import React from "react"


const BackgroundCircles = () => {
    return(
        <>
            <div 
            className="
                bg-[#F29129]
                md:bg-[#cc7a00] 
                rounded-full
                w-[700px]
                h-[700px]
                800:w-[1000px]
                800:h-[1000px]
                bottom-[30%]
                left-[-18%]
                absolute
                z-0
            " />
            <div
            className="
                bg-[#FDA03C]
                800:bg-[#FDA03C] 
                rounded-full
                w-[700px]
                h-[700px]
                800:w-[1000px]
                800:h-[1000px]
                top-[30%]
                right-[-18%]
                absolute
                z-0"
            />
        </>
    )
}

export default BackgroundCircles;


