import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import {combineReducers} from 'redux';
import counterReducer from '../common/features/counter/counterSlice';
import authReducer from '../common/features/AuthForm/authFormSlice'
import receivableReducer from '../NegotiationPortal/components/Receivable/ListPanel/receivableListSlice'
import negotiationReducer from './components/Receivable/Negotiation/negotiationSlice';

const rootReducer = combineReducers({
  counter: counterReducer,
  auth: authReducer,
  receivables: receivableReducer,
  negotiation: negotiationReducer
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export let persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
