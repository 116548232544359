import { BackgroundPanelPages } from "../style"            
import Header from "../../partials/Header"
import Breadcrumbs from "../../partials/Breadcrumbs"
import { NegotiationPageContentContainer } from "../style"
import { SummaryContainer } from "../../partials/Negotiation/SummaryContainer"
import { ContactOptionsSection } from "../../../common/features/section/NegotiationSection/ContactOptionsSection"
import { emailValidator } from "../../../common/utils/validator/emailValidator"
import { phoneValidator } from "../../../common/utils/validator/phoneValidator"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { setIsPhoneValid, setIsEmailValid, selectPhone, selectEmail, selectPreAgreement, selectClientGeoLocation, selectIsLoading } from "../../components/Receivable/Negotiation/negotiationSlice"
import { selectDocumentNumber } from "../../../common/features/AuthForm/authFormSlice"
import { CreateAgreementThunk } from "../../components/Receivable/Negotiation/negotiationSliceThunk"
import { ChangeEvent, useEffect } from "react"

import { setEmail, setPhone, selectIsEmailValid, selectIsPhoneValid } from "../../components/Receivable/Negotiation/negotiationSlice"
import { removePhoneMask, phoneMask } from "../../../common/utils/mask/phoneMask"
import { PreAgreement } from "../../../common/entities/PreAgreement/PreAgreement"
import { useCookies } from "react-cookie"
import { postLogThunk } from "../../components/logs/logsThunk"

const SummaryPage = () => {
        const dispatch = useAppDispatch()
        const isEmailValid = useAppSelector(selectIsEmailValid)
        const isPhoneValid = useAppSelector(selectIsPhoneValid)

        const links = [
        {
            display: 'Home',
            url: '/'
        },
        { 
            display: 'Contratos',
            url: '/contratos'
        },
        {
            display: 'Negociação',
            url: '/negociacao'
        },
        {
            display: 'Resumo do Acordo',
            url: '/resumo'
        }
    ]

    const handleEmailChangeFunction = (event: ChangeEvent<HTMLSelectElement>) => {
        dispatch(setEmail(event.target.value))
    }

    const handlePhoneChangeFunction = (event: ChangeEvent<HTMLSelectElement>) => {
        dispatch(setPhone(removePhoneMask(event.target.value)))
        event.target.value = phoneMask(event.target.value)
    }
    const email = useAppSelector(selectEmail)
    const phone = useAppSelector(selectPhone)
    const documentNumber = useAppSelector(selectDocumentNumber)
    const preAgreement = useAppSelector(selectPreAgreement)
    const geoLocation= useAppSelector(selectClientGeoLocation)
    const isLoading = useAppSelector(selectIsLoading)
    const [cookies] = useCookies(['accessUUID']);
    let accessUUID = cookies.accessUUID

    useEffect(() => {
        
        dispatch(postLogThunk(
            {
                event: 'ENTERED_SUMMARY_PAGE',
                documentNumber: documentNumber,
                formData: null,
                details: null,
                accessUUID: accessUUID
            }
        ))
    }, [])

    interface IHandleAgreement {
        email: string | null
        phone: string | null
        documentNumber: string
        preAgreement: PreAgreement | null
        geoLocation: {latitude: string, longitude: string} | null
    }
    const _handleAgreement = (
        {
            email, phone, documentNumber, preAgreement, geoLocation
        }: IHandleAgreement) => {
        const isEmailValid =  emailValidator(email)
        let isPhoneValid = phoneValidator(phone)

        dispatch(postLogThunk(
            {
                event: 'PRESSED_NEGOTIATION_BUTTON',
                documentNumber: documentNumber,
                formData: {preAgreement: preAgreement, email: email, phone: phone, documentNumber: documentNumber},
                details: {isEmailValid: isEmailValid, isPhoneValid: isPhoneValid},
                accessUUID: accessUUID
            }
        ))

        if(isPhoneValid && isEmailValid && preAgreement && geoLocation){
            dispatch(CreateAgreementThunk({preAgreement: preAgreement, email: email, phone: phone, documentNumber: documentNumber, dispatch, accessUUID}))
        }else{
            dispatch(setIsPhoneValid(isPhoneValid))
            dispatch(setIsEmailValid(isEmailValid))
        }
    }

    return (
        <>
            <BackgroundPanelPages>
                <Header 
                    showLogout={true}
                />  
                <Breadcrumbs links={links}/>
                <NegotiationPageContentContainer>
                    <div className='w-full md:w-8/12 h-auto flex flex-col justify-between items-start my-5'>
                        <div className='w-full md:w-full'>
                            {!isLoading ? (
                                <ContactOptionsSection 
                                handleSubmit={_handleAgreement}
                                handleEmailChangeFunction={handleEmailChangeFunction}
                                handlePhoneChangeFunction={handlePhoneChangeFunction}
                                isEmailValid={isEmailValid}
                                isPhoneValid={isPhoneValid}
                                isLoading={false}
                                email={email}
                                phone={phone}
                                documentNumber={documentNumber}
                                geoLocation={geoLocation}
                                preAgreement={preAgreement}
                            />
                            ) : null}
                        </div>
                        <div className='w-full md:w-full' style={{position: 'sticky', top: '0'}}>
                            <SummaryContainer
                                handleSubmit={_handleAgreement}
                                email={email}
                                phone={phone}
                                documentNumber={documentNumber}
                                geoLocation={geoLocation}
                                preAgreement={preAgreement}
                            />
                        </div>
                    </div>
                </NegotiationPageContentContainer>
            </BackgroundPanelPages>
        </>
    )
}


export default SummaryPage;
