import React, { useEffect } from "react";
import { CreditorsPanel } from "../../../common/features/panel/CreditorsPanel";
import { Creditor } from "../../../common/entities/Creditor/Creditor";
import { Client } from "../../../common/entities/Client/Client";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectDocumentNumber, selectEmail } from "../../../common/features/AuthForm/authFormSlice";
import { selectName } from "../../../common/features/AuthForm/authFormSlice";
import { getCreditorsThunk, selectCreditors, selectLoadingCreditors, setCreditor } from "../../components/Receivable/ListPanel/receivableListSlice";
import { useNavigate } from "react-router-dom";


export const CreditorsListPanel = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const documentNumber = useAppSelector(selectDocumentNumber);
    const name = useAppSelector(selectName);
    const email = useAppSelector(selectEmail);
    const creditors = useAppSelector(selectCreditors);
    const isCreditorsLoading = useAppSelector(selectLoadingCreditors);

    const client = {
        documentNumber: documentNumber,
        name: name,
        emails: [email]
    } as Client

    useEffect(() => {
        dispatch(getCreditorsThunk(documentNumber));
    }, [])

    const clickCreditorCard = (creditor: Creditor) => {
        dispatch(setCreditor(creditor))
        navigate('/contratos')
    }

    return(
        <>
            <CreditorsPanel 
                isLoading={isCreditorsLoading}
                creditors={creditors}
                clickCallback={clickCreditorCard}
                client={client}
            />
        </>
    )
}