import React from 'react';
import getKey from '../../../common/utils/key/getKey';

type Link = {
    url: string,
    display: string
}

type BreadcrumbProps = {
    links: Link[]
}
const Breadcrumbs = ({links}: BreadcrumbProps) => {
    return(
        <nav
            className="relative flex w-full flex-wrap items-center justify-between bg-neutral-100 py-3 text-neutral-500 shadow-lg hover:text-neutral-700 focus:text-neutral-700 dark:bg-neutral-600 lg:flex-wrap lg:justify-start"
        data-te-navbar-ref>
            <div className="flex w-full flex-wrap items-center justify-between px-6">
                <nav className="bg-grey-light w-full rounded-md" aria-label="breadcrumb">
                    <ol className="list-reset flex">
                        {links.map(link => (
                            <>
                                <li key={getKey()}>
                                    <a
                                        href={link.url}
                                        className="text-neutral-500 hover:text-neutral-600 dark:text-neutral-200"
                                    >
                                        {link.display}
                                    </a>
                                </li>
                                <li>
                                    <span className="mx-2 text-neutral-500 dark:text-neutral-200"
                                >/</span
                                >
                                </li>
                            </>
                        ))}
                    </ol>
                </nav>
            </div>
        </nav>
    )

}

export default Breadcrumbs;