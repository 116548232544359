import React from 'react'
import { 
    CardBody, 
    CardContainer, 
    CardHeader, 
    CardTopLine,
    CardContent 
} from './styled';
import { CardType } from './interfaces';


export const Card = ({
    hasHeader,
    headerText,
    topLine,
    direction,
    children,
    topLineColor,
    backgroundColor,
    className
} : CardType) => {
    //direction = Can be column or row
    return(
        <CardContainer style={{boxShadow:'3px 3px 10px #b9b9b9'}}>
            <CardTopLine topline={topLine} backgroundColor={topLineColor} style={{backgroundColor:topLineColor}}/>
            <CardContent direction={direction} header={hasHeader} >
                <CardHeader 
                    header={hasHeader} 
                    direction={direction}
                >
                    {headerText}
                </CardHeader>
                <CardBody style={{backgroundColor:backgroundColor}}>
                    {children}
                </CardBody>
            </CardContent>
        </CardContainer>
    )
}

