import tw from "tailwind-styled-components";
//cheat sheet https://tailwindcomponents.com/cheatsheet/ 

export const Container = tw.div`
    bg-transparent
    h-auto
    w-full
    overflow-hidden
    flex
    flex-wrap
`

export const Blank = tw.div`
    h-[8%]
    w-full
`

export const StepsContainer = tw.div`
    font-roboto
    w-full
    h-[97%]
    flex
    flex-wrap
    justify-center
    content-around
    overflow-hidden
    py-10
    md:py-14
    px-[5%]
    md:px-[20%]
`

export const Bar = tw.div`
    w-full
    h-[3%]
    min-h-[10px]
`

export const TextContainer = tw.div`
    w-full
    flex
    flex-wrap
    content-center
    justify-around
`

export const Header = tw.div`
    w-full
    mx-4
    mb-2
    flex
    justify-center
    font-semibold
    text-3xl
    text-center
    md:text-4xl
    drop-shadow-md
`

export const Description = tw.div`
    w-full
    mx-4
    flex
    flex wrap
    justify-center
    font-medium
    text-md
    text-center
    md:text-lg
    mb-8
`

export const Step = tw.div`
    rounded-lg
    w-full
    h-1/5
    p-4
    my-3
    flex
    flex-wrap
    place-content-center
    place-items-center
`

export const StepHeader = tw.div`
    w-full
    flex
    content-center
    place-items-center
    md:mb-2
`

export const Ball = tw.div`
    rounded-full
    text-1xl
    md:text-xl
    font-semibold
    flex
    place-content-center
    place-items-center
    ml-4
    mr-2
    w-8
    h-8
    md:w-12
    md:h-12
    md:mr-4
`

export const TitleContainer = tw.div`
    h-full
    w-9/12
    md:w-11/12
    text-sm
    md:text-2xl
    font-semibold
`

export const Content = tw.div`
    my-1
    mx-4
    w-full
    text-xs
    md:text-base
    font-extralight
`