import React from "react";
import { Card } from "../Card";
import HistoryCardFooter from "./HistoryCardFooter";
import HistoryCardHeader from "./HistoryCardHeader";
import { AgreementCardType } from "./interfaces";


const HistoryCard = ({
    agreement,
    creditor,
    receivables,
    currentReceivable,
    originalReceivables,
    clickCallback,
    paymentsLoading
} : AgreementCardType) => {
    return (
        <>
            <Card
                hasHeader={false}
                topLine={true}
                topLineColor={'#868686'}
                className={'bg-white'}
                backgroundColor={'#FFFFFF'}
            >

                <HistoryCardHeader 
                    agreement={agreement}
                    creditor={creditor}
                    receivables={receivables}
                    originalReceivables={originalReceivables}
                    currentReceivable={currentReceivable}
                /> 

                <HistoryCardFooter 
                    agreement={agreement}
                    creditor={creditor}
                    receivables={receivables}
                    clickCallback={clickCallback}
                    originalReceivables={originalReceivables}
                    currentReceivable={currentReceivable}
                    paymentsLoading = {paymentsLoading}
                />

            </Card>
        </>
    )
}

export default HistoryCard