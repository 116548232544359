import tw from "tailwind-styled-components"

export const CreditorContainer = tw.div`
    font-roboto
    w-full
    md:w-8/12
    h-auto
    px-8
    py-10
    800:py-0
    800:pt-10
    bg-white
    flex
    flex-col
    justify-center
    items-center
    800:px-0
`

export const CreditorSectionTitle = tw.div`
    text-center
    justify-center
    text-4xl
    font-semibold
    m-1
    text-gray-800
`

export const CreditorSectionSubtitle = tw.div`
    text-center
    justify-center
    text-lg
    m-1
    text-gray-500
    w-full
    md:w-9/12
`

export const CreditorSectionLogos = tw.div`
    flex
    flex-wrap
    justify-center
    w-full
    h-auto
    my-2
    800:my-4
    px-[5%]
    800:px-[20%]
`

export const CreditorLogoDiv = tw.div`
    m-4
    h-[100px]
    w-[100px]
    md:h-[120px]
    md:w-[120px]
    flex
    justify-center
    items-center
`

export const CreditorLogoImg = tw.img`
    w-full
    h-auto
`

