

export interface modalType{
    modalButtonClassname?: string,
    modalClassname?: string,
    children?: any,
    modalButtonText?: string
    modalOpeningLogFunction?: () => void
}

export const modalTypeDefault = {
    modalButtonClassname: `
    border
    border-gray-400
    border-2
    text-gray-400
    fw-bold
    fs-lg
    hover:bg-gray-100
    md:w-5/12
    w-9/12
    flex
    justify-center
    items-center
    py-1
    rounded-md
    mb-3
    md:mb-0
    `,
    modalClassname: '',
    children: '',
    modalButtonText: 'Pesquisar'
}