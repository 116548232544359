const AUTH_BASE_URL = 'https://auth.adimplere.com.br/api/v1/'

export const Settings = {
    auth: {
        getUser: `${AUTH_BASE_URL}/client`,
        postSignIn: `${AUTH_BASE_URL}/authentication/user`,
        postSignUp: `${AUTH_BASE_URL}/user`,
        magicLink: `${AUTH_BASE_URL}/magiclink`
    },
    SERVER_HOST: 'https://kroton-api.adimplere.com.br',
    LOG_HOST: 'https://kroton-events.adimplere.com.br',
    //SERVER_HOST: 'http://localhost:8009',
    env: 'production', //'production',
    walletUUID: '9f88f22a-6a22-4015-9bb2-45e8927f244f',
    paymentMethods: {
        pix_vista: 'PIX a Vista',
        pix_vista_rematricula: 'PIX a Vista Rematricula',
        boleto_vista: 'Boleto a Vista',
        boleto_vista_rematricula: 'Boleto a Vista Rematricula',
        boleto: 'Boleto',
        boleto_rematricula: 'Boleto Rematricula',
        cartao: 'Cartao',
        cartao_rematricula: 'Cartao Rematricula'
    }
}

export type TErrorCode = {
    message: string
}

export const ErrorCodes = {
    error_api: {
        message: 'Um erro ocorreu nos nossos servidores e já fomos avisados do problema!'
    }
}
