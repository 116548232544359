import React from "react";
import tw from 'tailwind-styled-components';

const FooterDiv = tw.div`
    w-full
    h-auto
    p-2
    pt-0
`

const CardFooter = ({children}) => {
    return (
        <>
            <FooterDiv>
                {children}
            </FooterDiv>
        </>
    )
}

export default CardFooter