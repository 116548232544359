import React, {useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import NegotiationOptionList from './cards/NegotiationOptionList';
import AgreementList from './cards/AgreementList';
import {
    selectLoadingNegotiationOptions,
    selectAgreements,
    selectReceivables,
    selectNegotiationOptions,
    selectCreditors,
    selectWallets,
    getNegotiationOptionsThunk,
    negotiate,
    getCreditorsThunk,
    selectLoadingAgreements,
    getAgreementsThunk,
    resetReceivablesList,
    selectReceivableError,
    selectPaymentsLoading
} from './receivableListSlice';
import { resetAuthForm, selectEmail, selectName, selectRawDocumentNumber, selectUserIsAuthenticated } from '../../../../common/features/AuthForm/authFormSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { NegotiationOption } from '../../../../common/entities/NegotiationOption/NegotiationOption';
import { Loadings } from '../../../../common/features/loadings/Loadings';
import { resetNegotiation, selectSelectedNegotiationOption, setNegotiationOption } from '../Negotiation/negotiationSlice';
import { Client } from '../../../../common/entities/Client/Client';
import { Creditor } from '../../../../common/entities/Creditor/Creditor';
import { verifyIfDictHasTrueValue } from '../../../../common/utils/dictVerifier/verifyIfDictHasTrueValue';
import { maskDocumentNumber } from '../../../../common/utils/formatters/DocumentNumber';


const ReceivableListPanel = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const loadingNegotiationOptions = useAppSelector(selectLoadingNegotiationOptions)
    const loadingAgreements = useAppSelector(selectLoadingAgreements)
    const agreements = useAppSelector(selectAgreements)
    const receivables = useAppSelector(selectReceivables)
    const negotiationOptions = useAppSelector(selectNegotiationOptions)
    const creditors = useAppSelector(selectCreditors)
    const documentNumber = useAppSelector(selectRawDocumentNumber)
    const wallets = useAppSelector(selectWallets)
    const clientName = useAppSelector(selectName)
    const clientEmail = useAppSelector(selectEmail)
    const userIsAuthenticated = useAppSelector(selectUserIsAuthenticated)
    const receivableError = useAppSelector(selectReceivableError)
    const selectedNegotiationOption = useAppSelector(selectSelectedNegotiationOption)
    const paymentsLoading = useAppSelector(selectPaymentsLoading)

    const client = {
        documentNumber: documentNumber,
        name: clientName,
        emails: [clientEmail]
    } as Client

    useEffect(() => {
        dispatch(resetReceivablesList())
        dispatch(getCreditorsThunk(documentNumber))
        dispatch(getNegotiationOptionsThunk(documentNumber))
        dispatch(getAgreementsThunk(documentNumber))
        dispatch(resetNegotiation())
    }, [])

    useEffect(()=> {
        if(!userIsAuthenticated){
            dispatch(resetNegotiation())
            dispatch(resetAuthForm())
            dispatch(resetReceivablesList())
            navigate('/')
        }
    }, [userIsAuthenticated, receivableError])

    const startNegotiation = (negotiationOption: NegotiationOption) => {
        dispatch(setNegotiationOption({negotiationOption, receivables}))
        navigate('/negociacao')
    }

    const renderNegotiationOptions = () => {
        if(loadingNegotiationOptions) {
            return (
                <div className='w-full flex items-center justify-center'>
                    <Loadings variant='circle' colorHex='#999'/>
                </div>
            )
        }
        
        const _creditor: Creditor = {
            type: 'Creditor',
            uuid: '',
            name: 'Kroton',
            logo: '',
            wallets: []
        }
        return (
            <>
                {
                    <NegotiationOptionList 
                        creditor={_creditor}
                        client={client}
                        negotiationOptions={
                            negotiationOptions
                        }
                        receivables={receivables}
                        clickCallback={
                            (negotiationOption: NegotiationOption) => startNegotiation(negotiationOption)
                        }
                    />
                }
            </>
        )
    }

    const renderAgreementList = () => {
        if(loadingAgreements) {
            return (
                <div className='w-full flex items-center justify-center'>
                    <Loadings variant='circle' colorHex='#999'/>
                </div>
            )
        }
        if(agreements.length > 0) {
            return (
                <>
                    <AgreementList
                        agreements={agreements}
                        clickCallback={() => {}}
                        creditor={creditors[0]}
                        receivables={receivables}
                        paymentsLoading={paymentsLoading}
                    />
                </>
            )
        } else {
            return <>
                <div
                className="mb-4 rounded-lg bg-success-100 px-6 py-5 text-base text-success-700" role="alert"
                >
                    Não encontramos acordos ativos no portal.
                </div>
            </>
        }
    }


    return (
        <>
            <div className="w-full flex flex-col py-5">
                <div className="w-full flex flex-col items-center justify-center mt-8 mb-14">
                    <p className="font-bold text-center text-2xl text-gray-700">{client.name}</p>
                    <p className="font-bold text-center text-xl text-gray-600">{`CPF: ${maskDocumentNumber(client.documentNumber).masked}`}</p>
                </div>
                <div className="pb-5">
                    <p className="font-semibold text-2xl text-gray-700 ">
                        Contratos em aberto
                    </p>
                    <div className="border-b h-1 border-gray-300 ">

                    </div>
                </div>
                {renderNegotiationOptions()}
                </div>
                <div className="w-full flex flex-col py-5">
                    <div className="pb-5">
                        <p className="font-semibold text-2xl text-gray-700 ">
                            Acordos em andamento ou finalizados
                        </p>
                        <div className="border-b h-1 border-gray-300 ">
                        </div>
                    </div>
                    {renderAgreementList()}
                </div>
            </>
    )
}

export default ReceivableListPanel;