import React, {ReactElement} from 'react';
import ReEnrolmentSection from '../section/NegotiationSection/ReEnrolmentSection';
import { NegotiationOption, TExtraDomain } from '../../entities/NegotiationOption/NegotiationOption';
import { PreAgreement } from '../../entities/PreAgreement/PreAgreement';
import { Receivable } from '../../entities/Receivable/Receivable';
import { useAppSelector } from '../../../NegotiationPortal/hooks';
import { selectNegotiationError, selectRematError } from '../../../NegotiationPortal/components/Receivable/Negotiation/negotiationSlice';
import { DangerAlert } from '../../utils/alerts';


// const ExtraDomainContext = (extraDomain: TExtraDomain): (negotiationExtras: INegotiationExtras) => ReactElement<any, any> => {
//     switch(extraDomain.type) {
//         case 'raid': {
//             return (negotiationExtras: INegotiationExtras): ReactElement<any, any> => {
//                 if(extraDomain.data.remat) {
//                     const items = negotiationExtras.negotiationOption?.negotiationOptionItems.filter(
//                         item => item.extraDomain?.data.remat
//                     )
//                     if(items && items.length > 0) {
//                         const rematReceivable = negotiationExtras.receivables.filter(receivable => receivable.uuid == items[0].receivableUUID)
//                         return (
//                             <>
//                                 <ReEnrolmentSection 
//                                     receivables={rematReceivable}
//                                     negotiationOption={negotiationExtras.negotiationOption}
//                                     isLoading={negotiationExtras.isLoading}
//                                 />
//                             </>
//                         )
//                     }
//                 }

//                 return (<>...</>)
//             }
//         }
//     }

//     return ((negotiationExtras: INegotiationExtras) => <></>)
// }


interface INegotiationExtras {
    negotiationOption?: NegotiationOption | null,
    isLoading: boolean,
    receivables: Receivable[]
    extraReceivablesUUID: string[] | null
}
const NegotiationExtras = (props: INegotiationExtras) => {
    const error = useAppSelector(selectRematError);
    if (props.negotiationOption && !error) {
        if(props.negotiationOption.negotiationOptionItems.length > 0) {
            const _itemsRemat = props.negotiationOption.negotiationOptionItems.filter(item => item.extraDomain?.data.remat)
            if(_itemsRemat.length > 0) {
                // const strategy = ExtraDomainContext(extraDomain)
                // return strategy(props)

                const rematReceivables = props.receivables.filter(
                    (receivable) => props.extraReceivablesUUID?.includes(receivable.uuid)
                )
                const negotiationOptionItems = props.negotiationOption.negotiationOptionItems.filter(
                    (item) => props.extraReceivablesUUID?.includes(item.receivableUUID) 
                )
                
                if(props.extraReceivablesUUID){
                    return (
                        <>
                            <ReEnrolmentSection 
                                rematReceivables={rematReceivables}
                                negotiationOption={props.negotiationOption}
                                negotiationOptionItems = {negotiationOptionItems}
                                isLoading={props.isLoading}
                                extraReceivablesUUID={props.extraReceivablesUUID}
                            />
                        </>
                    )
                }
                
            }
        }
    }else if(error){
        return(<DangerAlert 
            message='Não foi possível adicionar a opção de negociar rematrícula.'
        />)
    }

    return <></>
}

export default NegotiationExtras;
