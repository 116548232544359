import React from "react"
import Modal from "../../modal"
import { NegotiationOptionCardFooterModalType } from "./interfaces"
import { NegotiationOptionCardDetailTable } from "./table/NegotiationOptionCardDetailTable"
import { NegotiationOptionCardTable, NegotiationOptionCardTableBody, NegotiationOptionCardTableCell, NegotiationOptionCardTableHead, NegotiationOptionCardTableHeader, NegotiationOptionCardTableRow } from "./table/NegotiationOptionCardTableStyle"
import { postLogThunk } from "../../../../NegotiationPortal/components/logs/logsThunk"
import { useAppDispatch } from "../../../../NegotiationPortal/hooks"
import { logsGateway } from "../../../gateways/logsGateway"

export const NegotiationOptionCardFooterModal = ({
    receivables,
    negotiationOption,
    creditor,
    client,
    accessUUID,
}: NegotiationOptionCardFooterModalType) => {
    const dispatch = useAppDispatch()

    return(
        <Modal 
            modalButtonClassname="border
            border-gray-400
            border-2    
            text-gray-400
            fw-bold
            fs-lg
            hover:bg-gray-100
            md:w-5/12
            w-9/12
            flex
            justify-center
            items-center
            py-1
            rounded-md
            mb-3
            md:mb-0"
            modalButtonText="Detalhes"
            modalClassname="w-full md:w-1/2"  
            modalOpeningLogFunction={() => {
                //Tive que chamar dessa forma ao invés do Thunk por conta de conflito 
                // com a renderização usando UseState do Modal.
                const domain = window.location.hostname
                const path = window.location.pathname

                logsGateway(
                    {
                        domain: domain,
                        path: path,
                        event: 'PRESSED_NEGOTIATION_OPTION_DETAIL_BUTTON',
                        documentNumber: client.documentNumber,
                        accessUUID: accessUUID,
                        formData: null,
                        details: {
                            negotiationOptionUUID: negotiationOption.uuid
                        }
                    }
                )
            }}  
        >
            <div className="w-full flex flex-col ">
                <div className="w-full flex flex-col">
                    <div className="border-b pb-3">
                        <p className="text-lg font-bold text-gray-700 mb-5 mt-3"> 
                            Cliente
                        </p>
                        <div className="flex flex-col md:flex-row mb-3 md:mb-1">
                            <p className="text-lg font-bold text-gray-500 -mb-1 mr-0 md:mb-0 md: mr-3"> 
                                Nome:
                            </p>
                            <p className="text-base md: text-lg font-bold text-gray-500"> 
                                {client.name}
                            </p>
                        </div>
                        <div className="flex flex-col md:flex-row mb-3 md:mb-1">
                            <p className="text-lg font-bold text-gray-500 -mb-1 mr-0 md:mb-0 md: mr-3"> 
                                CPF:
                            </p>
                            <p className="text-base md: text-lg font-bold text-gray-500"> 
                                {client.documentNumber}
                            </p>
                        </div>
                    </div>
                    
                    {/* <div className="border-b pb-3 pt-3">
                        <p className="text-lg font-bold text-gray-700 mb-5"> 
                            Dívida com a empresa 
                        </p>
                        <div className="flex flex-col md:flex-row mb-3 md:mb-1">
                            <p className="text-lg font-bold text-gray-500 -mb-1 mr-0 md:mb-0 md: mr-3"> 
                                Nome:
                            </p>
                            <p className="text-base md: text-lg font-bold text-gray-500"> 
                                {creditor.name}
                            </p>
                        </div>
                        <div className="flex flex-col md:flex-row mb-3 md:mb-1">
                            <p className="text-lg font-bold text-gray-500 -mb-1 mr-0 md:mb-0 md: mr-3"> 
                                CNPJ:
                            </p>
                            <p className="text-base md: text-lg font-bold text-gray-500"> 
                                {creditor.partner?.documentNumber}
                            </p>
                        </div>
                    </div> */}

                    <div className="border-b pb-3 pt-3">
                        <p className="text-lg font-bold text-gray-700 mb-5"> 
                            Contrato
                        </p>
                        <div className="flex flex-col md:flex-row mb-3 md:mb-1">
                            <p className="text-lg font-bold text-gray-500 -mb-1 mr-0 md:mb-0 md: mr-3"> 
                                Número:
                            </p>
                            <p className="text-base md: text-lg font-bold text-gray-500"> 
                                {negotiationOption.description}
                            </p>
                        </div>
                        <div className="flex flex-col md:flex-row mb-3 md:mb-1">
                            <p className="text-lg font-bold text-gray-500 -mb-1 mr-0 md:mb-0 md: mr-3"> 
                                Curso:
                            </p>
                            <p className="text-base md: text-lg font-bold text-gray-500"> 
                                {receivables[0]?.product ? receivables[0].product.name : '-'}
                            </p>
                        </div>
                    </div>

                    <NegotiationOptionCardDetailTable 
                        receivables={receivables}
                    />
                    
                </div>
            </div>
        </Modal>
    )
}