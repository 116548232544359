import React, { useEffect } from 'react';
import Header from '../../partials/Header';
import { BackgroundPanelPages, NegotiationPageContentContainer, PanelPagesContentContainer } from '../style';
import { NegotiationChoicesContainer } from '../../partials/Negotiation/NegotiationChoicesContainer';
import ReceivableListPanel from '../../components/Receivable/ListPanel';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useCookies } from 'react-cookie';
import { postLogThunk } from '../../components/logs/logsThunk';
import { selectDocumentNumber } from '../../../common/features/AuthForm/authFormSlice';


const ReceivablesPanelPage = () => {
    const dispatch = useAppDispatch()
    const documentNumber = useAppSelector(selectDocumentNumber)
    const [cookies] = useCookies(['accessUUID']);

    useEffect(() => {
        let accessUUID = cookies.accessUUID
        
        dispatch(postLogThunk(
            {
                event: 'ENTERED_RECEIVABLES_PANEL_PAGE',
                documentNumber: documentNumber,
                formData: null,
                details: null,
                accessUUID: accessUUID
            }
        ))
    }, [])
    

    return (
        <>  
           <BackgroundPanelPages>
                <Header 
                    showLogout={true}
                />  
                <PanelPagesContentContainer>
                    <ReceivableListPanel />
                </PanelPagesContentContainer>
            </BackgroundPanelPages> 
        </>
    )

}


export default ReceivablesPanelPage;