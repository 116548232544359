import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { cpf } from 'cpf-cnpj-validator'; 
import InputMask from 'react-input-mask';
import { useAppDispatch, useAppSelector } from '../../../NegotiationPortal/hooks';
import { AuthFormContainer } from "./styled"
import SignUpForm from './forms/SignUpForm';
import AuthFormService, {AuthFormServiceInterface} from './services';
import { DangerAlert } from '../../utils/alerts';
import LoadingComponent from '../LoadingComponent';
import {
    // state selectors
    selectDocumentNumber,
    selectRawDocumentNumber,
    selectEmail,
    selectName,
    selectOpenModal,
    selectLoadingUser,
    selectErrorMessage,
    selectShowErrorMessage,
    selectFormType,
    selectUserIsAuthenticated,

    //actions
    closeModal,
    setDocumentNumber,
    setError,
    showError,
    hideError,

    //thunks
    fetchClientThunk,
} from './authFormSlice'
import { TMaskedDocumentNumber } from '../../utils/formatters/DocumentNumber';
import SignInForm from './forms/SignInForm';
import SignInDateOfBirthForm from './forms/SignInDateOfBirthForm';
import ClientNotFoundWarning from './forms/ClientNotFoundWarning';
import { useCookies } from 'react-cookie';
import { postLogThunk } from '../../../NegotiationPortal/components/logs/logsThunk';


interface AuthFormProps {
    service?: AuthFormServiceInterface,
}

const AuthForm = (
    { service = AuthFormService }: AuthFormProps
) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const email = useAppSelector(selectEmail)
    const name = useAppSelector(selectName)
    const isLoading = useAppSelector(selectLoadingUser)
    const errorMessage = useAppSelector(selectErrorMessage)
    const formType = useAppSelector(selectFormType)
    const openModal = useAppSelector(selectOpenModal)
    const rawDocumentNumber = useAppSelector(selectRawDocumentNumber)
    const showErrorMessage = useAppSelector(selectShowErrorMessage)
    const userIsAuthenticated = useAppSelector(selectUserIsAuthenticated)
    const documentNumber = useAppSelector(selectRawDocumentNumber)

    const [cookies] = useCookies(['accessUUID']);
    const accessUUID = cookies.accessUUID


    const validateDocumentNumber = (_documentNumber: string) => {
        if(documentNumber) {
            const dn = _documentNumber
                    .replace('.', '')
                    .replace('-', '')
                    .replace('/', '')

            if(!cpf.isValid(dn)) {
                dispatch(setError(`CPF informado não é válido: ${_documentNumber}`))
                dispatch(showError())
            } else {
                dispatch(hideError())
            }

        }
    }

    useEffect(() => {
        if(userIsAuthenticated) {
            navigate("/contratos")
        }
    }, [userIsAuthenticated])

    useEffect(() => {
        if(documentNumber && (documentNumber.includes('_') || documentNumber.length == 0)) {
            dispatch(hideError())
        } else {
            validateDocumentNumber(documentNumber)
        }
    }, [documentNumber])

    const renderForm = () => {
        if(formType == "signin") {
            //return <SignInForm documentNumber={documentNumber} />
            return <SignInDateOfBirthForm documentNumber={documentNumber} />
        }
        if(formType == "signup") {
            //return <SignUpForm documentNumber={documentNumber} />
            return <ClientNotFoundWarning />
        }
    }

    return (
        <>  
            {isLoading && (
                <div className="loading" style={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: "#3b2f2fd1",
                    position: "absolute",
                    zIndex: 10,
                    top: 0,
                    left: 0,
                    color: 'white',
                    fontSize: 'larger'
                    }}
                >
                    <div className="grid mt-[100px] place-items-center">
                        <LoadingComponent />
                    </div>
                </div>
            )}
            <form
                onSubmit={(e) => {
                    e.preventDefault(); 
                    dispatch(postLogThunk(
                        {
                            event: 'PRESSED_SEARCH_CLIENT_BUTTON',
                            documentNumber: null,
                            formData: {documentNumber: documentNumber},
                            details: null,
                            accessUUID: accessUUID
                        }
                    ))
                    dispatch(fetchClientThunk({documentNumber, dispatch, accessUUID}))
                    
                }} 
                className='flex flex-col md:flex-row w-full items-center justify-center md:items-start md:justify-start'
            >
                <InputMask 
                    mask='999.999.999-99' 
                    value={documentNumber} 
                    onChange={(e) => dispatch(setDocumentNumber(e.currentTarget.value))}
                    className='pl-5 md:mr-2 rounded w-11/12 md:w-1/2 py-4 md:py-3 mb-3 md:mb-0 text-xl'
                    placeholder='Digite o seu CPF'
                />

                <button
                    className="bg-purple-700 hover:bg-purple-500 text-gray-100 
                            font-semibold py-4 md:py-3 px-4 m-0
                            rounded shadow w-11/12 md:w-auto border-none my-auto"
                >
                    Pesquisar
                </button>
            </form>
            {showErrorMessage && (
                <DangerAlert message={errorMessage}/>
            )}

            <div className={openModal ? '' : 'hidden'} >
                <AuthFormContainer tabIndex={-1}>
                    <div className="relative w-full h-full max-w-md md:h-auto m-auto">
                        <div className="relative bg-white rounded-lg shadow">
                            <button
                                type="button"
                                onClick={() => dispatch(closeModal())}
                                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                                data-modal-hide="authentication-modal"
                            >
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="px-6 py-6 lg:px-8">
                                {errorMessage.length > 0 && (
                                    <DangerAlert message={errorMessage}/>
                                )}
                                {renderForm()}
                            </div>
                        </div>
                    </div>
                </AuthFormContainer>
            </div>
        </>
    )
}

export default AuthForm;
