import { createAsyncThunk } from "@reduxjs/toolkit"
import { logsGateway } from "../../../common/gateways/logsGateway";
import { useAppCookies } from "../../hooks";


interface IPostLogThunk {
    event: string,
    documentNumber: string | null,
    formData: { [key: string]: any } | null,
    details: { [key: string]: any } | null,
    accessUUID: string
}

export const postLogThunk = createAsyncThunk(
    'logs/postLogThunk',
    async ({
        event, documentNumber, formData, details, accessUUID
    }: IPostLogThunk) => {
        const domain = window.location.hostname
        const path = window.location.pathname
        
        logsGateway(
            {
                domain: domain,
                path: path,
                event: event,
                documentNumber: documentNumber,
                accessUUID: accessUUID,
                formData: formData,
                details: details
            }
        )
    }
)