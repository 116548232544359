import React from "react";
import CardHeader from "../base/CardHeader";
import AgreementCardHeaderSection from "./HistoryCardHeaderSection";
import { AgreementCardHeaderType } from "./interfaces";
import { current } from "@reduxjs/toolkit";
import { convertDateToBRFormat } from "../../../utils/date/convert";


const HistoryCardHeader = ({
    agreement,
    creditor,
    receivables,
    currentReceivable,
    originalReceivables
} : AgreementCardHeaderType) => {

    const emissionDate = agreement.emissionDate ? convertDateToBRFormat(agreement.emissionDate) : ''
    if(currentReceivable) {
        return (
            <>
                <CardHeader>
                    <div className="w-full h-full flex md:flex-row flex-col">
                        {/* <div className="flex flex-col items-center justify-between w-full md:w-1/2 h-auto">
                            <AgreementCardHeaderSection 
                                title="Status"
                                description={currentReceivable.status}
                            />
                            <AgreementCardHeaderSection 
                                title="Data de Emissão"
                                description={emissionDate}
                            />
                        </div> */}
                        {/* <div className="flex flex-col items-center px-0 md:px-8 justify-between w-full md:w-1/2 h-auto md:h-full"> */}
                        <div className="flex flex-row items-center px-0 md:px-8 justify-around w-full md:w-full h-auto md:h-full">
                            <AgreementCardHeaderSection 
                                title="Status"
                                description= {currentReceivable.status == 'SETTLED' ? 'Acordo Pago' : 'Acordo quebrado'}
                            />
                        </div>
                    </div>
                    
                </CardHeader>
            </>
        )
    } else {
        return <></>
    }
}

export default HistoryCardHeader