import React from "react"
import { formatBRValue } from "../../../../utils/valueFormatters/formatBRValue"
import { convertDateToBRFormat } from "../../../../utils/date/convert"
import Modal from "../../../modal"
import { NegotiationOptionCardDetailTableType } from "../interfaces"
import { NegotiationOptionCardTable, NegotiationOptionCardTableBody, NegotiationOptionCardTableCell, NegotiationOptionCardTableHead, NegotiationOptionCardTableHeader, NegotiationOptionCardTableRow } from "./NegotiationOptionCardTableStyle"
import getKey from "../../../../utils/key/getKey"

export const NegotiationOptionCardDetailTable = ({
    receivables
}: NegotiationOptionCardDetailTableType) => {
    return(
        <NegotiationOptionCardTable>
            <NegotiationOptionCardTableHeader>
                <NegotiationOptionCardTableRow>
                    <NegotiationOptionCardTableHead>
                        Parcela
                    </NegotiationOptionCardTableHead>
                    <NegotiationOptionCardTableHead>
                        Vencimento
                    </NegotiationOptionCardTableHead>
                    <NegotiationOptionCardTableHead>
                        Valor
                    </NegotiationOptionCardTableHead>
                </NegotiationOptionCardTableRow>
            </NegotiationOptionCardTableHeader>
            <NegotiationOptionCardTableBody>
                {/* TODO - Aqui pegar cada recebível obrigatório por linha. */}
                {typeof receivables != 'undefined' ? receivables.map((receivable) => {
                    if(typeof receivable != 'undefined'){ 
                        return (
                            <NegotiationOptionCardTableRow key={getKey()}>
                                <NegotiationOptionCardTableCell>
                                    {
                                        typeof receivable.product != 'undefined' ? 
                                        receivable.product?.description : null
                                    }
                                </NegotiationOptionCardTableCell>
                                <NegotiationOptionCardTableCell>
                                    {
                                        // @ts-ignore
                                        convertDateToBRFormat(receivable?.installments[0].dueDate)
                                    }
                                </NegotiationOptionCardTableCell>
                                <NegotiationOptionCardTableCell>
                                    {`R$ ${formatBRValue(receivable.currentValue)}`}
                                </NegotiationOptionCardTableCell>
                            </NegotiationOptionCardTableRow>
                        )
                    }
                }) : null}
            </NegotiationOptionCardTableBody>
        </NegotiationOptionCardTable>
    )
}