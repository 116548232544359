
export const emailValidator = (enteredEmail) => {
    var regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;

    if (regexEmail.test(enteredEmail)) {
        return true
    } else {
        return false

    }
}