import { Partner } from "../Partner/Partner";
import { Wallet } from "../Wallet/Wallet";

export type Creditor = {
    type: "Creditor",
    uuid: string,
    name: string,
    logo: string,
    wallets?: Wallet[]
    partner?: Partner
};

//TODO create an API to centralized this informations
export const CreditorSettings = {
    '626779cb-f743-47dd-994d-0df22970df25': {
        name: 'UNIME',
        logo: '/media/img/creditors_logos/unime.jpg'
    },
    '278b6f5d-74ed-4df2-aa98-9fe99c806879': {
        name: 'AMPLI',
        logo: '/media/img/creditors_logos/ampli.png'
    },
    'ecb0ec66-9fd4-451a-828c-03171489774e': {
        name: 'UNIC',
        logo: '/media/img/creditors_logos/unic.jpg'
    },
    '4cb12c68-e9ff-4120-8e46-f24f006e91dd': {
        name: 'UNIDERP',
        logo: '/media/img/creditors_logos/uniderp.jpg'
    },
    '87edd83a-1f79-4b9c-bf1b-ef9759b26a4c': {
        name: 'FAMA',
        logo: '/media/img/creditors_logos/fama.jpg'
    },
    '4fc21fab-8eda-495b-a825-1535e104e334': {
        name: 'PITAGORAS ICF',
        logo: '/media/img/creditors_logos/pitagoras.jpg'
    },
    '1650003d-ed4f-494f-b821-d4c47efde525': {
        name: 'PITAGORAS',
        logo: '/media/img/creditors_logos/pitagoras.jpg'
    },
    'bc418793-80d9-4ec6-adf7-2522b72856a8': {
        name: 'PitagorasICF',
        logo: '/media/img/creditors_logos/pitagoras.jpg'
    },
    '83d01513-dc87-46f8-b613-cffa6e7e6045': {
        name: 'UNOPAR - C. UN. NITERÓI',
        logo: '/media/img/creditors_logos/unopar.jpg'
    },
    '4a47d804-73ce-4413-8355-145902894f07': {
        name:'UNOPAR',
        logo: '/media/img/creditors_logos/unopar.jpg'
    },
    'fbf890ea-b2cd-4c62-99e1-013593da2f95': {
        name: 'ANHANGUERA',
        logo: '/media/img/creditors_logos/anhanguera.jpg',
    },
    '9302e493-a78e-4b3d-8a7c-5726261358eb': {
        name: 'ANHANGUERA - C. UN. CAMPO GRANDE',
        logo: '/media/img/creditors_logos/anhanguera.jpg'
    }
}
