
export const get_font_color = (color_code) => {
    const  tinycolor = require("tinycolor2");
    const color = tinycolor(color_code);

    const brightness = color.getBrightness()

    if(brightness < 150){
        return '#1F2937'
    }else{
        return '#F9F9F9'
    }
}