import React, { ReactNode } from "react";
import { PreAgreement } from "../../../../entities/PreAgreement/PreAgreement";
import { formatBRValue } from "../../../../utils/valueFormatters/formatBRValue";
import { PaymentMethodOptionsSectionType } from "./interfaces";
import { faMoneyCheckDollar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingComponent from "../../../LoadingComponent";
import { NegotiationOption } from "../../../../entities/NegotiationOption/NegotiationOption";


export const PaymentMethodOptionsSection = ({
    negotiationOption,
    handleChangeFunction,
    isLoading,
    preAgreement,
    selectedReceivables,
    rematReceivablesUUID,
    selectedPaymentMethod,
    selectedInstallmentNumber
}: PaymentMethodOptionsSectionType) => {
    const _returnPaymentMethodsOptions = ({negotiationOption}: {negotiationOption: NegotiationOption}): ReactNode => {
        const translatedMethod: {[key: string]: string} = {
            'BANKSLIP': 'Boleto Bancário',
            'PIX': 'Pix',
            'CREDIT_CARD': 'Cartão de Crédito'
        }
        const listOfPaymentMethods = ['BANKSLIP', 'PIX', 'CREDIT_CARD']
        let listOfPaymentMethodsComponents = listOfPaymentMethods.map((method) => {
            return(
                <option
                    value={
                        method
                    }
                    selected={
                        method == selectedPaymentMethod
                    }
                >{translatedMethod[method]}</option>
            )
        })
        return listOfPaymentMethodsComponents
    }

    const handlePaymentMethod = (paymentMethod: string) => {
        handleChangeFunction(paymentMethod)
    }


    return (
        <>
            <div className='w-full h-auto p-6 bg-white rounded-md my-3' style={{ boxShadow: '3px 3px 10px #b9b9b9' }}>
                <p className='font-bold text-2xl text-gray-700'>
                    <FontAwesomeIcon icon={faMoneyCheckDollar} /> &nbsp;
                    Forma de Pagamento
                </p>
                {isLoading && (<LoadingComponent />)}
                {!isLoading && (
                    <div className="">
                        <select style={{
                            marginTop: "17px",
                            paddingLeft: "15px"
                        }}
                            onChange={(e) => {handlePaymentMethod(e.currentTarget.value)}}
                        >
                            {
                                _returnPaymentMethodsOptions({negotiationOption:negotiationOption})
                            }
                        </select>
                    </div>
                )}
            </div>
        </>
    )
}
