import { Loadings } from "../loadings/Loadings";


const LoadingComponent = () => {
    return (
       <>
            <div className="fa-1x">
                Carregando &nbsp;
                <i className="fas fa-spinner fa-spin"></i>
            </div>
        </>
    )
}

export default LoadingComponent;
