import { Agreement, CreateAgreementResponse } from "../../entities/Agreement/Agreement";
import { getCurrentReceivableFromAgreement } from "../../entities/Agreement/filter";
import { Installment } from "../../entities/Receivable/Installment";
import { Payment } from "../../entities/Receivable/Payment";
import { Receivable } from "../../entities/Receivable/Receivable";


type TAgreementDTO = {
    uuid: string,
    original_receivables_uuids: string[],
    current_receivable_uuid: string,
    emission_date?: string
}

type TReceivableDTO = {
    uuid?: string,
    original_receivable_value: number,
    product: {
        name: string,
        description: string | null
    },
    fee: number,
    interest: number,
    fine: number,
    taxes: number,
    current_value: number,
    status: string,
    installments: TInstallmentDTO[],
}

type TInstallmentDTO = {
    uuid: string,
    installment_number: number,
    initial_value: number,
    fee: number,
    interest: number,
    fine: number,
    taxes: number,
    current_value: number,
    due_date: string,
    payment?: {
        payment_date: string,
        paid_value: number,
        payment_method: string,
    } | null,
    payment_option_data?: any,
    status: string
}

export type TCreateAgreementResponse = { 
    payload: {
        agreement: TAgreementDTO,
        aggregates: {
            receivables: {[key:string]:TReceivableDTO}
        }
    },
    errors: [] | null
}

const CreateAgreementConverter = (
    dto: TCreateAgreementResponse
    ): [
        CreateAgreementResponse, 
        Receivable[]
    ] => {
    const receivableDTOs = dto.payload.aggregates.receivables
    const receivablesUUIDs = Object.keys(receivableDTOs)

    const receivables: Receivable[]= receivablesUUIDs.map((uuid) => {
    return (
        {
            uuid: uuid,
            currentValue: receivableDTOs.uuid?.current_value,
            downpayment: null,
            fee: receivableDTOs[uuid].fee,
            fine: receivableDTOs[uuid].fine,
            installments: receivableDTOs[uuid].installments.map((dto) => (
                {
                    type: 'Installment',
                    uuid: dto.uuid,
                    installmentNumber: dto.installment_number,
                    dueDate: dto.due_date,
                    fee: dto.fee,
                    fine: dto.fine,
                    interest: dto.interest,
                    taxes: dto.taxes,
                    initialValue: dto.initial_value,
                    currentValue: dto.current_value,
                    status: dto.status,
                    originalReceivableValue: dto.initial_value,
                    payment: {
                        paidValue: dto.payment?.paid_value,
                        paymentDate: dto.payment?.payment_date,
                        paymentInfo: {},
                        paymentMethod: dto.payment?.payment_method
                    } as Payment
                }
            )) as Installment[]
        } as Receivable
    )}) 

    const agreement = {
            type: 'CreateAgreementResponse',
            uuid: dto.payload.agreement.uuid,
            originalReceivablesUUID: dto.payload.agreement.original_receivables_uuids,
            currentReceivable: getCurrentReceivableFromAgreement({
                agreement: {
                    type: 'Agreement',
                    uuid: dto.payload.agreement.uuid,
                    originalReceivablesUUID: dto.payload.agreement.original_receivables_uuids,
                    currentReceivableUUID: dto.payload.agreement.current_receivable_uuid
                } as Agreement, receivables: receivables
            }),
            emissionDate: dto.payload.agreement.emission_date
        } as CreateAgreementResponse

    return [agreement, receivables]
}


export default CreateAgreementConverter;