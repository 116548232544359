import tw from "tailwind-styled-components"


export const HeaderBar = tw.div`
    w-full
    py-0
    h-auto
    flex
    justify-center
    drop-shadow-md
    max-h-[100px]
    bg-[#000]
    800:max-h-[120px]
    800:justify-start
    800:pl-[20%]
`

export const HeaderBarContent = tw.div`
    w-full
    md:w-1/2
    py-0
    h-full
    flex
    justify-center
    relative
    items-center
`

export const LogoHeader = tw.img`
    h-[41px]
    m-[12px]
`