import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faPlusCircle, faMoneyCheckDollar, faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import { PreAgreement } from '../../../../entities/PreAgreement/PreAgreement'
import LoadingComponent from '../../../LoadingComponent'
import { NegotiationOption } from '../../../../entities/NegotiationOption/NegotiationOption'
import { Receivable } from '../../../../entities/Receivable/Receivable'
import { formatBRValue } from '../../../../utils/valueFormatters/formatBRValue'

import { useAppDispatch, useAppSelector } from '../../../../../NegotiationPortal/hooks'
import {
    selectSelectedReceivablesUUID,
    setReceivables,
    removeReceivable,
    selectExtraReceivablesUUID,
    selectExtraReceivables,
    selectAdditionalReceivablesUUID,
    addAdditionalReceivableUUID,
    addSelectedNegotiationOptionItemUUID,
    removeAdditionalReceivableUUID,
    removeSelectedNegotiationOptionItemUUID,
} from '../../../../../NegotiationPortal/components/Receivable/Negotiation/negotiationSlice'
import getKey from '../../../../utils/key/getKey'
import { NegotiationOptionItem } from '../../../../entities/NegotiationOption/NegotiationOption'
import { postLogThunk } from '../../../../../NegotiationPortal/components/logs/logsThunk'
import { selectDocumentNumber } from '../../../AuthForm/authFormSlice'
import { useCookies } from 'react-cookie'

interface IReEnrolmentProps {
    preAgreement?: PreAgreement | null,
    negotiationOption?: NegotiationOption | null,
    isLoading?: boolean,
    rematReceivables?: Receivable[]
    negotiationOptionItems: NegotiationOptionItem[]
    extraReceivablesUUID: string[]
}
const ReEnrolmentSection = (props: IReEnrolmentProps) => {
    const dispatch = useAppDispatch()
    const selectedAdditionalReceivablesUUID = useAppSelector(selectAdditionalReceivablesUUID)
    const documentNumber = useAppSelector(selectDocumentNumber)
    const [cookies] = useCookies(['accessUUID']);
    const accessUUID = cookies.accessUUID
    // const selectedReceivablesUUID = useAppSelector(selectSelectedReceivablesUUID)
    // const extraReceivables = useAppSelector(selectExtraReceivables)
    // const extraReceivablesUUID = useAppSelector(selectExtraReceivablesUUID)

   
    const isRematAlreadyAdded = (): boolean => {
        const uuids = props.extraReceivablesUUID.filter(
            uuid => selectedAdditionalReceivablesUUID.includes(uuid)
        )
        return uuids.length > 0
    }

    const addRemat = () => {
        if(props.negotiationOption) {
            const negotiationOptionItem = props.negotiationOption.negotiationOptionItems.filter(item => {
                return props.extraReceivablesUUID.includes(item.receivableUUID)
            }).map(item => item.uuid)
            dispatch(
                addAdditionalReceivableUUID(
                    props.extraReceivablesUUID
                )
            )
            dispatch(addSelectedNegotiationOptionItemUUID(
                negotiationOptionItem
            ))
            
            dispatch(postLogThunk(
                {
                    event: 'ADDING_REMAT',
                    documentNumber: documentNumber,
                    formData: null,
                    details: {negotiationOptionItem: negotiationOptionItem},
                    accessUUID: accessUUID
                }
            ))
        }
    }

    const removeRemat = () => {
        dispatch(postLogThunk(
            {
                event: 'REMOVING_REMAT_NEGOTIATION',
                documentNumber: documentNumber,
                formData: null,
                details: {extraReceivablesUUID: props.extraReceivablesUUID},
                accessUUID: accessUUID
            }
        ))

        props.extraReceivablesUUID.forEach((uuid) => {
            if(props.negotiationOption) {
                dispatch(removeAdditionalReceivableUUID(uuid))
                props.negotiationOption
                     .negotiationOptionItems
                     .filter(item => item.receivableUUID == uuid)
                     .forEach(item => dispatch(removeSelectedNegotiationOptionItemUUID(item.uuid)))

                
            }
        })
    }
    
    const handleRematButton = (): JSX.Element => {
        if(isRematAlreadyAdded()) {
            return (
            <>
                <div 
                    className="bg-[#b45044] mt-5  w-full h-10 font-bold text-sm text-gray-100 flex items-center justify-center rounded-lg hover:bg-[#c06156]"
                    onClick={e => removeRemat()}
                >
                    <FontAwesomeIcon icon={faMinusCircle} /> &nbsp;
                    Remover Rematrícula?
                </div>
                <table className="min-w-full text-center text-sm font-light">
                    <thead
                        className="border-b bg-neutral-50 font-medium dark:border-neutral-500 dark:text-neutral-800">
                        <tr>
                            <th scope="col" className=" px-6 py-4">Parcela</th>
                            <th scope="col" className=" px-6 py-4">Valor Original</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.rematReceivables && props.rematReceivables.map(item => item ? (
                            <tr className="border-b dark:border-neutral-500" key={getKey()}>
                                <td className="whitespace-nowrap  px-6 py-4 font-medium">{item.product?.description}</td>
                                <td className="whitespace-nowrap  px-6 py-4">R$ {item.installments ? formatBRValue(item.installments[0].currentValue) : ""}</td>
                            </tr>
                        ) : null)}
                    </tbody>
                </table>
            </>
            )

        } else {
            return (
                <>
                    <div
                        className="bg-[#8038f0] mt-5  w-full h-10 font-bold text-sm text-gray-100 flex items-center justify-center rounded-lg hover:bg-[#9462e1]"
                        onClick={e => addRemat()}
                    >
                        <FontAwesomeIcon icon={faPlusCircle} /> &nbsp;
                        Incluir Rematricula?
                    </div>
                    <table className="min-w-full text-center text-sm font-light">
                        <thead
                            className="border-b bg-neutral-50 font-medium dark:border-neutral-500 dark:text-neutral-800">
                            <tr>
                                <th scope="col" className=" px-6 py-4">Parcela</th>
                                <th scope="col" className=" px-6 py-4">Valor Original</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.rematReceivables && props.rematReceivables.map(item  => item ? (
                                <tr className="border-b dark:border-neutral-500" key={getKey()}>
                                    <td className="whitespace-nowrap  px-6 py-4 font-medium">0{item.installments && item.product?.description}</td>
                                    <td className="whitespace-nowrap  px-6 py-4">R$ {item.installments && formatBRValue(item.installments[0].currentValue)}</td>
                                </tr>
                            ) : null)}
                        </tbody>
                    </table>
                </>
            )
        }
        return <></>
    }


    return (
        <>
            <div
                className='w-full h-auto p-6 bg-white rounded-md my-3'
                style={{boxShadow:'3px 3px 10px #b9b9b9'}}
            >
                {props.isLoading &&
                    (<LoadingComponent />)
                }
                {!props.isLoading && handleRematButton()}
            </div>
        </>
    )
}

export default ReEnrolmentSection

