import React, { useEffect } from 'react';
import Header from '../../partials/Header';
import { BackgroundPanelPages, NegotiationPageContentContainer, PanelPagesContentContainer } from '../style';
import { NegotiationChoicesContainer } from '../../partials/Negotiation/NegotiationChoicesContainer';
import { SummaryContainer } from '../../partials/Negotiation/SummaryContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyCheckDollar } from '@fortawesome/free-solid-svg-icons'
import Breadcrumbs from '../../partials/Breadcrumbs';
import NegotiationPanel from '../../components/Receivable/Negotiation';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { selectEmail, selectPhone, selectPreAgreement, selectClientGeoLocation } from '../../components/Receivable/Negotiation/negotiationSlice';
import { selectDocumentNumber } from '../../../common/features/AuthForm/authFormSlice';
import { useCookies } from 'react-cookie';
import { postLogThunk } from '../../components/logs/logsThunk';

const NegotiationPage = () => {
    const [cookies] = useCookies(['accessUUID']);
    const accessUUID = cookies.accessUUID

    const navigate = useNavigate()
    const links = [
        {
            display: 'Home',
            url: '/'
        },
        { 
            display: 'Contratos',
            url: '/contratos'
        },
        {
            display: 'Negociação',
            url: '/negociacao'
        }
    ]

    const handleSubmit = () => {
        dispatch(postLogThunk(
            {
                event: 'PRESSED_NEXT_PAGE_NEGOTIATION_BUTTON',
                documentNumber: documentNumber,
                formData: null,
                details: null,
                accessUUID: accessUUID
            }
        ))
        navigate('/resumo')
    }
    const email = useAppSelector(selectEmail)
    const phone = useAppSelector(selectPhone)
    const documentNumber = useAppSelector(selectDocumentNumber)
    const preAgreement = useAppSelector(selectPreAgreement)
    const geoLocation= useAppSelector(selectClientGeoLocation)
    const dispatch = useAppDispatch()

    useEffect(() => {
        let accessUUID = cookies.accessUUID
        
        dispatch(postLogThunk(
            {
                event: 'ENTERED_NEGOTIATION_PAGE',
                documentNumber: documentNumber,
                formData: null,
                details: null,
                accessUUID: accessUUID
            }
        ))
    }, [])
    
    return (
        <>  
            <BackgroundPanelPages>
                <Header links showLogout={true}/>  
                <Breadcrumbs links={links}/>
                <NegotiationPageContentContainer>
                    <div className='w-full h-auto flex flex-col-reverse md:flex-row justify-between items-start my-5'>
                        <div className='w-full lg:w-7/12'>
                            <NegotiationPanel />
                        </div>
                        {/* <div className='w-full lg:w-5/12 md:ml-5' style={{position: 'sticky', top: '0'}}> */}
                        <div className='w-full lg:w-5/12 md:ml-5'>
                            <SummaryContainer
                                handleSubmit={handleSubmit}
                                email={email}
                                phone={phone}
                                documentNumber={documentNumber}
                                preAgreement={preAgreement}
                                geoLocation={geoLocation}
                                submitDisplayButton='Avançar'
                            />
                        </div>
                    </div>
                </NegotiationPageContentContainer>
            </BackgroundPanelPages>
        </>
    )
}


export default NegotiationPage;