import { NegotiationOption } from "../../../common/entities/NegotiationOption/NegotiationOption"
import { PreAgreement } from "../../../common/entities/PreAgreement/PreAgreement"
import LoadingComponent from "../../../common/features/LoadingComponent"
import {
    selectIsLoading,
    selectPreAgreement,
    selectSelectedReceivables,
    selectInstallmentNumber,
    selectPaymentMethod,
    selectPaymentDate,
    setClientGeoLocation,
    selectClientGeoLocation,
    selectIsAgreementCompleted,
    resetNegotiation,
    selectNegotiationError,
    selectSelectedNegotiationOption,
    selectEmail,
    selectPhone,
    setIsPhoneValid,
    setIsEmailValid
} from "../../components/Receivable/Negotiation/negotiationSlice"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { formatBRValue } from "../../../common/utils/valueFormatters/formatBRValue"
import getKey from "../../../common/utils/key/getKey"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { CreateAgreementThunk } from "../../components/Receivable/Negotiation/negotiationSliceThunk"
import { resetAuthForm, selectDocumentNumber, selectUserIsAuthenticated } from "../../../common/features/AuthForm/authFormSlice"
import { resetReceivablesList } from "../../components/Receivable/ListPanel/receivableListSlice"
import { convertDateToBRFormat } from "../../../common/utils/date/convert"
import { phoneValidator } from "../../../common/utils/validator/phoneValidator"
import { emailValidator } from "../../../common/utils/validator/emailValidator"

interface IHandleSubmit {
    email: string | null
    phone: string | null
    documentNumber: string
    preAgreement: PreAgreement | null
    geoLocation: {latitude: string, longitude: string} | null
}
interface ISummaryContainer {
    handleSubmit: (props: IHandleSubmit) => void,
    email: string | null
    phone: string | null
    documentNumber: string
    preAgreement: PreAgreement | null
    geoLocation: {latitude: string, longitude: string} | null
    submitDisplayButton?: string
}
export const SummaryContainer = (props: ISummaryContainer) => {
    const preAgreement = useAppSelector(selectPreAgreement)
    const negotiationOption = useAppSelector(selectSelectedNegotiationOption)
    const receivables = useAppSelector(selectSelectedReceivables)
    const isLoading = useAppSelector(selectIsLoading)
    const paymentMethod = useAppSelector(selectPaymentMethod)
    const paymentDate = useAppSelector(selectPaymentDate)
    const geoLocation = useAppSelector(selectClientGeoLocation)
    const isAgreementCompleted = useAppSelector(selectIsAgreementCompleted)
    const userIsAuthenticated = useAppSelector(selectUserIsAuthenticated)
    const error = useAppSelector(selectNegotiationError)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const _displayButton = props.submitDisplayButton == undefined ? "Confirmar Acordo" : props.submitDisplayButton

    const valueWithCharges = (preAgreement: PreAgreement | null): string => {
        if(preAgreement) {
            const total = preAgreement.fee + preAgreement.fine + preAgreement.interest + preAgreement.taxes
            if(total > 0) {
                return `${formatBRValue(total)}`
            }
        }

        return '0.00'
    }   

    const _total = receivables.map(
        receivable => receivable.currentValue
    //@ts-ignore
    ).reduce((acc, value) => (acc + value), 0)

    let _discount = 0
    if(preAgreement && _total) {
        _discount = _total - preAgreement.currentValue
    }

    const displayInstallmentNumber = (installmentNumber: number, lengthOfInstallments: number) => {
        if(installmentNumber == 0 && lengthOfInstallments == 1) {
            return 'À vista'
        } else if (installmentNumber == 0){
            return 'Entrada'
        } else {
            return `${installmentNumber + 1}`
        }
    }

    const _displayPaymentMethod = (selectedPaymentMethod: string) => {
        const converterPaymentMethod: { [key: string]: string } = {
            'CREDIT_CARD': 'Cartão de Crédito',
            'BANKSLIP': 'Boleto Bancário',
            'PIX': 'PIX',
        }
        return converterPaymentMethod[selectedPaymentMethod] ? converterPaymentMethod[selectedPaymentMethod] : selectedPaymentMethod
    }


    useEffect(() => {
        const showPosition = (position: any) => {
            const latitude = position.coords.latitude; 
            const longitude = position.coords.longitude;
            
            dispatch(setClientGeoLocation({latitude: latitude, longitude:longitude}))
        }
        if(navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition)
        }
    }
    , [])
    useEffect(() => {
        if(isAgreementCompleted){
            navigate('/sucesso')
        }
    }
    , [isAgreementCompleted])

    useEffect(()=> {
        if(!userIsAuthenticated){
            dispatch(resetNegotiation())
            dispatch(resetAuthForm())
            dispatch(resetReceivablesList())
            navigate('/')
        }
    }, [userIsAuthenticated])

    let originalValueWithTaxes = preAgreement?.originalReceivableValue
    let sortedInstallments = []
    if(preAgreement){
        //originalValueWithTaxes = preAgreement.originalReceivableValue + preAgreement.fine + preAgreement.fee + preAgreement.taxes + preAgreement.interest
    }

    const getSortedInstallments = (preAgreement: PreAgreement) => {
        return [...preAgreement.installments].sort((a,b) => a.installmentNumber - b.installmentNumber)
    }

    return(
        <div className='w-full h-auto my-3  p-6 bg-white rounded-md flex flex-col'  style={{boxShadow:'3px 3px 10px #b9b9b9'}}>
            <p className='font-bold text-xl text-gray-700'>
                Resumo da Negociação
            </p>

            {isLoading && (
                <LoadingComponent />
            )}
            {error && (
                <p className="font-medium text-base text-gray-600">
                    Erro ao procurar por descontos para este acordo, tente novamente mais tarde.
                </p>
            )}
            {!isLoading && preAgreement && (
                <>
                    <div className="border-b h-1 border-gray-300 pb-3"></div>
                    <div className='w-full p-1 flex flex-row justify-between pr-3'>
                        <div className="font-medium text-lg text-gray-700 pr-3">Valor atual da dívida</div>
                        <div className="font-light text-lg text-gray-600 ">R$ {formatBRValue(preAgreement.originalReceivableValue)}</div>
                    </div>
                    <div className='w-full p-1 flex flex-row justify-between pr-3'>
                        <div className="font-medium text-lg text-green-700 pr-3">Descontos</div>
                        <div className="font-light text-lg text-green-600 ">R$ {formatBRValue(preAgreement.currentValue - preAgreement.originalReceivableValue)}</div>
                    </div>
                    <div className='w-full p-1 flex flex-row justify-between pr-3'>
                        <div className="font-medium text-lg text-gray-800 pr-3">Valor total do acordo</div>
                        <div className="font-light text-lg text-gray-700 whitespace-nowrap">R$ {formatBRValue(preAgreement.currentValue)}</div>
                    </div>
                    <div className='w-full p-1 flex flex-row justify-between pr-3'>
                        <div className="font-medium text-lg text-gray-700 pr-1">Forma de Pagamento</div>
                        <div className="font-light text-lg text-gray-600 whitespace-nowrap">{_displayPaymentMethod(paymentMethod)}</div>
                    </div>

                    <p className='font-bold text-xl text-gray-700 pt-5'>
                        Condição
                    </p>
                    <div className="border-b h-1 border-gray-300 pb-3">

                    </div>

                    <div className='w-full p-1 flex flex-row'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Parcela</th>
                                    <th>Valor</th>
                                    <th>Vencimento</th>
                                </tr>
                            </thead>
                            <tbody>
                                {preAgreement.installments.length == 0 && (
                                    <tr key={getKey()}>
                                        <td>
                                            A vista
                                        </td>
                                        <td>
                                            {preAgreement.downpayment}
                                        </td>
                                        <td>---</td>
                                    </tr>
                                )}
                                {preAgreement.installments.length > 0 && (
                                    <>
                                        {getSortedInstallments(preAgreement).map((installment, index) => {
                                            if(installment) {
                                                return (
                                                
                                                    <tr key={getKey()}>
                                                        <td>{displayInstallmentNumber(index, preAgreement.installments.length)}</td>
                                                        <td>R$ {formatBRValue(installment.currentValue)}</td>
                                                        <td>{convertDateToBRFormat(installment.dueDate)}</td>
                                                    </tr>
                                                
                                                )
                                            } else {
                                                return <></>
                                            }
                                       })}
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>

                    <div className="border-b h-1 border-gray-300 pb-3">
                    </div>

                    <div
                        className={`mt-5 w-full text-sm text-gray-400 ${geoLocation ? 'hidden' : ''}`}
                    >
                        É necessário permitir que o navegador acesse sua localização para fechar o acordo.
                    </div>               
                    <div 
                        className={`
                         mt-2 w-full 
                        h-10 font-bold text-lg text-gray-100 
                        flex items-center justify-center 
                        rounded-lg hover:bg-green-600 
                        bg-green-500 cursor-pointer`
                        }

                        onClick={
                            () => {
                                props.handleSubmit({
                                    email: props.email,
                                    phone: props.phone,
                                    documentNumber: props.documentNumber,
                                    geoLocation: props.geoLocation,
                                    preAgreement: props.preAgreement
                                })
                            }
                        }
                    >
                        {_displayButton}
                    </div>
                </>
            )}
        </div>
    )
}
