import tw from "tailwind-styled-components";


export const AuthFormContainer = tw.div`
    fixed
    top-0
    left-0
    right-0
    z-50
    w-full
    p-4
    overflow-x-hidden
    overflow-y-auto
    md:inset-0
    h-[calc(100%-1rem)]
    md:h-full
    bg-[#000000a3]
`