import React from "react";
import { PreAgreement } from "../../../../entities/PreAgreement/PreAgreement";
import { formatBRValue } from "../../../../utils/valueFormatters/formatBRValue";
import { convertDateToBRFormat } from "../../../../utils/date/convert";
import { NegotiationOptionsSectionType } from "./interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faMoneyCheckDollar, faCommentsDollar } from "@fortawesome/free-solid-svg-icons";
import LoadingComponent from "../../../LoadingComponent";
import NegotiationExtras from "../../../NegotiationExtras";
import getKey from "../../../../utils/key/getKey";
import { Receivable } from "../../../../entities/Receivable/Receivable";
import { NegotiationOptionItem } from "../../../../entities/NegotiationOption/NegotiationOption";
// import { selectReceivableFromList } from "../../../../utils/receivables/selectReceivableFromList";

export const NegotiationOptionsSection = ({
    negotiationOption,
    receivables,
    handleChangeFunction,
    isLoading,
    selectedItemsUUID = [],
    extraReceivablesUUID,
    selectedReceivables
} : NegotiationOptionsSectionType) => {
    //selectedItemsUUID = São os items que devem ser selecionados, coloquei aqui para depois adicionarmos
    //funcionalidades que vão controlar o sistema de grupos.
    //MOCK
    const negotiationOptionItems = negotiationOption?.negotiationOptionItems
    //Aqui deve separar os itens em grupos
    const itemsGroup1 = negotiationOptionItems
    const itemsGroup2 = negotiationOptionItems 
    const itemsGroup3 = negotiationOptionItems
    //Criar mecanismo para selecionar automaticamente itens de cada grupo de acordo com necessidade.

    const rematReceivableUUIDS = negotiationOptionItems?.filter(
        item => item.extraDomain?.data.remat
    ).map(
        item => item.receivableUUID
    )

    const rematReceivableDescription = (receivable: Receivable, rematReceivableUUIDS?: string[]): string => {
        if(rematReceivableUUIDS && rematReceivableUUIDS.includes(receivable.uuid)) {
            return "(Rematricula)"
        } else {
            return ""
        }
    }
    return(
        <div className='w-full h-auto p-6 bg-white rounded-md my-3' style={{boxShadow:'3px 3px 10px #b9b9b9'}}>
            <p className='font-bold text-2xl text-gray-700'>
                <FontAwesomeIcon icon={faCommentsDollar} /> &nbsp;
                Valores em aberto
            </p>

                <>
                {(
                    <NegotiationExtras
                        isLoading={isLoading}
                        negotiationOption={negotiationOption}
                        receivables={receivables}
                        extraReceivablesUUID={extraReceivablesUUID}
                    />
                )}
                <table className="min-w-full text-center text-sm font-light">
                    <thead
                        className="border-b bg-neutral-50 font-medium dark:border-neutral-500 dark:text-neutral-800">
                        <tr>
                            <th scope="col" className=" px-6 py-4">Parcela</th>
                            <th scope="col" className=" px-6 py-4">Vencimento</th>
                            <th scope="col" className=" px-6 py-4">Valor</th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedReceivables.map((receivable) => {
                            return(
                                <tr className="border-b dark:border-neutral-500" key={getKey()}>
                                    <td className="whitespace-nowrap  px-6 py-4 font-medium">{receivable.product?.description} {rematReceivableDescription(receivable, rematReceivableUUIDS)}</td>
                                    <td className="whitespace-nowrap  px-6 py-4">{receivable.installments ? convertDateToBRFormat(receivable.installments[0].dueDate) : ""}</td>
                                    <td className="whitespace-nowrap  px-6 py-4">R$ {formatBRValue(receivable.currentValue)}</td>
                                </tr>
                            )
                            
                        })}
                </tbody>
                </table>
                </>
        </div>
    )
}
