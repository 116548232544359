import React from "react";
import getKey from "../../../../utils/key/getKey";
import { formatBRValue } from "../../../../utils/valueFormatters/formatBRValue";
import { AgreementCardTableBodyAggregationType } from "../interfaces";
import AgreementCardTableButton from "./AgreementCardTableButton";
import { AgreementCardTableBody, AgreementCardTableCell, AgreementCardTableRow } from "./AgreementCardTableStyle";
import { convertDateToBRFormat } from "../../../../utils/date/convert";
import { Installment } from "../../../../entities/Receivable/Installment";

const AgreementCardTableBodyAggregation = ({
    agreement,
    receivables,
    currentReceivable,
    originalReceivables,
    paymentsLoading
} : AgreementCardTableBodyAggregationType) => {

    //TODO Select receivables 
    const agreementReceivable = currentReceivable

    const _returnDownPaymentButton = () => {
        if(agreementReceivable && agreementReceivable.downpayment){
            const downpayment = agreementReceivable.downpayment
            return(
                <AgreementCardTableRow key={getKey()}>
                    <AgreementCardTableCell>
                        <>
                            <b className="pr-2 md:hidden">
                                Parcela: 
                            </b>
                            Entrada
                        </>
                    </AgreementCardTableCell>
                    <AgreementCardTableCell>
                        <>
                            <b className="pr-2 md:hidden">
                                Vencimento: 
                            </b>
                            -
                        </>
                    </AgreementCardTableCell>
                    <AgreementCardTableCell>
                        <>
                            <b className="pr-2 md:hidden">
                                Valor: 
                            </b>
                            {`R$ ${formatBRValue(downpayment?.value)}`}
                        </>
                    </AgreementCardTableCell>
                    <AgreementCardTableCell>
                        <AgreementCardTableButton 
                            downpayment={downpayment}
                            paymentsLoading={paymentsLoading}
                            receivable={agreementReceivable}
                        />
                    </AgreementCardTableCell>
                </AgreementCardTableRow>
                )
        }else{
            return null
        }
    }

    if(typeof agreementReceivable != 'undefined'){
        let orderedInstallments: Installment[] = []

        if(agreementReceivable.installments){
            orderedInstallments = [...agreementReceivable.installments].sort((a,b) => a.installmentNumber - b.installmentNumber)
        }
        return (
            <AgreementCardTableBody className="mt-0 md:mt-2">
                {/* {
                    _returnDownPaymentButton()
                }  */}
                {(agreementReceivable && agreementReceivable.installments) ? orderedInstallments.map((installment) => {
                    if(typeof installment != 'undefined'){
                        return(
                            <AgreementCardTableRow key={getKey()}>
                                <AgreementCardTableCell>
                                    <>
                                        <b className="pr-2 md:hidden">
                                            Parcela: 
                                        </b>
                                        {installment.installmentNumber}
                                    </>
                                </AgreementCardTableCell>
                                <AgreementCardTableCell>
                                    <>
                                        <b className="pr-2 md:hidden">
                                            Vencimento: 
                                        </b>
                                        {convertDateToBRFormat(installment.dueDate)}
                                    </>
                                </AgreementCardTableCell>
                                <AgreementCardTableCell>
                                    <>
                                        <b className="pr-2 md:hidden">
                                            Valor: 
                                        </b>
                                        {`R$ ${formatBRValue(installment.currentValue)}`}
                                    </>
                                </AgreementCardTableCell>
                                <AgreementCardTableCell>
                                    <AgreementCardTableButton 
                                        installment={installment}
                                        paymentsLoading={paymentsLoading}
                                        receivable={agreementReceivable}
                                    />
                                </AgreementCardTableCell>
                            </AgreementCardTableRow>
                        )
                    }
                    
                }) : null} 
            </AgreementCardTableBody>
        )
    } else {
        return null
    }
    
}

export default AgreementCardTableBodyAggregation