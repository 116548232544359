import { Receivable } from "../Receivable/Receivable"
import { NegotiationOption } from "./NegotiationOption"


interface ReceivablesProps {
    negotiationOption: NegotiationOption,
    receivables: Receivable[]
}   

export const getObligatoryReceivablesFromNegotiationOption = ({negotiationOption, receivables}: ReceivablesProps): Receivable[] => {
    const selectedReceivables = negotiationOption.obligatoryReceivablesUUID.map((uuid) => {
        const foundReceivables = receivables.filter((receivable) => receivable.uuid == uuid)

        return(foundReceivables[0])
    })
    return selectedReceivables
}

export const getReceivablesFromNegotiationOption = ({negotiationOption, receivables}: ReceivablesProps): Receivable[] => {
    const allReceivables = getObligatoryReceivablesFromNegotiationOption({negotiationOption, receivables})

    if(negotiationOption.negotiationOptionItems) {
        const optionReceivableUUIDs = negotiationOption.negotiationOptionItems.map(item => (
            item.receivableUUID
        ))

        const optionalReceivables = receivables.filter(receivable => receivable.uuid in optionReceivableUUIDs)

        return [
            ...allReceivables,
            ...optionalReceivables
        ]
    }

    return allReceivables;
}
