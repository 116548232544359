import React from 'react'
import { Agreement } from '../../../../../common/entities/Agreement/Agreement';
import { Receivable } from '../../../../../common/entities/Receivable/Receivable';
import { Creditor } from '../../../../../common/entities/Creditor/Creditor';
import AgreementCard from '../../../../../common/features/card/AgreementCard';
import { getCurrentReceivableFromAgreement, getOriginalReceivablesFromAgreement } from '../../../../../common/entities/Agreement/filter';
import HistoryCard from '../../../../../common/features/card/HistoryCard';
import getKey from '../../../../../common/utils/key/getKey';


export interface IAgreementList{
    agreements: Agreement[],
    receivables: Receivable[],
    creditor: Creditor,
    clickCallback: (arg1: Agreement) => void
    paymentsLoading: string[]
}

const AgreementList = ({
    agreements,
    receivables,
    creditor,
    clickCallback,
    paymentsLoading
}: IAgreementList) => {
    return(
        <>
            {
                agreements.map((agreement) => {
                    let originalReceivables:Receivable[] = []
                    if(agreement.originalReceivablesUUID){
                        originalReceivables = getOriginalReceivablesFromAgreement({agreement, receivables})
                    }
                    
                    const currentReceivable = getCurrentReceivableFromAgreement({agreement, receivables})

                    if(currentReceivable && (currentReceivable.status == 'AWAITING_PAYMENT' || currentReceivable.status == 'OPENED')){
                        return(
                            <div className="w-full py-4" key={getKey()}>
                                <AgreementCard 
                                    agreement={agreement}
                                    receivables={receivables}
                                    creditor={creditor}
                                    clickCallback={clickCallback}
                                    originalReceivables={originalReceivables}
                                    currentReceivable={currentReceivable}
                                    paymentsLoading={paymentsLoading}
                                />
                            </div>
                        )
                    }else if(currentReceivable && (currentReceivable.status == 'SETTLED' || currentReceivable.status == 'CANCELLED')){
                        return (<div className="w-full py-4" key={getKey()}>
                            <HistoryCard 
                                agreement={agreement}
                                receivables={receivables}
                                creditor={creditor}
                                clickCallback={clickCallback}
                                originalReceivables={originalReceivables}
                                currentReceivable={currentReceivable}
                                paymentsLoading={paymentsLoading}
                            />
                        </div>)
                    } else{
                        return <></>
                    }
                    
                })
            }
        </>
    )
}

export default AgreementList;