import React from "react"
import PropTypes from 'prop-types';
import { Bar, Step, StepsContainer, Header, Description, Container, TextContainer, Ball, TitleContainer, StepHeader, Content, Blank } from "./styled"
import { add_alpha } from "../../../utils/ color/add_alpha";
import { get_font_color } from "../../../utils/ color/get_font_color";


//TO CONVERT TO TSX
export const NegotiationSteps = (PropTypes) => {
    const step_circle_color = add_alpha(PropTypes.ball_color, .3).toRgbString()
    const title_font_color = get_font_color(PropTypes.background_color)

    return(
        <Container>
            <Bar style={{backgroundColor:`${PropTypes.bar_color}`}}/>
            <StepsContainer className="bg-[#F29129]">
                <Blank/>
                <TextContainer>
                    <Header style={{color:`${title_font_color}`}}>
                        {PropTypes.header_text}
                    </Header>
                    <Description style={{color:`${title_font_color}`}}>
                        {PropTypes.desc_text}
                    </Description>
                </TextContainer>
                <Step style={{backgroundColor:`${PropTypes.steps_color}`}}>
                    <StepHeader>
                        <Ball style={{backgroundColor:`${step_circle_color}`,color:`${PropTypes.ball_text_color}`}}>1</Ball>
                        <TitleContainer style={{color:`${PropTypes.step_header_color}`}}>{PropTypes.step1_header_text}</TitleContainer>
                    </StepHeader>
                    <Content style={{color:`${PropTypes.step_content_color}`}}>{PropTypes.step1_content_text}</Content>
                </Step>
                <Step style={{backgroundColor:`${PropTypes.steps_color}`}}>
                    <StepHeader>
                        <Ball style={{backgroundColor:`${step_circle_color}`,color:`${PropTypes.ball_text_color}`}}>2</Ball>
                        <TitleContainer style={{color:`${PropTypes.step_header_color}`}}>{PropTypes.step2_header_text}</TitleContainer>
                    </StepHeader>
                    <Content style={{color:`${PropTypes.step_content_color}`}}>{PropTypes.step2_content_text}</Content>
                </Step>
                <Step style={{backgroundColor:`${PropTypes.steps_color}`}}>
                    <StepHeader>
                        <Ball style={{backgroundColor:`${step_circle_color}`,color:`${PropTypes.ball_text_color}`}}>3</Ball>
                        <TitleContainer style={{color:`${PropTypes.step_header_color}`}}>{PropTypes.step3_header_text}</TitleContainer>
                    </StepHeader>
                    <Content style={{color:`${PropTypes.step_content_color}`}}>{PropTypes.step3_content_text}</Content>
                </Step>
                <Blank/>
            </StepsContainer>
        </Container>
    )
}

NegotiationSteps.propTypes = {
    header_text: PropTypes.string,
    desc_text: PropTypes.string,
    step1_header_text: PropTypes.string,
    step1_content_text: PropTypes.string,
    step2_header_text: PropTypes.string,
    step2_content_text: PropTypes.string,
    step3_header_text: PropTypes.string,
    step3_content_text: PropTypes.string,
    bar_color: PropTypes.string,
    background_color: PropTypes.string,
    steps_color: PropTypes.string,
    ball_color: PropTypes.string,
    ball_text_color: PropTypes.string,
    step_header_color: PropTypes.string,
    step_content_color: PropTypes.string,
}

NegotiationSteps.defaultProps = {
    header_text: "Veja como é fácil, rápido e seguro negociar!",
    desc_text: "São apenas 3 passos para você ficar em dia e seguir com seus planos para um futuro melhor.",
    step1_header_text: "Identificação",
    step1_content_text: "Por segurança, é necessário que você se identifique digitando seu CPF e data de nascimento ou a validação de algum dado adicional.",
    step2_header_text: "Seleção da melhor oferta, vencimento e meio de pagamento",
    step2_content_text: "Neste momento você acessa as melhores ofertas para pagamento à vista ou parcelado.Além disso, é possível pagar via Pix, Cartão de Crédito ou Boleto.",
    step3_header_text: "Conclusão da negociação",
    step3_content_text: "Para concluir a negociação, após a escolha do meio de pagamento, basta efetuar o pagamento.",
    bar_color: "#ffffff",
    background_color: "#FFAD01",
    steps_color: "#ffffff",
    ball_color: "#bde4ff",
    ball_text_color: "#0070bc",
    step_header_color: "#0070bc",
    step_content_color: "#0070bc",
}