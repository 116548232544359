import React, { useRef } from "react";
import { ContactOptionsSectionType } from "./interfaces";
import LoadingComponent from "../../../LoadingComponent";
import { DangerAlert } from "../../../../utils/alerts";


export const ContactOptionsSection = ({
    handleEmailChangeFunction,
    handlePhoneChangeFunction,
    isLoading,
    isPhoneValid,
    isEmailValid,
    handleSubmit,
    email,
    phone,
    documentNumber,
    geoLocation,
    preAgreement
} : ContactOptionsSectionType) => {
    
    return(
        <div className='w-full h-auto my-3 p-6 pb-10 bg-white rounded-md flex flex-col'  style={{boxShadow:'3px 3px 10px #b9b9b9'}}>
            <p className='font-bold text-2xl text-gray-700'>
                Contato
            </p>
            <p className='font-bold text-md text-gray-500 ml-1'>
                Para onde devemos encaminhar as informações do seu acordo?
            </p>

            {isLoading && (<LoadingComponent />)}
            {!isLoading && (
                <>
                <div className="flex p-1 mt-3 w-full flex-col">
                    {!isEmailValid && <DangerAlert message="Email inválido"/>}
                    <label htmlFor='email' className="mb-2 font-semibold text-gray-600 text-base">
                        E-mail: (Obrigatório)
                    </label>
                    <input 
                    className="form-control w-full md:w-4/6 border rounded px-2 py-1" 
                    id="email_input" 
                    type='email' 
                    placeholder="Exemplo: exemplo@gmail.com"
                    onChange={(e) => {handleEmailChangeFunction(e)}} 
                    />
                </div>
                <div className="flex p-1 mt-3 w-full flex-col">
                    <label htmlFor='phone_input' className="mb-2 font-semibold text-gray-600 text-base">
                        Celular: (Obrigatório)
                    </label>
                    {!isPhoneValid && <DangerAlert message="Celular inválido"/>}
                    <input 
                    className="form-control w-full md:w-4/6 border rounded p-0 px-2 py-1" 
                    style={{'padding':'0.25rem 0.5rem', }}
                    id="phone_input" 
                    type='text' 
                    placeholder="Exemplo: 11988887777"
                    onChange={(e) => {handlePhoneChangeFunction(e)}} 
                    maxLength={15}
                    />
                </div>
                <div 
                    className={`
                    mt-2 w-full 
                    h-10 font-bold text-lg text-gray-100 
                    flex items-center justify-center 
                    rounded-lg hover:bg-green-600 
                    bg-green-500 cursor-pointer`
                    }

                    onClick={
                        () => {
                            handleSubmit({
                                email: email,
                                phone: phone,
                                documentNumber: documentNumber,
                                geoLocation: geoLocation,
                                preAgreement: preAgreement
                            })
                        }
                    }
                >
                    Confirmar Acordo
                </div>
                </>
            )}
            
            
        </div>
    )
}
