import axios, {AxiosResponse} from "axios"
import { APIErrorCodes, GatewayError, TGatewayError } from "./errors"
import preAgreementConverter, {TPreAgreementResponse} from "./converters/preAgreementConverter"
import {Builder, Mocks} from '../../NegotiationPortal/components/Receivable/Negotiation/mocks'
import {Mocks as Mocks2} from '../../NegotiationPortal/components/Receivable/ListPanel/mocks'
import { PreAgreement } from "../entities/PreAgreement/PreAgreement"
import { CreateAgreementResponse } from "../entities/Agreement/Agreement"
import CreateAgreementConverter, { TCreateAgreementResponse } from "./converters/createAgreementConverter"
import { Receivable } from "../entities/Receivable/Receivable"
import { CAD, TCD } from "../entities/Negotiation/Documents"
import { Settings } from "../settings"
import { delay } from "../utils/delay"


export interface ICreateAgreementGateway {
    preAgreementUUID: string
}

export const createAgreementGateway = async ({
    preAgreementUUID
}: ICreateAgreementGateway): Promise<[
    CreateAgreementResponse, 
    Receivable[]
] | TGatewayError> => {

    if(Settings.env == 'production') {
        if(Settings.SERVER_HOST) {
            const response: AxiosResponse<TCreateAgreementResponse, any> = await axios.post(
                `${Settings.SERVER_HOST}/api/v1/${Settings.walletUUID}/agreement`,
                {
                    preagreement_uuid: preAgreementUUID
                }
            )

            return CreateAgreementConverter(response.data)
        } else {
            return GatewayError('DEV101')
        }
    } else {
        await delay(1000)
        const agreementResponse = CreateAgreementConverter(Mocks.createAgreementDTO())
        {
            return agreementResponse
        }
    }
}


export interface IgetTCDGateway {
    agreementUUID: string
}

type TGetTCDGatewayResponse = {
    payload: {
        tcd: {
            url: string
        }
    },
    error?: {
        code: keyof typeof APIErrorCodes,
    }
}

export const getTCDGateway = async ({
    agreementUUID
}: IgetTCDGateway): Promise<TCD | TGatewayError> => {
    if(Settings.env == 'production') {
        if(Settings.SERVER_HOST) {
            const response: AxiosResponse<TGetTCDGatewayResponse, any> = await axios.get(
                `${Settings.SERVER_HOST}/api/v1/${Settings.walletUUID}/agreement/${agreementUUID}/tcd`
            )

            return {type: 'TCD', url: response.data.payload.tcd.url}
        } else {
            return GatewayError('DEV101')
        }
    } else {
        await delay(1000)
        {
            return Mocks.getTCD()
        }
    }
}

export interface IgetCADGateway {
    agreementUUID: string
    clientGeoLocation: {
        longitude: string,
        latitude: string
    }
}

type TGetCADGatewayResponse = {
    payload: {
        cad: {
            url: string
        }
    },
    error?: {
        code: keyof typeof APIErrorCodes,
    }
}

export const getCADGateway = async ({
    agreementUUID,
    clientGeoLocation
}: IgetCADGateway): Promise<CAD | TGatewayError> => {
    if(Settings.env == 'production') {
        if(Settings.SERVER_HOST) {
            const response: AxiosResponse<TGetCADGatewayResponse, any> = await axios.post(
                `${Settings.SERVER_HOST}/api/v1/${Settings.walletUUID}/agreement/${agreementUUID}/cad`,
                {
                        latitude: clientGeoLocation.latitude,
                        longitude: clientGeoLocation.longitude
                }
            )

            return {type: 'CAD', url: response.data.payload.cad.url}
        } else {
            return GatewayError('DEV101')
        }
    } else {
        await delay(1000)
        {
            return Mocks.getCAD()
        }
    }
}


export interface IPutContactInfo {
    phone: string | null;
    email: string | null;
    documentNumber: string;
}

type TPutContactInfoResponse = {
    payload: any,
    error?: {
        code: keyof typeof APIErrorCodes,
    } | null
}

export const putContactInfo = async ({
    phone,
    email,
    documentNumber
}: IPutContactInfo): Promise<boolean | TGatewayError> => {
    if(Settings.env == 'production') {
        if(Settings.SERVER_HOST) {
            const response: AxiosResponse<TPutContactInfoResponse, any> = await axios.put(
                `${Settings.SERVER_HOST}/api/v1/${Settings.walletUUID}/client/${documentNumber}/contact`,
                {
                        email: email,
                        phone_number: phone
                }
            )
            return true
        } else {
            return GatewayError('DEV101')
        }
    } else {
        await delay(1000)
        {
            return true
        }
    }
}

export interface IgetAvailablePaymentDatesGateway {
    negotiationOptionUUID: string;
    paymentMethod: string
}

type TGetAvailablePaymentDatesGateway = {
    payload: {
        available_payment_dates: string[]
    },
    error?: {
        code: keyof typeof APIErrorCodes,
    }
}

export const getAvailablePaymentDatesGateway = async ({
    negotiationOptionUUID,
    paymentMethod
}: IgetAvailablePaymentDatesGateway): Promise<string[] | TGatewayError> => {
    if(Settings.env == 'production') {
        if(Settings.SERVER_HOST) {
            //TODO
            // const response: AxiosResponse<TGetAvailablePaymentDatesGateway, any> = await axios.get(
            //     `${Settings.SERVER_HOST}/api/v1/${Settings.walletUUID}/negotiation_option/${negotiationOptionUUID}/${paymentMethod}}/available_downpayment_dates`
            // )
            // return response.data.payload.available_payment_dates
            const response: AxiosResponse<string[], any> = await axios.get(
                `${Settings.SERVER_HOST}/api/v1/{wallet_uuid}/negotiation_option/{negotiation_option_uuid}/{payment_method}/available_downpayment_dates`
            )

            return response.data
            
        } else {
            return GatewayError('DEV101')
        }
    } else {
        await delay(1000)
        {
            return [
                "2023-05-10",
                "2023-05-11",
                "2023-05-12"
              ]
        }
    }
}