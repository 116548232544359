import axios, {AxiosResponse} from "axios"
import { GatewayError, TGatewayError } from "./errors"
import preAgreementConverter, {TLimitsResponse, TPreAgreementResponse, limitsConverter} from "./converters/preAgreementConverter"
import {Builder} from '../../NegotiationPortal/components/Receivable/Negotiation/mocks'
import { Limits, PreAgreement } from "../entities/PreAgreement/PreAgreement"
import { Settings } from "../settings"
import { delay } from "../utils/delay"


export interface IPreAgreementGateway {
    negotiationOptionUUID: string
    negotiationOptionItemsUuids: string[]
    paymentMethod: string
    numberOfInstallments: number
    paymentDate: string
}

export const preAgreementGateway = async ({
    negotiationOptionUUID,
    negotiationOptionItemsUuids,
    paymentMethod,
    numberOfInstallments,
    paymentDate,
}: IPreAgreementGateway): Promise<PreAgreement | TGatewayError> => {
    if(Settings.env == 'production') {
        const serverHost = Settings.SERVER_HOST
        const walletUUID = Settings.walletUUID
        if(serverHost) {
            const response: AxiosResponse<TPreAgreementResponse, any> = await axios.post(
                `${serverHost}/api/v1/${walletUUID}/preagreements`,
                {
                    negotiation_option_uuid: negotiationOptionUUID,
                    negotiation_option_items_uuids: negotiationOptionItemsUuids,
                    payment_method: paymentMethod ? paymentMethod : 'PIX',
                    installments_number: numberOfInstallments,
                    payment_date: paymentDate
                }
            )
            if(response.data.payload){
                return preAgreementConverter(response.data)
            }else{
                if(response.data.error && response.data.error.code == 'REMAT-01'){
                    return GatewayError('REMAT01')
                }else{
                    return GatewayError('EXT01')
                }
            }
            
        } else {
            return GatewayError('DEV101')
        }
    } else {
        console.log({
            negotiation_option_uuid: negotiationOptionUUID,
            negotiation_option_items_uuids: negotiationOptionItemsUuids,
            payment_method: paymentMethod ? paymentMethod : 'PIX',
            installments_number: numberOfInstallments,
            payment_date: paymentDate
        })
        await delay(1000)
        return Builder.preAgreement(numberOfInstallments, negotiationOptionUUID, true)
    }
}


export interface ILimitsGateway {
    negotiationOptionUUID: string
    negotiationOptionItemsUuids: string[]
    paymentMethod: string
    document_number: string
}

export const limitsGateway = async ({
    negotiationOptionUUID,
    negotiationOptionItemsUuids,
    paymentMethod,
    document_number
}: ILimitsGateway): Promise<Limits | TGatewayError> => {
    if(Settings.env == 'production') {
        const serverHost = Settings.SERVER_HOST
        const walletUUID = Settings.walletUUID
        if(serverHost) {
            const response: AxiosResponse<TLimitsResponse, any> = await axios.post(
                `${serverHost}/api/v1/${walletUUID}/client/${document_number}/negotiation_limits`,
                {
                    negotiation_option_uuid: negotiationOptionUUID,
                    negotiation_option_items_uuids: negotiationOptionItemsUuids,
                    payment_method: paymentMethod
                }
            )
            if(response.data.payload){
                return limitsConverter(response.data)
            }else{
                if(response.data.error && response.data.error.code == 'REMAT-01'){
                    return GatewayError('REMAT01')
                }else{
                    return GatewayError('EXT01')
                }
            }
            
        } else {
            return GatewayError('DEV101')
        }

    } else {
        console.log({
            negotiation_option_uuid: negotiationOptionUUID,
            negotiation_option_items_uuids: negotiationOptionItemsUuids,
            payment_method: paymentMethod
        })
        await delay(1000)
        if(paymentMethod == 'CREDIT_CARD'){
            return {
                type: 'Limits',
                uuid: 'L1',
                availableDates: ['2023-10-01', '2023-10-10', '2023-10-15', '2023-10-20'],
                installmentsInfo: [1, 2, 3, 4, 5, 6]
            } as Limits
        }else if(paymentMethod == 'PIX'){
            return {
                type: 'Limits',
                uuid: 'L2',
                availableDates: ['2023-10-01'],
                installmentsInfo: [1]
            } as Limits
        }else{
            return {
                type: 'Limits',
                uuid: 'L3',
                availableDates: ['2023-10-01', '2023-10-10', '2023-10-15', '2023-10-20', '2023-10-22', '2023-10-25', '2023-10-28', '2023-10-30'],
                installmentsInfo: [2, 5, 7, 12]
            } as Limits
        }
        
    }
}