import axios, {AxiosResponse} from "axios"
import {Mocks} from '../../NegotiationPortal/components/Receivable/ListPanel/mocks'
import { delay } from "../utils/delay"
import { GatewayError, TGatewayError } from "./errors"
import negotiationOptionConverter from "./converters/negotiationOptionConverter"
import { NegotiationOption } from "../entities/NegotiationOption/NegotiationOption"
import { Receivable } from "../entities/Receivable/Receivable"
import { PortalCreditor } from "../../NegotiationPortal/entities/creditor/PortalCreditor"
import walletConverter, { TCreditorResponse } from "./converters/walletConverter"
import { Wallet } from "../entities/Wallet/Wallet"
import { Settings } from '../settings'

interface IwalletGateway {
    documentNumber: string
}

export const walletGateway = async (
    {
        documentNumber
    }: IwalletGateway
): Promise<Wallet[] | TGatewayError> => {
    return [
        Mocks.wallet
    ]

    if(Settings.env == 'production') {
        const serverHost = Settings.SERVER_HOST
        if(serverHost) {
            const response: AxiosResponse<TCreditorResponse> = await axios.get(
                `${serverHost}/api/v2/wallets`
            )

            return walletConverter(response.data)
        } else {
            return GatewayError('DEV101')
        }
    } else {
        await delay(1000)
        return [
            Mocks.wallet, Mocks.wallet2
        ]
    }
}