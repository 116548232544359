import React from "react"
import getKey from "../../../utils/key/getKey"
import { CreditorContainer, CreditorLogoDiv, CreditorLogoImg, CreditorSectionLogos, CreditorSectionSubtitle, CreditorSectionTitle } from "./styled"
import { CreditorsSectionType, CreditorsSectionTypeDefault } from "./interfaces"



export const CreditorsSection = ({landingPageSettings = CreditorsSectionTypeDefault.landingPageSettings}: CreditorsSectionType) => {

    return (
        <>
            <div className="w-full flex items-center justify-center bg-white">
                <CreditorContainer>
                    <CreditorSectionTitle>
                        Marcas participantes
                    </CreditorSectionTitle>
                    <CreditorSectionSubtitle>
                        ALUNOemDIA é o canal digital oficial de negociação dos 
                        alunos das marcas de graduação do Grupo Cogna. 
                        Um ambiente criado para proporcionar a melhor experiência, 
                        no qual é possível analisar dívidas e encontrar as melhores 
                        condições para regularizar débitos educacionais.
                    </CreditorSectionSubtitle>
                    <CreditorSectionLogos>
                        {
                            landingPageSettings.creditorsLogos.map(
                                (logo) => {
                                    return (
                                        <CreditorLogoDiv key={getKey()}>
                                            <CreditorLogoImg
                                                src={logo}
                                                key={logo}
                                            />
                                        </CreditorLogoDiv>
                                        
                                    )
                                }
                            )
                        }
                    </CreditorSectionLogos>
                </CreditorContainer>
            </div>
        </>
    )
}
