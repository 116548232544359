import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../NegotiationPortal/hooks';
import { signInThunk, ISignIn, selectLoadingUser } from '../authFormSlice';
import { useNavigate } from 'react-router-dom';
import { selectUserIsAuthenticated } from '../authFormSlice';
import './inputDate.css';
import { DangerAlert } from '../../../utils/alerts';
import { useCookies } from 'react-cookie';
import { postLogThunk } from '../../../../NegotiationPortal/components/logs/logsThunk';

interface SignInDateOfBirthFormProps {
    documentNumber: string
}

const SignInDateOfBirthForm = ({documentNumber}: SignInDateOfBirthFormProps) => {

    const [dateOfBirth, setDateOfBirth] = useState("")
    const [isDateValid, setIsDateIsValid] = useState(true)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const userIsAuthenticated = useAppSelector(selectUserIsAuthenticated)
    const loadingUser = useAppSelector(selectLoadingUser)

    const [cookies] = useCookies(['accessUUID']);
    const accessUUID = cookies.accessUUID

    useEffect(() => {
        if(userIsAuthenticated) {
            navigate('/contratos')
        }
    }, [userIsAuthenticated])

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        dispatch(postLogThunk(
            {
                event: 'PRESSED_SIGNIN_BUTTON',
                documentNumber: documentNumber,
                formData: {
                    documentNumber: documentNumber,
                    dateOfBirth: dateOfBirth
                },
                details: null,
                accessUUID: accessUUID
            }
        ))
        if(dateOfBirth.length >= 10 ){
            const day = parseInt(dateOfBirth.slice(0,2))
            const month = parseInt(dateOfBirth.slice(3,5))
            const year = parseInt(dateOfBirth.slice(6,10))
            const formattedDate = `${year}-${month}-${day}`

            if(Number.isNaN(Date.parse(formattedDate))){
                // setIsDateIsValid(false)
                
                dispatch(
                    signInThunk(
                        {
                            documentNumber: documentNumber,
                            dateOfBirth: formattedDate,
                            accessUUID: accessUUID,
                            dispatch: dispatch
                        }
                    )
                )
                
                dispatch(postLogThunk(
                    {
                        event: 'PRESSED_SIGNIN_BUTTON',
                        documentNumber: documentNumber,
                        formData: {
                            documentNumber: documentNumber,
                            dateOfBirth: dateOfBirth
                        },
                        details: {error:{
                            code: '',
                            message: 'BirthDate is NaN',
                            formattedDate: formattedDate
                        }},
                        accessUUID: accessUUID
                    }
                ))
            }else{
                
                dispatch(
                    signInThunk(
                        {
                            documentNumber: documentNumber,
                            dateOfBirth: formattedDate,
                            accessUUID: accessUUID,
                            dispatch: dispatch
                        }
                    )
                )
            }
        }else{
            dispatch(
                signInThunk(
                    {
                        documentNumber: documentNumber,
                        dateOfBirth: dateOfBirth,
                        accessUUID: accessUUID,
                        dispatch: dispatch
                    }
                )
            )
            dispatch(postLogThunk(
                {
                    event: 'PRESSED_SIGNIN_BUTTON',
                    documentNumber: documentNumber,
                    formData: {
                        documentNumber: documentNumber,
                        dateOfBirth: dateOfBirth
                    },
                    details: {error:{
                        code: '',
                        message: 'DateOfBirth length are less than 10 characters'
                    }},
                    accessUUID: accessUUID
                }
            ))
            // setIsDateIsValid(false)
        }
    }

    return (
        <form className="space-y-6" action="#" onSubmit={(e) => handleSubmit(e) }>
            <div>
                <label htmlFor="documentNumber" className="block mb-2 text-sm font-medium text-gray-900">CPF</label>
                <input
                    type="text"
                    name="documentNumber"
                    id="documentNumber"
                    value={documentNumber}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    disabled
                />
            </div>
            <div>
                {!isDateValid && <DangerAlert className='w-full' message='Data de nascimento inválida'/>}
                <label htmlFor="date" className="block mb-2 text-sm font-medium text-gray-900">Data de Nascimento</label>
                <input
                    type="text"
                    name="date"
                    id="dateInput"
                    pattern="\d{2}\/\d{2}\/\d{4}" 
                    maxLength={10}
                    onChange={(e) => {
                        e.preventDefault()
                        let value = e.currentTarget.value

                        let v = value.replace(/\D/g,'').slice(0, 10);
                        let customValue = v

                        if (v.length >= 5) {
                            customValue = `${v.slice(0,2)}/${v.slice(2,4)}/${v.slice(4)}`;
                        }

                        else if (v.length == 3) {
                            customValue = `${v.slice(0,2)}/${v.slice(2)}`;
                        }
                        
                        e.currentTarget.value = customValue    

                        setDateOfBirth(customValue)
                    }}
                    disabled={loadingUser}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
            </div>

            <button type="submit" className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                {!loadingUser && "Faça o login na sua conta"}
                {loadingUser && "Buscando ..."}
            </button>
        </form>
    )
}

export default SignInDateOfBirthForm;
