import { Wallet } from "../../../common/entities/Wallet/Wallet"
import { Creditor } from "../../../common/entities/Creditor/Creditor"

export const getPortalCreditorFromWallet = (wallets: Wallet[]): Creditor[] => {
    type TUniqueCreditors = Map<string, Creditor>

    const creditors: TUniqueCreditors = new Map()
    wallets.forEach(
        wallet => {
            if(wallet?.creditor) {
                creditors.set(wallet.creditor.uuid, wallet.creditor)
            }
        }
    )
    return Array.from(creditors.values())
}