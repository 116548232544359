import React from "react";
import { AgreementCardTableHead, AgreementCardTableHeader, AgreementCardTableRow } from "./AgreementCardTableStyle";


const AgreementCardTableHeaderAggregation = () => {
    return (
        <AgreementCardTableHeader>
            <AgreementCardTableRow>
                <AgreementCardTableHead>
                    Parcela
                </AgreementCardTableHead>
                <AgreementCardTableHead>
                    Vencimento
                </AgreementCardTableHead>
                <AgreementCardTableHead>
                    Valor
                </AgreementCardTableHead>
                <AgreementCardTableHead>
                    
                </AgreementCardTableHead>
            </AgreementCardTableRow>
        </AgreementCardTableHeader>
    )
}

export default AgreementCardTableHeaderAggregation