import React, { useState } from "react"
import { ISuccessSection } from "./interfaces"
import LoadingComponent from "../../LoadingComponent"
import { useNavigate } from "react-router-dom"
import { postLogThunk } from "../../../../NegotiationPortal/components/logs/logsThunk"
import { useAppDispatch, useAppSelector } from "../../../../NegotiationPortal/hooks"
import { useCookies } from "react-cookie"
import { selectDocumentNumber } from "../../AuthForm/authFormSlice"

export const SuccessSection = ({receivable, tcdURL, cadURL, isLoadingCad, isLoadingTcd, agreementPayment} : ISuccessSection) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [cookies] = useCookies(['accessUUID']);
    const accessUUID = cookies.accessUUID
    const documentNumber = useAppSelector(selectDocumentNumber)
    const [clickedClipboard, setClickedClipboard] = useState(false)

    const _returnPaymentURL = () => {
        if(agreementPayment){
            return agreementPayment?.paymentInfo.url
        }else{
            return null
        }

    }

    const _returnPaymentButtonLabel = () => {
        if(agreementPayment){
            if(agreementPayment?.paymentMethod == 'BANKSLIP'){
                return(
                    <>
                        <img
                            className="w-6 h-6 mr-3"
                            src="/media/img/download_icon.png">
                        </img>

                        Download do boleto
                    </>
                )
            }else{
                return(
                    <>
                        <img
                            className="w-6 h-6 mr-3"
                            src="/media/img/dollar_icon.png">
                        </img>

                        Efetuar pagamento
                    </>
                )
            }
        }else{
            return(
                <>
                    <LoadingComponent></LoadingComponent>
                </>
            )
        }

    }

    const _returnBankslipBarCodeButton = () => {
        if(agreementPayment?.paymentMethod == 'BANKSLIP' && agreementPayment.paymentInfo && agreementPayment.paymentInfo.digit_line){
            return(
                <>
                    {
                        clickedClipboard ?
                        <p className="font-base text-md text-gray-500 mt-1 text-center">Linha digitável copiado para sua área de transferência</p>
                        : null
                    }
                    <div
                        className='w-full md:w-6/12 flex justify-center items-center mt-2 cursor-pointer'
                        onClick={
                            () => {
                                navigator.clipboard.writeText(agreementPayment.paymentInfo.digit_line)
                                dispatch(postLogThunk(
                                    {
                                        event: 'PRESSED_PAYMENT_COPY_TO_CLIPBOARD_SUCCESS_PAGE',
                                        documentNumber: documentNumber,
                                        formData: null,
                                        details: {
                                            agreementPayment: agreementPayment
                                        },
                                        accessUUID: accessUUID
                                    }
                                ))
                                setClickedClipboard(true)
                            }
                        }
                        >
                        <div className=
                        {`bg-green-500 w-full h-12
                        font-bold text-lg text-gray-100 flex
                        items-center justify-center rounded-lg
                        hover:bg-green-600
                        `
                        }
                        >
                            <img
                                className="w-8 h-8 mr-2"
                                src="/media/img/barcode.png">
                            </img>
                            Copiar linha digitável
                        </div>
                    </div>
                </>

            )
        } else if(agreementPayment?.paymentMethod == 'PIX' && agreementPayment.paymentInfo && agreementPayment.paymentInfo.qr_code){
            return(
                <>
                    {
                        clickedClipboard ?
                        <p className="font-base text-md text-gray-500 text-center">Pix copiado para sua área de transferência</p>
                        : null
                    }
                    <div
                        className='w-full md:w-6/12 flex justify-center items-center mt-2 cursor-pointer'
                        onClick={
                            () => {
                                navigator.clipboard.writeText(agreementPayment.paymentInfo.qr_code)
                                dispatch(postLogThunk(
                                    {
                                        event: 'PRESSED_PAYMENT_COPY_TO_CLIPBOARD_SUCCESS_PAGE',
                                        documentNumber: documentNumber,
                                        formData: null,
                                        details: {
                                            agreementPayment: agreementPayment
                                        },
                                        accessUUID: accessUUID
                                    }
                                ))
                                setClickedClipboard(true)
                            }
                        }
                        >
                        <div className=
                        {`bg-green-500 w-full h-12
                        font-bold text-lg text-gray-100 flex
                        items-center justify-center rounded-lg
                        hover:bg-green-600
                        `
                        }
                        >
                            <img
                                className="w-8 h-8 mr-2"
                                src="/media/img/barcode.png">
                            </img>
                            Copiar código pix
                        </div>
                    </div>
                </>

            )
        } else {
            return null
        }
    }

    const _returnCADButton = () => {
        if(cadURL && cadURL != ''){
            return(
                <div className="w-full flex items-center justify-center mt-2 ">
                    <a
                        className='w-full md:w-6/12 flex justify-center items-center'
                        onClick={() => {
                            dispatch(postLogThunk(
                                {
                                    event: 'PRESSED_CAD_BUTTON_SUCCESS_PAGE',
                                    documentNumber: documentNumber,
                                    formData: null,
                                    details: {
                                        cadURL: cadURL
                                    },
                                    accessUUID: accessUUID
                                }
                            ))
                        }}
                        href={cadURL} target="_blank">
                        <div className="
                        bg-blue-600 w-full h-12
                        font-bold text-base text-gray-100 flex
                        items-center justify-center rounded-lg
                        hover:bg-blue-500"
                        >

                            <img
                                className="w-6 h-6 mr-2"
                                src="/media/img/download_icon.png">
                            </img>

                            Certificado de Aceite Digital

                        </div>
                    </a>
                </div>
            )
        }
    }

    const _returnTCDButton = () => {
        if(tcdURL && tcdURL != ''){
            return(
                <div className="w-full flex items-center justify-center mt-2 ">
                    <a
                        className='w-full md:w-6/12 flex justify-center items-center'
                        onClick={() => {
                            dispatch(postLogThunk(
                                {
                                    event: 'PRESSED_TCD_BUTTON_SUCCESS_PAGE',
                                    documentNumber: documentNumber,
                                    formData: null,
                                    details: {
                                        tcdURL: tcdURL
                                    },
                                    accessUUID: accessUUID
                                }
                            ))
                        }}
                        href={tcdURL} target="_blank">
                        <div className="
                        bg-blue-600 w-full h-12
                        font-bold text-lg text-gray-100 flex
                        items-center justify-center rounded-lg
                        hover:bg-blue-500"
                        >

                            <img
                                className="w-6 h-6 mr-2"
                                src="/media/img/download_icon.png">
                            </img>

                            Termo de dívida

                        </div>
                    </a>
                </div>
            )
        }
    }

    return(
        <div className="w-full flex flex-col px-5 md:px-8 py-8">
            <p className="font-bold text-center text-3xl text-[#35b048]">
                Parabéns! Acordo efetuado.
            </p>
            <p className="font-light  text-center text-xl text-gray-700">
                Em instantes você receberá um e-mail com detalhes do seu acordo!
                Se preferir, selecione uma das opções abaixo.
            </p>
            <div className="w-full flex items-center justify-center mt-8 flex-col">
                {
                    agreementPayment?.paymentMethod == 'PIX' ? (
                        <>
                            <div
                                className='w-full md:w-6/12 flex flex-col justify-center items-center'
                            >
                                <p className="mb-1 font-medium text-base">
                                    QR CODE PIX
                                </p>
                                <img
                                    src={agreementPayment.paymentInfo.url}
                                    className="h-50 w-50"
                                />
                            </div>
                            {
                                _returnBankslipBarCodeButton()
                            }
                        </>
                    ) : (
                        <>
                            <a
                                className='w-full md:w-6/12 flex justify-center items-center'
                                onClick={
                                    () => {
                                        dispatch(postLogThunk(
                                            {
                                                event: 'PRESSED_PAYMENT_BUTTON_SUCCESS_PAGE',
                                                documentNumber: documentNumber,
                                                formData: null,
                                                details: {
                                                    agreementPayment: agreementPayment
                                                },
                                                accessUUID: accessUUID
                                            }
                                        ))
                                    }
                                }
                                href={_returnPaymentURL()} target="_blank">
                                <div className=
                                {`bg-green-500 w-full h-12
                                font-bold text-lg text-gray-100 flex
                                items-center justify-center rounded-lg
                                hover:bg-green-600
                                `
                                }
                                >
                                    {
                                        _returnPaymentButtonLabel()
                                    }
                                </div>
                            </a>
                            {
                                _returnBankslipBarCodeButton()
                            }
                        </>
                    )
                }
            </div>

            {_returnTCDButton()}

            <div className="w-full flex items-center justify-center mt-4 ">
                <a
                    className='w-full md:w-6/12 flex justify-center items-center'
                    target="_blank"
                    onClick={() => {

                        navigate('/contratos')
                    }}
                    >
                    <div className="
                    bg-gray-300 w-full h-12
                    font-bold text-lg text-gray-600 flex
                    items-center justify-center rounded-lg
                    hover:bg-gray-400 cursor-pointer"
                    >
                        <img
                            className="w-6 h-6 mr-2"
                            src="/media/img/return_icon.png">
                        </img>

                        Retornar para o início
                    </div>
                </a>
            </div>
        </div>
    )
}
