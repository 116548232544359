import { Limits, PreAgreement, PreAgreementInstallment} from "../../entities/PreAgreement/PreAgreement"


type TPreAgreementInstallmentDTO = {
    due_date: string,
    installment_number: number,
    initial_value: number,
    fee: number,
    fine: number,
    taxes: number,
    interest: number,
    current_value: number
}

type TPreAgreementDTO = {
    uuid: string,
    original_receivable_value: number,
    fee: number, 
    interest: number,
    fine: number,
    taxes: number,
    current_value: number,
    discount: number,
    downpayment_value: number,
    payment_option: string,
    negotiation_option_uuid: string,
    installments: TPreAgreementInstallmentDTO[],
    expire_at: string
}

export type TPreAgreementResponse = {
    payload: {
        preagreement: TPreAgreementDTO
    },
    aggregates: {
        original_receivables: string[]
    },
    error: any
}

const preAgreementConverter = (dto: TPreAgreementResponse): PreAgreement => {
    const pre = dto.payload.preagreement

        
    return {
            type: "PreAgreement",
            uuid: pre.uuid,
            originalReceivableValue: pre.original_receivable_value,
            fee: pre.fee,
            interest: pre.interest,
            fine: pre.fine,
            taxes: pre.taxes,
            currentValue: pre.current_value,
            discount: pre.discount,
            downpayment: pre.downpayment_value,
            paymentOption: pre.payment_option,
            installments: pre.installments.map(installment => (
                {
                    type: 'PreAgreementInstallment',
                    dueDate: installment.due_date,
                    installmentNumber: installment.installment_number,
                    initialValue: installment.initial_value,
                    fee: installment.fee,
                    fine: installment.fine,
                    taxes: installment.taxes,
                    interest: installment.interest,
                    currentValue: installment.current_value
                } as PreAgreementInstallment
            ))
    } as PreAgreement
}


export type TLimitsResponse = {
    payload: {
        uuid: string;
        available_dates: string[];
        installments_info: number[]
    },
    error: any
}


export const limitsConverter = (dto: TLimitsResponse): Limits => {
    const limits = dto.payload

    return {
        type: 'Limits',
        uuid: limits.uuid,
        availableDates: limits.available_dates,
        installmentsInfo: limits.installments_info
    } as Limits
}


export default preAgreementConverter;

