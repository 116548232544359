import React from "react";
import { CreditorListHeaderType } from "./interfaces";
import { maskDocumentNumber } from "../../../utils/formatters/DocumentNumber";

export const CreditorsPanelHeader = ({client} : CreditorListHeaderType) => {
    return (
        <div className="w-full flex flex-col items-center justify-center mt-8 mb-14">
            <p className="font-bold text-center text-2xl text-gray-700">{client.name}</p>
            <p className="font-bold text-center text-xl text-gray-600">{`CPF: ${maskDocumentNumber(client.documentNumber).masked}`}</p>
        </div>
    )
}