import tw from "tailwind-styled-components";

export const BackgroundPanelPages = tw.div`
    bg-gray-100
    w-full
    h-full
    flex
    flex-col
    items-center
    overflow-auto
`

export const PanelPagesContentContainer = tw.div`
    w-full
    md:w-6/12 
    h-auto
    px-4
`

export const NegotiationPageContentContainer = tw.div`
    w-full
    lg:w-full
    xl:w-8/12 
    2xl:w-7/12
    h-auto
    px-4
    flex
    items-center
    justify-center
`

export const SuccessPageContentContainer = tw.div`
    w-full
    md:w-5/12 
    h-auto
    px-6
`