import { Receivable } from "../Receivable/Receivable"
import { NegotiationOption } from "../NegotiationOption/NegotiationOption"
import { Agreement } from "./Agreement"


interface OriginalReceivablesProps {
    agreement: Agreement,
    receivables: Receivable[]
}   

export const getOriginalReceivablesFromAgreement = ({agreement, receivables}: OriginalReceivablesProps) => {
    if(agreement.originalReceivablesUUID){
        const selectedReceivables = agreement.originalReceivablesUUID.map((uuid) => {
            const foundReceivables = receivables.filter((receivable) => receivable.uuid == uuid)
            return(foundReceivables[0])
        })
        return selectedReceivables
    }else{
        return []
    }
    
    
}


interface CurrentReceivableProps {
    agreement: Agreement,
    receivables: Receivable[]
}   

export const getCurrentReceivableFromAgreement = ({agreement, receivables}: CurrentReceivableProps) => {
    const foundReceivables = receivables.filter((receivable) => receivable.uuid == agreement.currentReceivableUUID)
    return(foundReceivables[0])

}