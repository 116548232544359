import tw from 'tailwind-styled-components';

export const AgreementCardTable = tw.table`
    text-md
    font-semibold
    w-full
`

export const AgreementCardTableHeader = tw.thead`
    font-bold 
    text-left
    hidden
    md:contents
`

export const AgreementCardTableHead = tw.th`
    font-bold 
    text-center
    text-lg
    text-gray-700
`

export const AgreementCardTableCell = tw.td`
    px-2
    text-center
    font-medium
    text-gray-600
    border-0
    py-1
`

export const AgreementCardTableBody = tw.tbody`
`

export const AgreementCardTableRow = tw.tr`
    flex 
    flex-col 
    md:table-row
    py-0
    my-0
    border-b
    md:border-none
`