import React from "react"
import {
     MainBannerContainer,
     BannerContainer,
     Container,
     FormContainer,
     FormTitle,
     FormLabel,
} from "./styled"
import BackgroundCircles from "./BackgroundCircles"


export interface Props {
    backgroundColor: string
    children: any
}

export const defaultValues = {
    backgroundColor: "#F29129"
}

const MainBanner = ({
    children,
    backgroundColor=defaultValues.backgroundColor,
}: Props) => {
    return(
        <MainBannerContainer>
            <Container>
                <BannerContainer style={{backgroundColor: backgroundColor}} className='relative'>
                    <BackgroundCircles />
                    <FormContainer>
                        <FormTitle 
                            style={{color:'#FFFFFF', 'textDecorationColor':'#B8E4FF', 'fontWeight': 570, 'textAlign': 'left'}} 
                            className='mb-[15px] px-5 md:px-0 md:mb-4 justify-left text-5xl md:text-5xl'
                        >
                            Bem-vindo(a) ao seu portal digital de negociação!
                        </FormTitle>
                        <FormLabel style={{color:'#FAFAFA'}} className='mb-6 px-5 md:px-0 text-2xl md:text-2xl'>
                            Aqui você encontra condições especiais para negociar suas pendências.
                        </FormLabel>
                        {children}
                    </FormContainer>
                </BannerContainer>
            </Container>
        </MainBannerContainer>
    )
}

export default MainBanner;

