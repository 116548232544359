import React from "react";
import { AgreementCardTableButtonType } from "../interfaces";
import { useAppDispatch, useAppSelector } from "../../../../../NegotiationPortal/hooks";
import { getReceivableListPaymentThunk } from "../../../../../NegotiationPortal/components/Receivable/ListPanel/receivableListSlice";
import { postLogThunk } from "../../../../../NegotiationPortal/components/logs/logsThunk";
import { selectDocumentNumber } from "../../../AuthForm/authFormSlice";
import { useCookies } from "react-cookie";

const HistoryCardTableButton = ({
    installment,
    downpayment,
    paymentsLoading,
    receivable
} : AgreementCardTableButtonType) => {

    const dispatch = useAppDispatch()
    const documentNumber = useAppSelector(selectDocumentNumber)
    const [cookies] = useCookies(['accessUUID']);
    const accessUUID = cookies.accessUUID
    
    const _handleGetPayment = (receivableUUID: string, installmentNumber: number) => {
        dispatch(getReceivableListPaymentThunk({receivableUUID, installmentNumber}))
    }

    const isNegotiable = () => {
        if(installment && receivable.installments){
            const installmentNumber = installment.installmentNumber
            if(installmentNumber <= 1){
                return true
            }else{
                let previousInstallment = receivable.installments.filter((installment) => installment.installmentNumber == installmentNumber -1)
                if(previousInstallment.length > 0 && previousInstallment[0].status == 'PAID'){
                    return true
                }else{
                    return false
                }
            }
        }
        
    }

    if(installment && isNegotiable()){
        switch(installment.status){
            case 'PAID':
                return(
                    <a>Pago</a>
                )
            case 'SETTLED':
                return(
                    <a>Pago</a>
                )
            case 'CANCELLED':
                return(
                    <a>Cancelado</a>
                )
            default:
                return null 
            
        }
    }else if(!isNegotiable()){
        return(
            <div
                className=" cursor-pointer w-auto max-w-full text-base font-medium bg-gray-400 hover:bg-gray-400 rounded px-5 py-1 text-white">
                -
            </div>
        )
    }else{
        if(downpayment && downpayment.payment && !downpayment.payment.paymentDate){
            return(
                <a 
                    href={downpayment.payment.paymentInfo.url}
                    target="_blank"
                    download
                    className="w-auto max-w-full text-base font-medium bg-[#5374CC] hover:bg-gray-500 rounded px-5 py-1 text-white">
                    Gerar boleto
                </a>
            )
        }else if(downpayment && downpayment.payment && downpayment.payment.paymentDate){
            return <a>Pago</a>
        }else{
            return null
        }
    }
    
}

export default HistoryCardTableButton;