import { Agreement } from "../../../../common/entities/Agreement/Agreement"
import { NegotiationOption } from "../../../../common/entities/NegotiationOption/NegotiationOption"
import { Product } from "../../../../common/entities/Product/Product"
import { Installment } from "../../../../common/entities/Receivable/Installment"
import { Wallet } from "../../../../common/entities/Wallet/Wallet"
import { Creditor } from "../../../../common/entities/Creditor/Creditor"
import { TAgreementResponse } from "../../../../common/gateways/converters/agreementConverter"

export const Mocks = {
    creditor: {
        type: "Creditor",
        uuid:'string',
        name:'Unopar',
        logo:'https://storage.googleapis.com/kittou-logos/logotipos/digicob/kroton/krotoncarrossellogounopar20140918.jpg',
        wallets:[],
    } as Creditor,
    creditor2: {
        type: "Creditor",
        uuid:'string2',
        name:'Anhanguera',
        logo:'https://storage.googleapis.com/kittou-logos/logotipos/digicob/kroton/krotoncarrossellogoanhanguera20140918.jpg',
        wallets:[],
    } as Creditor,
    wallet:{
        type: "Wallet",
        uuid: 'W1',
        name: 'Ativos',
        creditor: {
            type: "Creditor",
            uuid:'string',
            name:'Unopar',
            logo:'https://storage.googleapis.com/kittou-logos/logotipos/digicob/kroton/krotoncarrossellogounopar20140918.jpg',
            partner: {
                type: 'Partner',
                uuid: 'string',
                name: 'Kroton',
                logo: 'https://storage.googleapis.com/kittou-logos/logotipos/digicob/kroton/krotoncarrossellogounopar20140918.jpg',
                documentNumber: '12345656892',
                creditors: [],
                email: 'kroton@gmail.com',
            }
        },
    } as Wallet,
    wallet2:{
        type: "Wallet",
        uuid: 'W2',
        name: 'Ativos',
        creditor: {
            type: "Creditor",
            uuid:'string2',
            name:'Anhanguera',
            logo:'https://storage.googleapis.com/kittou-logos/logotipos/digicob/kroton/krotoncarrossellogoanhanguera20140918.jpg',
            partner: {
                type: 'Partner',
                uuid: 'string',
                name: 'Kroton',
                logo: 'https://storage.googleapis.com/kittou-logos/logotipos/digicob/kroton/krotoncarrossellogounopar20140918.jpg',
                documentNumber: '12345656892',
                creditors: [],
                email: 'kroton@gmail.com',
            }
        },
    } as Wallet,
    negotiationOptions: [
        {
            type: "NegotiationOption",
            uuid:'1',
            description: 'Adimplere Test Mock: Kroton',
            obligatoryReceivablesUUID: ['R1', 'R2', 'R3'],
            negotiationOptionItems: [{
                uuid: '12345656',
                receivableUUID: 'R4',
                groupname: 'MENS',
                groupCounter: 7,
                extraDomain: {
                    type: 'raid',
                    data: {
                        remat: true
                    }
                }
            }],
            numberOfInstallments: 11,
            paymentMethods: ['CREDIT_CARD', 'BANKSLIP'],
            creditor: {
                name: 'ADIMPLERE TEST',
                logo: 'https://adimplere.com.br/wp-content/uploads/2022/08/logo-azul.webp'
            },
            availablePaymentDates: ['2023-04-18', '2023-04-19']
        } as NegotiationOption,
    ],
    agreements: [
        {
            type: "Agreement",
            uuid:'A1',
            originalReceivablesUUID: ['R1'],
            currentReceivableUUID: 'R3',
            emissionDate: '2023-04-18'
        } as Agreement,
        {
          type: "Agreement",
          uuid:'A2',
          originalReceivablesUUID: ['R1'],
          currentReceivableUUID: 'R6',
          emissionDate: '2023-04-18'
        } as Agreement,
        {
          type: "Agreement",
          uuid:'A3',
          originalReceivablesUUID: ['R1'],
          currentReceivableUUID: 'R7',
          emissionDate: '2023-04-18'
        } as Agreement,
        {
          type: "Agreement",
          uuid:'A4',
          originalReceivablesUUID: ['R1'],
          currentReceivableUUID: 'R8',
          emissionDate: '2023-04-18'
        } as Agreement
    ],
    receivables: [
        {
            uuid: 'R1',
            type: "Receivable",
            originalReceivableValue: 0,
            product: {
                type: "Product",
                name: 'Fatura 10-11-2022',
                description: 'Mensalidades de 2023'
            } as Product,
            fee: 0,
            fine: 0,
            interest: 0,
            taxes: 0,
            currentValue: 1000,
            status: 'PENDING',
            downpayment: {
                value: 0, 
                payment: {
                    paymentDate: '2023-05-05',
                    paidValue: 0,
                    paymentMethod: '',
                    paymentInfo: ''
                }
            },
            installments: [
                { 
                    type: "Installment",
                    uuid: 'I1',
                    installmentNumber: 0,
                    fee: 0,
                    fine: 0,
                    interest: 0,
                    taxes: 0,
                    initialValue: 0,
                    currentValue: 0,
                    dueDate: '2023-05-05',
                    status: 'PENDING',
                    payment: {
                        type: "Payment",
                        paymentDate: '2023-05-05',
                        paidValue: 0,
                        paymentMethod: '',
                        paymentInfo: ''
                    }
                } as Installment
            ]
        },
        {
            type: "Receivable",
            uuid: 'R2',
            originalReceivableValue: 0,
            product: {
                type: "Product",
                name: 'Fatura 10-12-2022',
                description: 'Mensalidades de 2023'
            } as Product,
            fee: 0,
            fine: 0,
            interest: 0,
            taxes: 0,
            currentValue: 1000,
            status: 'PENDING',
            downpayment: {
                value: 0, 
                payment: {
                    paymentDate: '2023-05-05',
                    paidValue: 0,
                    paymentMethod: '',
                    paymentInfo: ''
                }
            },
            installments: [
                {
                    type: "Installment",
                    uuid: 'I2',
                    installmentNumber: 0,
                    fee: 0,
                    fine: 0,
                    interest: 0,
                    taxes: 0,
                    initialValue: 0,
                    currentValue: 0,
                    dueDate: '2023-05-05',
                    status: 'PENDING',
                    payment: {
                        paymentDate: '2023-05-05',
                        paidValue: 0,
                        paymentMethod: '',
                        paymentInfo: '',
                    }
                } as Installment
            ]
        },
        {
            uuid: 'R3',
            originalReceivableValue: 0,
            product: {
                type: "Product",
                name: 'Fatura 10-01-2023',
                description: 'Mensalidades de 2023'
            } as Product,
            fee: 0,
            fine: 0,
            interest: 0,
            taxes: 0,
            currentValue: 1000,
            status: 'PENDING',
            downpayment: null,
            installments: [
                {
                    type: "Installment",
                    uuid: 'I3',
                    installmentNumber: 1,
                    fee: 0,
                    fine: 0,
                    interest: 0,
                    taxes: 0,
                    initialValue: 0,
                    currentValue: 0,
                    dueDate: '2023-05-05',
                    status: 'OPENED',
                    payment: null
                } as Installment
            ]
        },
        {
            uuid: 'R4',
            originalReceivableValue: 0,
            product: {
                type: "Product",
                name: 'Rematrícula',
                description: 'Mensalidades de 2023'
            } as Product,
            fee: 0,
            fine: 0,
            interest: 0,
            taxes: 0,
            currentValue: 1000,
            status: 'PENDING',
            downpayment: {
                value: 0, 
                payment: {
                    paymentDate: '2023-05-05',
                    paidValue: 0,
                    paymentMethod: '',
                    paymentInfo: ''
                }
            },
            installments: [
                {
                    type: "Installment",
                    uuid: 'I4',
                    installmentNumber: 0,
                    fee: 0,
                    fine: 0,
                    interest: 0,
                    taxes: 0,
                    initialValue: 0,
                    currentValue: 0,
                    dueDate: '2023-05-05',
                    status: 'PENDING',
                    payment: {
                        paymentDate: '2023-05-05',
                        paidValue: 0,
                        paymentMethod: '',
                        paymentInfo: ''
                    }
                } as Installment
            ]
        },
       {
            uuid: 'R5',
            originalReceivableValue: 0,
            product: {
                type: "Product",
                name: 'Fatura de 10-10-2010',
                description: 'Mensalidades de 2010'
            } as Product,
            fee: 0,
            fine: 0,
            interest: 0,
            taxes: 0,
            currentValue: 1000,
            status: 'NEGOTIATED',
            downpayment: {
                value: 0, 
                payment: {
                    paymentDate: '',
                    paidValue: 0,
                    paymentMethod: '',
                    paymentInfo: ''
                }
            },
            installments: [
                {
                    type: "Installment",
                    uuid: 'I4',
                    installmentNumber: 0,
                    fee: 0,
                    fine: 0,
                    interest: 0,
                    taxes: 0,
                    initialValue: 0,
                    currentValue: 0,
                    dueDate: '2010-10-10',
                    status: 'PENDING',
                    payment: {
                        paymentDate: '',
                        paidValue: 0,
                        paymentMethod: '',
                        paymentInfo: ''
                    }
                } as Installment
            ]
        },
       {
            uuid: 'R6',
            originalReceivableValue: 0,
            product: {
                type: "Product",
                name: 'Acordo',
                description: ''
            } as Product,
            fee: 0,
            fine: 0,
            interest: 0,
            taxes: 0,
            currentValue: 1000,
            status: 'OPENED',
            downpayment: {
                value: 0, 
                payment: {
                    paymentDate: '2023-03-05',
                    paidValue: 100,
                    paymentMethod: 'BANKSLIP',
                    paymentInfo: ''
                }
            },
            installments: [
                {
                    type: "Installment",
                    uuid: 'I4',
                    installmentNumber: 0,
                    fee: 0,
                    fine: 0,
                    interest: 0,
                    taxes: 0,
                    initialValue: 0,
                    currentValue: 0,
                    dueDate: '2023-04-05',
                    status: 'PAID',
                    payment: {
                        paymentDate: '2023-04-05',
                        paidValue: 100,
                        paymentMethod: 'BANKSLIP',
                        paymentInfo: ''
                    }
                } as Installment,
                {
                    type: 'Installment',
                    uuid: 'I5',
                    installmentNumber: 0,
                    fee: 0,
                    fine: 0,
                    interest: 0,
                    taxes: 0,
                    initialValue: 0,
                    currentValue: 0,
                    dueDate: '2023-05-05',
                    status: 'OPENED',
                    payment: {
                        paymentDate: '',
                        paidValue: 0,
                        paymentMethod: 'BANKSLIP',
                        paymentInfo: {
                          url: 'https://google.com.br',
                          digit_line: '232132412332'
                        }
                    }
                } as Installment,
                {
                  type: 'Installment',
                  uuid: 'I5',
                  installmentNumber: 0,
                  fee: 0,
                  fine: 0,
                  interest: 0,
                  taxes: 0,
                  initialValue: 0,
                  currentValue: 0,
                  dueDate: '2023-05-05',
                  status: 'OPENED',
                  payment: {
                      paymentDate: '',
                      paidValue: 0,
                      paymentMethod: 'BANKSLIP',
                      paymentInfo: {
                        url: 'https://google.com.br',
                        digit_line: '232132412332'
                      }
                  }
              } as Installment
            ]
        },
        {
          uuid: 'R7',
          originalReceivableValue: 0,
          product: {
              type: "Product",
              name: 'Acordo',
              description: ''
          } as Product,
          fee: 0,
          fine: 0,
          interest: 0,
          taxes: 0,
          currentValue: 1000,
          status: 'SETTLED',
          downpayment: {
              value: 0, 
              payment: {
                  paymentDate: '2023-03-05',
                  paidValue: 100,
                  paymentMethod: 'BANKSLIP',
                  paymentInfo: ''
              }
          },
          installments: [
              {
                  type: "Installment",
                  uuid: 'I4',
                  installmentNumber: 1,
                  fee: 0,
                  fine: 0,
                  interest: 0,
                  taxes: 0,
                  initialValue: 0,
                  currentValue: 256095,
                  dueDate: '2023-04-05',
                  status: 'PAID',
                  payment: {
                      paymentDate: '2023-04-05',
                      paidValue: 100,
                      paymentMethod: 'BANKSLIP',
                      paymentInfo: ''
                  }
              } as Installment
          ]
        },
        {
          uuid: 'R8',
          originalReceivableValue: 0,
          product: {
              type: "Product",
              name: 'Acordo',
              description: ''
          } as Product,
          fee: 0,
          fine: 0,
          interest: 0,
          taxes: 0,
          currentValue: 1000,
          status: 'CANCELLED',
          downpayment: {
              value: 0, 
              payment: {
                  paymentDate: '2023-03-05',
                  paidValue: 100,
                  paymentMethod: 'BANKSLIP',
                  paymentInfo: ''
              }
          },
          installments: [
              {
                  type: "Installment",
                  uuid: 'I4',
                  installmentNumber: 1,
                  fee: 0,
                  fine: 0,
                  interest: 0,
                  taxes: 0,
                  initialValue: 0,
                  currentValue: 55256,
                  dueDate: '2023-04-05',
                  status: 'PAID',
                  payment: {
                      paymentDate: '2023-04-05',
                      paidValue: 100,
                      paymentMethod: 'BANKSLIP',
                      paymentInfo: ''
                  }
              } as Installment,
              {
                  type: 'Installment',
                  uuid: 'I5',
                  installmentNumber: 2,
                  fee: 0,
                  fine: 0,
                  interest: 0,
                  taxes: 0,
                  initialValue: 0,
                  currentValue: 15520,
                  dueDate: '2023-05-05',
                  status: 'CANCELLED',
                  payment: {
                      paymentDate: '',
                      paidValue: 0,
                      paymentMethod: 'BANKSLIP',
                      paymentInfo: {
                        url: 'https://google.com.br',
                        digit_line: '232132412332'
                      }
                  }
              } as Installment
          ]
        },
        {
          uuid: 'd490a081-4da8-45ce-8512-5236ab221cb8',
          originalReceivableValue: 100000,
          product: {
              type: "Product",
              name: 'Acordo',
              description: ''
          } as Product,
          fee: 0,
          fine: 0,
          interest: 0,
          taxes: 0,
          currentValue: 1000,
          status: 'OPENED',
          downpayment: {
              value: 0, 
              payment: {
                  paymentDate: '2023-03-05',
                  paidValue: 100,
                  paymentMethod: 'BANKSLIP',
                  paymentInfo: ''
              }
          },
          installments: [
              {
                  type: "Installment",
                  uuid: 'I4',
                  installmentNumber: 0,
                  fee: 0,
                  fine: 0,
                  interest: 0,
                  taxes: 0,
                  initialValue: 0,
                  currentValue: 0,
                  dueDate: '2023-04-05',
                  status: 'PAID',
                  payment: {
                      paymentDate: '2023-04-05',
                      paidValue: 100,
                      paymentMethod: 'BANKSLIP',
                      paymentInfo: ''
                  }
              } as Installment,
              {
                  type: 'Installment',
                  uuid: 'I5',
                  installmentNumber: 0,
                  fee: 0,
                  fine: 0,
                  interest: 0,
                  taxes: 0,
                  initialValue: 0,
                  currentValue: 0,
                  dueDate: '2023-05-05',
                  status: 'OPENED',
                  payment: {
                      paymentDate: '',
                      paidValue: 0,
                      paymentMethod: '',
                      paymentInfo: ''
                  }
              } as Installment
          ]
      },
    ],
    paymentBankslipResponse: {
            "payload": {
                "payment": {
                    "downpayment": true,
                    "installment_uuid": "3558c19d-3774-4f8a-8018-1d71124732d9",
                    "payment_date": "2023-01-21",
                    "paid_value": 164000,
                    "payment_method": "BANKSLIP",
                    "payment_info": {
                        "digit_line": "37Y37EHHjd8e448r4uhrf84r84hr84rj84ijr04rj49",
                        "due_date": "2023-01-21",
                        "url": "https://storage.s3/57e1d9b1-3ffb-4221-bad9-9bdb1ef3c0da",
                    },
                },
            "errors": null
        }
    },
    paymentCreditCardResponse: {
            "payload": {
                "payment": {
                    "downpayment": false,
                    "installment_uuid": "3558c19d-3774-4f8a-8018-1d71124732d9",
                    "payment_date": "2023-01-21",
                    "paid_value": 164000,
                    "payment_method": "CREDIT_CARD",
                    "payment_info": {
                        "due_date": "2023-01-21",
                        "url": "https://storage.s3/57e1d9b1-3ffb-4221-bad9-9bdb1ef3c0da",
                    },
                },
            "errors": null
        }
    },
    paymentPixResponse: {
            "payload": {
                "payment": {
                    "downpayment": false,
                    "installment_uuid": "3558c19d-3774-4f8a-8018-1d71124732d9",
                    "payment_date": "2023-01-21",
                    "paid_value": 164000,
                    "payment_method": "PIX",
                    "payment_info": {
                        "due_date": "2023-01-21",
                        "url": "https://ps.w.org/doqrcode/assets/icon-256x256.png?rev=2143781",
                        "qr_code": "onepieceisthebestanimeever"
                    },
                },
            "errors": null
        }
    },
    getNegotiationOptionsResponse: {
        "payload": {
            "negotiation_options": [
                {
                    "uuid": "50beffda-9099-4bcf-ab56-aa1ea92a97d6",
                    "description": "OLIMPO-1722956",
                    "creditor_uuid": "ecb0ec66-9fd4-451a-828c-03171489774e",
                    "available_dates": [
                        "2023-05-11",
                        "2023-05-12"
                    ],
                    "payment_methods": ['BANKSLIP', 'PIX', 'CREDIT_CARD'],
                    "number_of_installments": 12,
                    "obligatory_receivables_uuids": [
                        "0112777d-9bbc-4e48-94bc-c84b6472932a",
                        "6e3896ca-3837-4063-961d-c1c1805725bb",
                        "a3be8ac4-a53f-4ed0-85bc-aee536faf7ad",
                        "bcb89a67-e3e8-48e7-8e3d-fdc0c07bda5b",
                        "08b025b2-c2bd-450f-a647-c3ba15ed964d",
                        "994364c0-8deb-4a34-8e9a-4984bb282fd5",
                        "e0d05505-b6b0-4547-a8b5-5fbc5a85837f",
                        "d83f9110-762b-4a7c-ab57-98647a5592a2",
                        "a4209e86-9059-4a06-a951-9ac56acf1470",
                        "837b05fc-a16c-4219-8b8e-80858d76ccde",
                        "692da665-af79-4af9-9715-02b0047b50e6",
                        "58e7432e-f2f2-4861-ac1f-0e7011ec3341",
                        "793f5b15-57c5-4c40-ad89-e0643fcc9d95",
                        "cda86018-9d48-4526-a956-f5e886f5ca67"
                    ],
                    "negotiation_option_items_uuids": [
                        "cbaf4fd8-3f18-4a7f-8b2d-10f817cbdd06",
                        "8d1ea98d-7270-4ac5-b4f8-3f1decfc5c16",
                        "47f0f2b7-58b7-4fce-80e4-82d618159b6a",
                        "e675bf85-1e9c-4aff-82c4-072ebed66646",
                        "52237a46-dc6a-4979-ac76-8279f93d5927",
                        "aea31f0b-2d30-42dd-bf89-228135c335b1"
                    ]
                }
            ],
            "aggregates": {
                "options_items": {
                    "cbaf4fd8-3f18-4a7f-8b2d-10f817cbdd06": {
                        "uuid": "cbaf4fd8-3f18-4a7f-8b2d-10f817cbdd06",
                        "receivable": {
                            "uuid": "d490a081-4da8-45ce-8512-5236ab221cb8",
                            "original_receivable_value": 170027,
                            "product": {
                                "name": "Agronomia - Bacharelado",
                                "description": "012015"
                            },
                            "fee": 0,
                            "interest": 0,
                            "fine": 0,
                            "taxes": 0,
                            "current_value": 170027,
                            "downpayment": {
                                "value": 170027,
                                "payment": null
                            },
                            "installments": [
                                {
                                    "uuid": "ddcb7407-d8c9-4ce3-8a14-0020b0427ca3",
                                    "installment_number": 1,
                                    "initial_value": 170027,
                                    "fee": 0,
                                    "interest": 0,
                                    "fine": 0,
                                    "taxes": 0,
                                    "current_value": 170027,
                                    "due_date": "2015-11-03",
                                    "payment": null,
                                    "status": "PENDING"
                                }
                            ],
                            "status": "PENDING"
                        },
                        "payment_methods": [],
                        "name": "MENS",
                        "counter": 9,
                        "extra_domain": {
                            "type": "raid",
                            "data": {
                                "remat": true
                            }
                        },
                        "receivable_uuid": "d490a081-4da8-45ce-8512-5236ab221cb8"
                    },
                    "8d1ea98d-7270-4ac5-b4f8-3f1decfc5c16": {
                        "uuid": "8d1ea98d-7270-4ac5-b4f8-3f1decfc5c16",
                        "receivable": {
                            "uuid": "3dc05845-c2a0-4c98-a3ab-d36eca50a1f9",
                            "original_receivable_value": 170027,
                            "product": {
                                "name": "Agronomia - Bacharelado",
                                "description": "022015"
                            },
                            "fee": 0,
                            "interest": 0,
                            "fine": 0,
                            "taxes": 0,
                            "current_value": 170027,
                            "downpayment": {
                                "value": 170027,
                                "payment": null
                            },
                            "installments": [
                                {
                                    "uuid": "43f54c1c-394f-4dcd-bcf4-ec59fd722edf",
                                    "installment_number": 1,
                                    "initial_value": 170027,
                                    "fee": 0,
                                    "interest": 0,
                                    "fine": 0,
                                    "taxes": 0,
                                    "current_value": 170027,
                                    "due_date": "2015-11-03",
                                    "payment": null,
                                    "status": "PENDING"
                                }
                            ],
                            "status": "PENDING"
                        },
                        "payment_methods": [],
                        "name": "MENS",
                        "counter": 10,
                        "extra_domain": {
                            "type": "raid",
                            "data": {
                                "remat": false
                            }
                        },
                        "receivable_uuid": "3dc05845-c2a0-4c98-a3ab-d36eca50a1f9"
                    },
                    "47f0f2b7-58b7-4fce-80e4-82d618159b6a": {
                        "uuid": "47f0f2b7-58b7-4fce-80e4-82d618159b6a",
                        "receivable": {
                            "uuid": "3438ba09-48dd-4895-924b-83f3c74b960d",
                            "original_receivable_value": 170027,
                            "product": {
                                "name": "Agronomia - Bacharelado",
                                "description": "032015"
                            },
                            "fee": 0,
                            "interest": 0,
                            "fine": 0,
                            "taxes": 0,
                            "current_value": 170027,
                            "downpayment": {
                                "value": 170027,
                                "payment": null
                            },
                            "installments": [
                                {
                                    "uuid": "36f7c982-d3de-4e17-9fe3-96b6c798f7ee",
                                    "installment_number": 1,
                                    "initial_value": 170027,
                                    "fee": 0,
                                    "interest": 0,
                                    "fine": 0,
                                    "taxes": 0,
                                    "current_value": 170027,
                                    "due_date": "2015-11-03",
                                    "payment": null,
                                    "status": "PENDING"
                                }
                            ],
                            "status": "PENDING"
                        },
                        "payment_methods": [],
                        "name": "MENS",
                        "counter": 11,
                        "extra_domain": {
                            "type": "raid",
                            "data": {
                                "remat": false
                            }
                        },
                        "receivable_uuid": "3438ba09-48dd-4895-924b-83f3c74b960d"
                    },
                    "e675bf85-1e9c-4aff-82c4-072ebed66646": {
                        "uuid": "e675bf85-1e9c-4aff-82c4-072ebed66646",
                        "receivable": {
                            "uuid": "8b86fae6-8c52-4d07-b2cc-f92291e35245",
                            "original_receivable_value": 170027,
                            "product": {
                                "name": "Agronomia - Bacharelado",
                                "description": "042015"
                            },
                            "fee": 0,
                            "interest": 0,
                            "fine": 0,
                            "taxes": 0,
                            "current_value": 170027,
                            "downpayment": {
                                "value": 170027,
                                "payment": null
                            },
                            "installments": [
                                {
                                    "uuid": "dab260f9-f8d9-4669-a10a-84c730429471",
                                    "installment_number": 1,
                                    "initial_value": 170027,
                                    "fee": 0,
                                    "interest": 0,
                                    "fine": 0,
                                    "taxes": 0,
                                    "current_value": 170027,
                                    "due_date": "2015-11-03",
                                    "payment": null,
                                    "status": "PENDING"
                                }
                            ],
                            "status": "PENDING"
                        },
                        "payment_methods": [],
                        "name": "MENS",
                        "counter": 12,
                        "extra_domain": {
                            "type": "raid",
                            "data": {
                                "remat": false
                            }
                        },
                        "receivable_uuid": "8b86fae6-8c52-4d07-b2cc-f92291e35245"
                    },
                    "52237a46-dc6a-4979-ac76-8279f93d5927": {
                        "uuid": "52237a46-dc6a-4979-ac76-8279f93d5927",
                        "receivable": {
                            "uuid": "2a4dfd66-5106-4a6c-b3bc-2db07d887bc1",
                            "original_receivable_value": 170027,
                            "product": {
                                "name": "Agronomia - Bacharelado",
                                "description": "052015"
                            },
                            "fee": 0,
                            "interest": 0,
                            "fine": 0,
                            "taxes": 0,
                            "current_value": 170027,
                            "downpayment": {
                                "value": 170027,
                                "payment": null
                            },
                            "installments": [
                                {
                                    "uuid": "6cfb468f-59a0-4723-8e08-4a317ed9e5eb",
                                    "installment_number": 1,
                                    "initial_value": 170027,
                                    "fee": 0,
                                    "interest": 0,
                                    "fine": 0,
                                    "taxes": 0,
                                    "current_value": 170027,
                                    "due_date": "2015-11-03",
                                    "payment": null,
                                    "status": "PENDING"
                                }
                            ],
                            "status": "PENDING"
                        },
                        "payment_methods": [],
                        "name": "MENS",
                        "counter": 13,
                        "extra_domain": {
                            "type": "raid",
                            "data": {
                                "remat": false
                            }
                        },
                        "receivable_uuid": "2a4dfd66-5106-4a6c-b3bc-2db07d887bc1"
                    },
                    "aea31f0b-2d30-42dd-bf89-228135c335b1": {
                        "uuid": "aea31f0b-2d30-42dd-bf89-228135c335b1",
                        "receivable": {
                            "uuid": "b0b72d5c-1e8d-4606-a079-8ebbc9971d00",
                            "original_receivable_value": 170027,
                            "product": {
                                "name": "Agronomia - Bacharelado",
                                "description": "062015"
                            },
                            "fee": 0,
                            "interest": 0,
                            "fine": 0,
                            "taxes": 0,
                            "current_value": 170027,
                            "downpayment": {
                                "value": 170027,
                                "payment": null
                            },
                            "installments": [
                                {
                                    "uuid": "b722fa1f-f77b-45ec-80fa-b2eb50bf4b2a",
                                    "installment_number": 1,
                                    "initial_value": 170027,
                                    "fee": 0,
                                    "interest": 0,
                                    "fine": 0,
                                    "taxes": 0,
                                    "current_value": 170027,
                                    "due_date": "2015-11-03",
                                    "payment": null,
                                    "status": "PENDING"
                                }
                            ],
                            "status": "PENDING"
                        },
                        "payment_methods": [],
                        "name": "MENS",
                        "counter": 14,
                        "extra_domain": {
                            "type": "raid",
                            "data": {
                                "remat": false
                            }
                        },
                        "receivable_uuid": "b0b72d5c-1e8d-4606-a079-8ebbc9971d00"
                    }
                },
                "receivables": {
                    "0112777d-9bbc-4e48-94bc-c84b6472932a": {
                        "original_receivable_value": 1600,
                        "product": {
                            "name": "Agronomia - Bacharelado",
                            "description": "102014"
                        },
                        "fee": 0,
                        "interest": 0,
                        "fine": 0,
                        "taxes": 0,
                        "current_value": 1600,
                        "downpayment": {
                            "value": 1600,
                            "payment": null
                        },
                        "installments": [
                            {
                                "uuid": "b99826f4-921d-46c9-820e-8967226f81f0",
                                "installment_number": 1,
                                "initial_value": 1600,
                                "fee": 0,
                                "interest": 0,
                                "fine": 0,
                                "taxes": 0,
                                "current_value": 1600,
                                "due_date": "2014-10-16",
                                "payment": null,
                                "status": "PENDING"
                            }
                        ],
                        "status": "PENDING"
                    },
                    "6e3896ca-3837-4063-961d-c1c1805725bb": {
                        "original_receivable_value": 800,
                        "product": {
                            "name": "Agronomia - Bacharelado",
                            "description": "102014"
                        },
                        "fee": 0,
                        "interest": 0,
                        "fine": 0,
                        "taxes": 0,
                        "current_value": 800,
                        "downpayment": {
                            "value": 800,
                            "payment": null
                        },
                        "installments": [
                            {
                                "uuid": "aaabb299-3852-462a-ac91-04a37e444707",
                                "installment_number": 1,
                                "initial_value": 800,
                                "fee": 0,
                                "interest": 0,
                                "fine": 0,
                                "taxes": 0,
                                "current_value": 800,
                                "due_date": "2014-10-16",
                                "payment": null,
                                "status": "PENDING"
                            }
                        ],
                        "status": "PENDING"
                    },
                    "a3be8ac4-a53f-4ed0-85bc-aee536faf7ad": {
                        "original_receivable_value": 1600,
                        "product": {
                            "name": "Agronomia - Bacharelado",
                            "description": "102014"
                        },
                        "fee": 0,
                        "interest": 0,
                        "fine": 0,
                        "taxes": 0,
                        "current_value": 1600,
                        "downpayment": {
                            "value": 1600,
                            "payment": null
                        },
                        "installments": [
                            {
                                "uuid": "5695b0f1-81c3-46cb-9e7a-fb1bf887e0d5",
                                "installment_number": 1,
                                "initial_value": 1600,
                                "fee": 0,
                                "interest": 0,
                                "fine": 0,
                                "taxes": 0,
                                "current_value": 1600,
                                "due_date": "2014-10-16",
                                "payment": null,
                                "status": "PENDING"
                            }
                        ],
                        "status": "PENDING"
                    },
                    "bcb89a67-e3e8-48e7-8e3d-fdc0c07bda5b": {
                        "original_receivable_value": 440,
                        "product": {
                            "name": "Agronomia - Bacharelado",
                            "description": "072014"
                        },
                        "fee": 0,
                        "interest": 0,
                        "fine": 0,
                        "taxes": 0,
                        "current_value": 440,
                        "downpayment": {
                            "value": 440,
                            "payment": null
                        },
                        "installments": [
                            {
                                "uuid": "9110e1b9-e23d-44c9-9ee0-25a1b04bd004",
                                "installment_number": 1,
                                "initial_value": 440,
                                "fee": 0,
                                "interest": 0,
                                "fine": 0,
                                "taxes": 0,
                                "current_value": 440,
                                "due_date": "2014-07-07",
                                "payment": null,
                                "status": "PENDING"
                            }
                        ],
                        "status": "PENDING"
                    },
                    "08b025b2-c2bd-450f-a647-c3ba15ed964d": {
                        "original_receivable_value": 750,
                        "product": {
                            "name": "Agronomia - Bacharelado",
                            "description": "082014"
                        },
                        "fee": 0,
                        "interest": 0,
                        "fine": 0,
                        "taxes": 0,
                        "current_value": 750,
                        "downpayment": {
                            "value": 750,
                            "payment": null
                        },
                        "installments": [
                            {
                                "uuid": "2d36bd3a-dc69-46b2-a147-67eeefbbc5b9",
                                "installment_number": 1,
                                "initial_value": 750,
                                "fee": 0,
                                "interest": 0,
                                "fine": 0,
                                "taxes": 0,
                                "current_value": 750,
                                "due_date": "2014-09-15",
                                "payment": null,
                                "status": "PENDING"
                            }
                        ],
                        "status": "PENDING"
                    },
                    "994364c0-8deb-4a34-8e9a-4984bb282fd5": {
                        "original_receivable_value": 2000,
                        "product": {
                            "name": "Agronomia - Bacharelado",
                            "description": "102014"
                        },
                        "fee": 0,
                        "interest": 0,
                        "fine": 0,
                        "taxes": 0,
                        "current_value": 2000,
                        "downpayment": {
                            "value": 2000,
                            "payment": null
                        },
                        "installments": [
                            {
                                "uuid": "04576d7c-5b37-412a-90be-43b100a235a4",
                                "installment_number": 1,
                                "initial_value": 2000,
                                "fee": 0,
                                "interest": 0,
                                "fine": 0,
                                "taxes": 0,
                                "current_value": 2000,
                                "due_date": "2014-11-17",
                                "payment": null,
                                "status": "PENDING"
                            }
                        ],
                        "status": "PENDING"
                    },
                    "e0d05505-b6b0-4547-a8b5-5fbc5a85837f": {
                        "original_receivable_value": 4800,
                        "product": {
                            "name": "Agronomia - Bacharelado",
                            "description": "102014"
                        },
                        "fee": 0,
                        "interest": 0,
                        "fine": 0,
                        "taxes": 0,
                        "current_value": 4800,
                        "downpayment": {
                            "value": 4800,
                            "payment": null
                        },
                        "installments": [
                            {
                                "uuid": "b133d548-5e2a-4f58-b673-27f22d4d08ca",
                                "installment_number": 1,
                                "initial_value": 4800,
                                "fee": 0,
                                "interest": 0,
                                "fine": 0,
                                "taxes": 0,
                                "current_value": 4800,
                                "due_date": "2014-11-17",
                                "payment": null,
                                "status": "PENDING"
                            }
                        ],
                        "status": "PENDING"
                    },
                    "d83f9110-762b-4a7c-ab57-98647a5592a2": {
                        "original_receivable_value": 2000,
                        "product": {
                            "name": "Agronomia - Bacharelado",
                            "description": "102014"
                        },
                        "fee": 0,
                        "interest": 0,
                        "fine": 0,
                        "taxes": 0,
                        "current_value": 2000,
                        "downpayment": {
                            "value": 2000,
                            "payment": null
                        },
                        "installments": [
                            {
                                "uuid": "186d00bb-96c1-4c56-9e42-368fb932db15",
                                "installment_number": 1,
                                "initial_value": 2000,
                                "fee": 0,
                                "interest": 0,
                                "fine": 0,
                                "taxes": 0,
                                "current_value": 2000,
                                "due_date": "2014-11-17",
                                "payment": null,
                                "status": "PENDING"
                            }
                        ],
                        "status": "PENDING"
                    },
                    "a4209e86-9059-4a06-a951-9ac56acf1470": {
                        "original_receivable_value": 4800,
                        "product": {
                            "name": "Agronomia - Bacharelado",
                            "description": "102014"
                        },
                        "fee": 0,
                        "interest": 0,
                        "fine": 0,
                        "taxes": 0,
                        "current_value": 4800,
                        "downpayment": {
                            "value": 4800,
                            "payment": null
                        },
                        "installments": [
                            {
                                "uuid": "551e84ae-1761-439c-a09c-7721166e0c2a",
                                "installment_number": 1,
                                "initial_value": 4800,
                                "fee": 0,
                                "interest": 0,
                                "fine": 0,
                                "taxes": 0,
                                "current_value": 4800,
                                "due_date": "2014-11-17",
                                "payment": null,
                                "status": "PENDING"
                            }
                        ],
                        "status": "PENDING"
                    },
                    "837b05fc-a16c-4219-8b8e-80858d76ccde": {
                        "original_receivable_value": 25946,
                        "product": {
                            "name": "Agronomia - Bacharelado",
                            "description": "042014"
                        },
                        "fee": 0,
                        "interest": 0,
                        "fine": 0,
                        "taxes": 0,
                        "current_value": 25946,
                        "downpayment": {
                            "value": 25946,
                            "payment": null
                        },
                        "installments": [
                            {
                                "uuid": "57b9b57c-8467-465a-88f2-05bf1c011ac0",
                                "installment_number": 1,
                                "initial_value": 25946,
                                "fee": 0,
                                "interest": 0,
                                "fine": 0,
                                "taxes": 0,
                                "current_value": 25946,
                                "due_date": "2014-05-06",
                                "payment": null,
                                "status": "PENDING"
                            }
                        ],
                        "status": "PENDING"
                    },
                    "692da665-af79-4af9-9715-02b0047b50e6": {
                        "original_receivable_value": 25946,
                        "product": {
                            "name": "Agronomia - Bacharelado",
                            "description": "052014"
                        },
                        "fee": 0,
                        "interest": 0,
                        "fine": 0,
                        "taxes": 0,
                        "current_value": 25946,
                        "downpayment": {
                            "value": 25946,
                            "payment": null
                        },
                        "installments": [
                            {
                                "uuid": "df271474-fe7b-408c-89c3-94a804bacecb",
                                "installment_number": 1,
                                "initial_value": 25946,
                                "fee": 0,
                                "interest": 0,
                                "fine": 0,
                                "taxes": 0,
                                "current_value": 25946,
                                "due_date": "2014-05-08",
                                "payment": null,
                                "status": "PENDING"
                            }
                        ],
                        "status": "PENDING"
                    },
                    "58e7432e-f2f2-4861-ac1f-0e7011ec3341": {
                        "original_receivable_value": 25946,
                        "product": {
                            "name": "Agronomia - Bacharelado",
                            "description": "062014"
                        },
                        "fee": 0,
                        "interest": 0,
                        "fine": 0,
                        "taxes": 0,
                        "current_value": 25946,
                        "downpayment": {
                            "value": 25946,
                            "payment": null
                        },
                        "installments": [
                            {
                                "uuid": "c0a79147-90d1-4610-8db7-f892a9d618df",
                                "installment_number": 1,
                                "initial_value": 25946,
                                "fee": 0,
                                "interest": 0,
                                "fine": 0,
                                "taxes": 0,
                                "current_value": 25946,
                                "due_date": "2014-06-10",
                                "payment": null,
                                "status": "PENDING"
                            }
                        ],
                        "status": "PENDING"
                    },
                    "793f5b15-57c5-4c40-ad89-e0643fcc9d95": {
                        "original_receivable_value": 750,
                        "product": {
                            "name": "Agronomia - Bacharelado",
                            "description": "072014"
                        },
                        "fee": 0,
                        "interest": 0,
                        "fine": 0,
                        "taxes": 0,
                        "current_value": 750,
                        "downpayment": {
                            "value": 750,
                            "payment": null
                        },
                        "installments": [
                            {
                                "uuid": "19663d4f-1d07-4c04-aa7a-85974e90351f",
                                "installment_number": 1,
                                "initial_value": 750,
                                "fee": 0,
                                "interest": 0,
                                "fine": 0,
                                "taxes": 0,
                                "current_value": 750,
                                "due_date": "2014-07-07",
                                "payment": null,
                                "status": "PENDING"
                            }
                        ],
                        "status": "PENDING"
                    },
                    "cda86018-9d48-4526-a956-f5e886f5ca67": {
                        "original_receivable_value": 170027,
                        "product": {
                            "name": "Agronomia - Bacharelado",
                            "description": "062015"
                        },
                        "fee": 0,
                        "interest": 0,
                        "fine": 0,
                        "taxes": 0,
                        "current_value": 170027,
                        "downpayment": {
                            "value": 170027,
                            "payment": null
                        },
                        "installments": [
                            {
                                "uuid": "b722fa1f-f77b-45ec-80fa-b2eb50bf4b2a",
                                "installment_number": 1,
                                "initial_value": 170027,
                                "fee": 0,
                                "interest": 0,
                                "fine": 0,
                                "taxes": 0,
                                "current_value": 170027,
                                "due_date": "2015-11-03",
                                "payment": null,
                                "status": "PENDING"
                            }
                        ],
                        "status": "PENDING"
                    }
                }
            }
        },
        "errors": null
    },
    getAgreementsResponse:{
        "payload": {
          "agreements": [
            {
              "uuid": "0874330d-1d30-447a-8bae-33538e490b8e",
              "original_receivables_uuids": [
                "1a3db049-c7ab-48a2-a868-3c004089501e",
                "50227a3c-13f0-43ea-b84f-56228e4983e5",
                "d80d899a-0b97-48b7-86a7-297c6be0395e",
                "92ce169d-42f5-4169-bea2-bafdf0eefa7e",
                "86a7e3fd-e384-4fd3-a3f5-0085e150a593",
                "632b948d-18f5-4ce9-9fb6-9aadadd30cf9",
                "5f44221a-80d4-452a-814e-a403a6a9cd68",
                "259a3930-5cb6-4f36-9ff9-265ebd7e40b0",
                "dabb7d75-9974-44bd-9d28-cd06541b439f",
                "f5abd0e3-c666-47d2-b773-65583c9d7525",
                "8fd28933-d477-4b05-99e6-85573e693d48",
                "ae110245-76e3-414d-88eb-f34e19e0fb13",
                "7f93e3ed-42bd-4d8b-8743-5fe89fe7521c",
                "9bd21dd0-e59c-48b5-88af-631075fc4edf",
                "82d4c94e-1cc4-44a5-8ff6-2121d7db470d",
                "37a500bd-7ba5-4a31-94ab-c98e3f17e673",
                "7a623241-a149-4141-a6ad-a3d89fafe3b6",
                "2327444c-2886-4b88-8029-436ba5a3fe91",
                "a23c18af-11f7-49c2-936e-42926a749113",
                "5765ec08-929f-4560-a67f-1a1577119c82",
                "86e29d59-5666-41c4-9224-7a03d1e32129"
              ],
              "current_receivable_uuid": "b624784c-170d-403d-8da4-eb0ab028e1c7"
            }
          ],
          "aggregates": {
            "receivables": {
              "1a3db049-c7ab-48a2-a868-3c004089501e": {
                "original_receivable_value": 43217,
                "product": {
                  "name": "CST em Logística",
                  "description": "092018"
                },
                "fee": 0,
                "interest": 0,
                "fine": 0,
                "taxes": 0,
                "current_value": 43217,
                "downpayment": {
                  "value": 43217,
                  "payment": null
                },
                "installments": [
                  {
                    "uuid": "7934f3b9-794c-49f4-bae2-a6fe3e097c2f",
                    "installment_number": 1,
                    "initial_value": 43217,
                    "fee": 0,
                    "interest": 0,
                    "fine": 0,
                    "taxes": 0,
                    "current_value": 43217,
                    "due_date": "2018-09-10",
                    "payment": null,
                    "status": "NEGOTIATED"
                  }
                ],
                "status": "NEGOTIATED"
              },
              "50227a3c-13f0-43ea-b84f-56228e4983e5": {
                "original_receivable_value": 43217,
                "product": {
                  "name": "CST em Logística",
                  "description": "102018"
                },
                "fee": 0,
                "interest": 0,
                "fine": 0,
                "taxes": 0,
                "current_value": 43217,
                "downpayment": {
                  "value": 43217,
                  "payment": null
                },
                "installments": [
                  {
                    "uuid": "4e269764-3551-40c5-8783-6f4b0f62b6d9",
                    "installment_number": 1,
                    "initial_value": 43217,
                    "fee": 0,
                    "interest": 0,
                    "fine": 0,
                    "taxes": 0,
                    "current_value": 43217,
                    "due_date": "2018-10-05",
                    "payment": null,
                    "status": "NEGOTIATED"
                  }
                ],
                "status": "NEGOTIATED"
              },
              "d80d899a-0b97-48b7-86a7-297c6be0395e": {
                "original_receivable_value": 43217,
                "product": {
                  "name": "CST em Logística",
                  "description": "112018"
                },
                "fee": 0,
                "interest": 0,
                "fine": 0,
                "taxes": 0,
                "current_value": 43217,
                "downpayment": {
                  "value": 43217,
                  "payment": null
                },
                "installments": [
                  {
                    "uuid": "2453f0c2-e7ac-4e56-8c07-89e865738af3",
                    "installment_number": 1,
                    "initial_value": 43217,
                    "fee": 0,
                    "interest": 0,
                    "fine": 0,
                    "taxes": 0,
                    "current_value": 43217,
                    "due_date": "2018-11-08",
                    "payment": null,
                    "status": "NEGOTIATED"
                  }
                ],
                "status": "NEGOTIATED"
              },
              "92ce169d-42f5-4169-bea2-bafdf0eefa7e": {
                "original_receivable_value": 43217,
                "product": {
                  "name": "CST em Logística",
                  "description": "122018"
                },
                "fee": 0,
                "interest": 0,
                "fine": 0,
                "taxes": 0,
                "current_value": 43217,
                "downpayment": {
                  "value": 43217,
                  "payment": null
                },
                "installments": [
                  {
                    "uuid": "db4bec85-44d2-4562-8303-6fd58b752206",
                    "installment_number": 1,
                    "initial_value": 43217,
                    "fee": 0,
                    "interest": 0,
                    "fine": 0,
                    "taxes": 0,
                    "current_value": 43217,
                    "due_date": "2018-12-07",
                    "payment": null,
                    "status": "NEGOTIATED"
                  }
                ],
                "status": "NEGOTIATED"
              },
              "86a7e3fd-e384-4fd3-a3f5-0085e150a593": {
                "original_receivable_value": 43217,
                "product": {
                  "name": "CST em Logística",
                  "description": "092018"
                },
                "fee": 0,
                "interest": 0,
                "fine": 0,
                "taxes": 0,
                "current_value": 43217,
                "downpayment": {
                  "value": 43217,
                  "payment": null
                },
                "installments": [
                  {
                    "uuid": "8c31abff-ef1b-483b-a7c8-2771161a9f4e",
                    "installment_number": 1,
                    "initial_value": 43217,
                    "fee": 0,
                    "interest": 0,
                    "fine": 0,
                    "taxes": 0,
                    "current_value": 43217,
                    "due_date": "2018-09-10",
                    "payment": null,
                    "status": "NEGOTIATED"
                  }
                ],
                "status": "NEGOTIATED"
              },
              "632b948d-18f5-4ce9-9fb6-9aadadd30cf9": {
                "original_receivable_value": 43217,
                "product": {
                  "name": "CST em Logística",
                  "description": "102018"
                },
                "fee": 0,
                "interest": 0,
                "fine": 0,
                "taxes": 0,
                "current_value": 43217,
                "downpayment": {
                  "value": 43217,
                  "payment": null
                },
                "installments": [
                  {
                    "uuid": "00384ac8-d00f-4153-b14e-d90669b2ee9a",
                    "installment_number": 1,
                    "initial_value": 43217,
                    "fee": 0,
                    "interest": 0,
                    "fine": 0,
                    "taxes": 0,
                    "current_value": 43217,
                    "due_date": "2018-10-05",
                    "payment": null,
                    "status": "NEGOTIATED"
                  }
                ],
                "status": "NEGOTIATED"
              },
              "5f44221a-80d4-452a-814e-a403a6a9cd68": {
                "original_receivable_value": 43217,
                "product": {
                  "name": "CST em Logística",
                  "description": "112018"
                },
                "fee": 0,
                "interest": 0,
                "fine": 0,
                "taxes": 0,
                "current_value": 43217,
                "downpayment": {
                  "value": 43217,
                  "payment": null
                },
                "installments": [
                  {
                    "uuid": "633af2bd-e80b-4d34-9d79-021730463b97",
                    "installment_number": 1,
                    "initial_value": 43217,
                    "fee": 0,
                    "interest": 0,
                    "fine": 0,
                    "taxes": 0,
                    "current_value": 43217,
                    "due_date": "2018-11-08",
                    "payment": null,
                    "status": "NEGOTIATED"
                  }
                ],
                "status": "NEGOTIATED"
              },
              "259a3930-5cb6-4f36-9ff9-265ebd7e40b0": {
                "original_receivable_value": 43217,
                "product": {
                  "name": "CST em Logística",
                  "description": "122018"
                },
                "fee": 0,
                "interest": 0,
                "fine": 0,
                "taxes": 0,
                "current_value": 43217,
                "downpayment": {
                  "value": 43217,
                  "payment": null
                },
                "installments": [
                  {
                    "uuid": "c0762ca2-7ec3-49fa-abfe-b0551ccb274d",
                    "installment_number": 1,
                    "initial_value": 43217,
                    "fee": 0,
                    "interest": 0,
                    "fine": 0,
                    "taxes": 0,
                    "current_value": 43217,
                    "due_date": "2018-12-07",
                    "payment": null,
                    "status": "NEGOTIATED"
                  }
                ],
                "status": "NEGOTIATED"
              },
              "dabb7d75-9974-44bd-9d28-cd06541b439f": {
                "original_receivable_value": 43217,
                "product": {
                  "name": "CST em Logística",
                  "description": "092018"
                },
                "fee": 0,
                "interest": 0,
                "fine": 0,
                "taxes": 0,
                "current_value": 43217,
                "downpayment": {
                  "value": 43217,
                  "payment": null
                },
                "installments": [
                  {
                    "uuid": "95a1885b-2d68-4ea5-86be-3602c9235fcf",
                    "installment_number": 1,
                    "initial_value": 43217,
                    "fee": 0,
                    "interest": 0,
                    "fine": 0,
                    "taxes": 0,
                    "current_value": 43217,
                    "due_date": "2018-09-10",
                    "payment": null,
                    "status": "NEGOTIATED"
                  }
                ],
                "status": "NEGOTIATED"
              },
              "f5abd0e3-c666-47d2-b773-65583c9d7525": {
                "original_receivable_value": 43217,
                "product": {
                  "name": "CST em Logística",
                  "description": "102018"
                },
                "fee": 0,
                "interest": 0,
                "fine": 0,
                "taxes": 0,
                "current_value": 43217,
                "downpayment": {
                  "value": 43217,
                  "payment": null
                },
                "installments": [
                  {
                    "uuid": "0f24ff19-89b5-4db7-a01c-777fe75b3351",
                    "installment_number": 1,
                    "initial_value": 43217,
                    "fee": 0,
                    "interest": 0,
                    "fine": 0,
                    "taxes": 0,
                    "current_value": 43217,
                    "due_date": "2018-10-05",
                    "payment": null,
                    "status": "NEGOTIATED"
                  }
                ],
                "status": "NEGOTIATED"
              },
              "8fd28933-d477-4b05-99e6-85573e693d48": {
                "original_receivable_value": 43217,
                "product": {
                  "name": "CST em Logística",
                  "description": "112018"
                },
                "fee": 0,
                "interest": 0,
                "fine": 0,
                "taxes": 0,
                "current_value": 43217,
                "downpayment": {
                  "value": 43217,
                  "payment": null
                },
                "installments": [
                  {
                    "uuid": "5bd5aa0a-be3f-4479-b6f8-ade7e6943a31",
                    "installment_number": 1,
                    "initial_value": 43217,
                    "fee": 0,
                    "interest": 0,
                    "fine": 0,
                    "taxes": 0,
                    "current_value": 43217,
                    "due_date": "2018-11-08",
                    "payment": null,
                    "status": "NEGOTIATED"
                  }
                ],
                "status": "NEGOTIATED"
              },
              "ae110245-76e3-414d-88eb-f34e19e0fb13": {
                "original_receivable_value": 43217,
                "product": {
                  "name": "CST em Logística",
                  "description": "122018"
                },
                "fee": 0,
                "interest": 0,
                "fine": 0,
                "taxes": 0,
                "current_value": 43217,
                "downpayment": {
                  "value": 43217,
                  "payment": null
                },
                "installments": [
                  {
                    "uuid": "b95d0fdd-0de5-4302-8b54-a178499df44c",
                    "installment_number": 1,
                    "initial_value": 43217,
                    "fee": 0,
                    "interest": 0,
                    "fine": 0,
                    "taxes": 0,
                    "current_value": 43217,
                    "due_date": "2018-12-07",
                    "payment": null,
                    "status": "NEGOTIATED"
                  }
                ],
                "status": "NEGOTIATED"
              },
              "7f93e3ed-42bd-4d8b-8743-5fe89fe7521c": {
                "original_receivable_value": 43217,
                "product": {
                  "name": "CST em Logística",
                  "description": "092018"
                },
                "fee": 0,
                "interest": 0,
                "fine": 0,
                "taxes": 0,
                "current_value": 43217,
                "downpayment": {
                  "value": 43217,
                  "payment": null
                },
                "installments": [
                  {
                    "uuid": "a06294c6-d088-436f-b421-01dc907dd545",
                    "installment_number": 1,
                    "initial_value": 43217,
                    "fee": 0,
                    "interest": 0,
                    "fine": 0,
                    "taxes": 0,
                    "current_value": 43217,
                    "due_date": "2018-09-10",
                    "payment": null,
                    "status": "NEGOTIATED"
                  }
                ],
                "status": "NEGOTIATED"
              },
              "9bd21dd0-e59c-48b5-88af-631075fc4edf": {
                "original_receivable_value": 43217,
                "product": {
                  "name": "CST em Logística",
                  "description": "102018"
                },
                "fee": 0,
                "interest": 0,
                "fine": 0,
                "taxes": 0,
                "current_value": 43217,
                "downpayment": {
                  "value": 43217,
                  "payment": null
                },
                "installments": [
                  {
                    "uuid": "185f2ad5-aa4d-46f1-86c8-3df563466f72",
                    "installment_number": 1,
                    "initial_value": 43217,
                    "fee": 0,
                    "interest": 0,
                    "fine": 0,
                    "taxes": 0,
                    "current_value": 43217,
                    "due_date": "2018-10-05",
                    "payment": null,
                    "status": "NEGOTIATED"
                  }
                ],
                "status": "NEGOTIATED"
              },
              "82d4c94e-1cc4-44a5-8ff6-2121d7db470d": {
                "original_receivable_value": 43217,
                "product": {
                  "name": "CST em Logística",
                  "description": "112018"
                },
                "fee": 0,
                "interest": 0,
                "fine": 0,
                "taxes": 0,
                "current_value": 43217,
                "downpayment": {
                  "value": 43217,
                  "payment": null
                },
                "installments": [
                  {
                    "uuid": "e1ee048e-906f-467f-8f62-2e3144d2577a",
                    "installment_number": 1,
                    "initial_value": 43217,
                    "fee": 0,
                    "interest": 0,
                    "fine": 0,
                    "taxes": 0,
                    "current_value": 43217,
                    "due_date": "2018-11-08",
                    "payment": null,
                    "status": "NEGOTIATED"
                  }
                ],
                "status": "NEGOTIATED"
              },
              "37a500bd-7ba5-4a31-94ab-c98e3f17e673": {
                "original_receivable_value": 43217,
                "product": {
                  "name": "CST em Logística",
                  "description": "122018"
                },
                "fee": 0,
                "interest": 0,
                "fine": 0,
                "taxes": 0,
                "current_value": 43217,
                "downpayment": {
                  "value": 43217,
                  "payment": null
                },
                "installments": [
                  {
                    "uuid": "11a4d028-e3ae-4a5e-b445-75a50ab0e6db",
                    "installment_number": 1,
                    "initial_value": 43217,
                    "fee": 0,
                    "interest": 0,
                    "fine": 0,
                    "taxes": 0,
                    "current_value": 43217,
                    "due_date": "2018-12-07",
                    "payment": null,
                    "status": "NEGOTIATED"
                  }
                ],
                "status": "NEGOTIATED"
              },
              "7a623241-a149-4141-a6ad-a3d89fafe3b6": {
                "original_receivable_value": 12965,
                "product": {
                  "name": "CST em Logística",
                  "description": "102018"
                },
                "fee": 0,
                "interest": 0,
                "fine": 0,
                "taxes": 0,
                "current_value": 12965,
                "downpayment": {
                  "value": 12965,
                  "payment": null
                },
                "installments": [
                  {
                    "uuid": "14adae01-a9c7-44d8-bd0e-eb2c47702936",
                    "installment_number": 1,
                    "initial_value": 12965,
                    "fee": 0,
                    "interest": 0,
                    "fine": 0,
                    "taxes": 0,
                    "current_value": 12965,
                    "due_date": "2018-10-05",
                    "payment": null,
                    "status": "NEGOTIATED"
                  }
                ],
                "status": "NEGOTIATED"
              },
              "2327444c-2886-4b88-8029-436ba5a3fe91": {
                "original_receivable_value": 12965,
                "product": {
                  "name": "CST em Logística",
                  "description": "112018"
                },
                "fee": 0,
                "interest": 0,
                "fine": 0,
                "taxes": 0,
                "current_value": 12965,
                "downpayment": {
                  "value": 12965,
                  "payment": null
                },
                "installments": [
                  {
                    "uuid": "8190903e-ead1-4dc4-aa0c-4b4f9db9dd86",
                    "installment_number": 1,
                    "initial_value": 12965,
                    "fee": 0,
                    "interest": 0,
                    "fine": 0,
                    "taxes": 0,
                    "current_value": 12965,
                    "due_date": "2018-11-08",
                    "payment": null,
                    "status": "NEGOTIATED"
                  }
                ],
                "status": "NEGOTIATED"
              },
              "a23c18af-11f7-49c2-936e-42926a749113": {
                "original_receivable_value": 12965,
                "product": {
                  "name": "CST em Logística",
                  "description": "122018"
                },
                "fee": 0,
                "interest": 0,
                "fine": 0,
                "taxes": 0,
                "current_value": 12965,
                "downpayment": {
                  "value": 12965,
                  "payment": null
                },
                "installments": [
                  {
                    "uuid": "eec18707-b13d-48c6-8543-e1147f8d6b3a",
                    "installment_number": 1,
                    "initial_value": 12965,
                    "fee": 0,
                    "interest": 0,
                    "fine": 0,
                    "taxes": 0,
                    "current_value": 12965,
                    "due_date": "2018-12-07",
                    "payment": null,
                    "status": "NEGOTIATED"
                  }
                ],
                "status": "NEGOTIATED"
              },
              "5765ec08-929f-4560-a67f-1a1577119c82": {
                "original_receivable_value": 60839,
                "product": {
                  "name": "CST em Logística",
                  "description": "092019"
                },
                "fee": 0,
                "interest": 0,
                "fine": 0,
                "taxes": 0,
                "current_value": 60839,
                "downpayment": {
                  "value": 60839,
                  "payment": null
                },
                "installments": [
                  {
                    "uuid": "7fb631dd-3bd9-44dd-8179-1c45a4cd6f86",
                    "installment_number": 1,
                    "initial_value": 60839,
                    "fee": 0,
                    "interest": 0,
                    "fine": 0,
                    "taxes": 0,
                    "current_value": 60839,
                    "due_date": "2019-09-10",
                    "payment": null,
                    "status": "NEGOTIATED"
                  }
                ],
                "status": "NEGOTIATED"
              },
              "86e29d59-5666-41c4-9224-7a03d1e32129": {
                "original_receivable_value": 60839,
                "product": {
                  "name": "CST em Logística",
                  "description": "102019"
                },
                "fee": 0,
                "interest": 0,
                "fine": 0,
                "taxes": 0,
                "current_value": 60839,
                "downpayment": {
                  "value": 60839,
                  "payment": null
                },
                "installments": [
                  {
                    "uuid": "f8be716b-37fd-465a-8755-6a4099a855c7",
                    "installment_number": 1,
                    "initial_value": 60839,
                    "fee": 0,
                    "interest": 0,
                    "fine": 0,
                    "taxes": 0,
                    "current_value": 60839,
                    "due_date": "2019-10-10",
                    "payment": null,
                    "status": "NEGOTIATED"
                  }
                ],
                "status": "NEGOTIATED"
              },
              "b624784c-170d-403d-8da4-eb0ab028e1c7": {
                "original_receivable_value": 172868,
                "product": {
                  "name": "Negociação Kroton",
                  "description": null
                },
                "fee": 33499,
                "interest": 16749,
                "fine": 1210,
                "taxes": 0,
                "current_value": 111961,
                "downpayment": {
                  "value": 0,
                  "payment": null
                },
                "installments": [
                  {
                    "uuid": "5cea9410-8cf3-4927-b8f7-ba6b0cd13c2f",
                    "installment_number": 1,
                    "initial_value": 172868,
                    "fee": 33499,
                    "interest": 16749,
                    "fine": 1210,
                    "taxes": 0,
                    "current_value": 111961,
                    "due_date": "2023-05-16",
                    "payment": {
                      "downpayment": false,
                      "payment_date": null,
                      "paid_value": null,
                      "payment_method": "BANKSLIP",
                      "payment_info": {
                        "digit_line": "03399054739990000045823716601010293520000111962",
                        "due_date": "2023-05-16",
                        "url": ""
                      }
                    },
                    "status": "OPENED"
                  }
                ],
                "status": "AWAITING_PAYMENT"
              }
            }
          }
        },
        "errors": null
      } as TAgreementResponse
}