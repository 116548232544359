import { Client } from "../../entities/Client/Client"

type TEmailDTO = {
    email: string,
    verified: boolean,
    main: boolean
}

type TPhoneDTO = {
    phone_number: string,
    verified: boolean,
    main: boolean
}

type TClientDTO = {
    uuid: string,
    documentNumber: string,
    dateOfBirth: string,
    name: string,
    emails: TEmailDTO[],
    phones: TPhoneDTO[],
    verified: boolean
}

export const clientConverter = (dto: TClientDTO): Client => {
    return {
        type: "Client",
        uuid: dto.uuid,
        documentNumber: dto.documentNumber,
        dateOfBirth: dto.dateOfBirth,
        name: dto.name,
        emails: dto.emails.map(email => email.email),
        phones: dto.phones.map(phone => phone.phone_number),
        verified: dto.verified
    } as Client
}