import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { accessLinkValidationThunk, selectAccessLinkError, signInThunk } from "../../../common/features/AuthForm/authFormSlice";
import { selectUserIsAuthenticated } from "../../../common/features/AuthForm/authFormSlice";
import { useEffect } from "react";
import { useCookies } from "react-cookie"
import { selectDocumentNumber } from "../../../common/features/AuthForm/authFormSlice";
import { postLogThunk } from "../../components/logs/logsThunk";

export const AccessLinkPage = () => {
    let { token } = useParams();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [cookies] = useCookies(['accessUUID']);
    let accessUUID = cookies.accessUUID

    const userIsAuthenticated = useAppSelector(selectUserIsAuthenticated);
    const documentNumber = useAppSelector(selectDocumentNumber);
    const accessLinkError = useAppSelector(selectAccessLinkError);

    useEffect(() => {
        if(userIsAuthenticated) {
            navigate('/contratos')
        }
    }, [userIsAuthenticated])

    useEffect(() => {
        dispatch(postLogThunk(
            {
                event: 'ENTERED_ACCESS_LINK_PAGE',
                documentNumber: null,
                formData: null,
                details: {token: token},
                accessUUID: accessUUID
            }
        ))

        if(token) {
            dispatch(accessLinkValidationThunk(
                token=token
            ))
        }else{
            navigate('/')
        }
    }, [])

    useEffect(() => {
        if(accessLinkError) {
            navigate('/')
        }
    }, [accessLinkError])

    useEffect(() => {
        if(documentNumber && documentNumber.length > 0) {
            dispatch(
                signInThunk(
                    {
                        documentNumber: documentNumber,
                        accessUUID: accessUUID,
                        dispatch: dispatch
                    }
                )
            )
        }
    }, [documentNumber])

    

}