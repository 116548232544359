import React from "react"
import { HeaderBar, HeaderBarContent, LogoHeader } from "./styled"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { resetAuthForm, selectDocumentNumber } from "../../../common/features/AuthForm/authFormSlice"
import { resetNegotiation } from "../../components/Receivable/Negotiation/negotiationSlice"
import { resetReceivablesList } from "../../components/Receivable/ListPanel/receivableListSlice"
import { useNavigate } from "react-router-dom"
import './index.css'
import { useCookies } from "react-cookie"
import { postLogThunk } from "../../components/logs/logsThunk"

export interface HeaderType {
    link: string,
    logoURL: string,
    showLogout?: boolean
}

export const defaultValues = {
    link: "https://alunoemdia.com.br/",
    logoURL: "/media/img/alunoemdia.png",
}

export const Header = ({
    link=defaultValues.link,
    logoURL=defaultValues.logoURL,
    showLogout=false,
    ...props
}) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const documentNumber = useAppSelector(selectDocumentNumber)
    const [cookies] = useCookies(['accessUUID']);
    const accessUUID = cookies.accessUUID

    const logoutFunction = () => {
        dispatch(resetAuthForm())
        dispatch(resetNegotiation())
        dispatch(resetReceivablesList())

        navigate('/')
    }

    const returnLogoutButton = () => {
        if(showLogout){
            //Não estou usando um BUTTON abaixo por conta de alguma estilização de algum framework
            //que está afetando o funcionamento do botão.
            return(
                <button
                    className="
                        w-8 h-8 md:absolute ml-5 md:ml-0
                        md:right-20 border-0 shadow-none
                        hover:bg-transparent active:animate-none
                        logout-button box-content
                        "
                    onClick={() => {
                        dispatch(postLogThunk(
                            {
                                event: 'PRESSED_LOGOUT_BUTTON',
                                documentNumber: documentNumber,
                                formData: null,
                                details: null,
                                accessUUID: accessUUID
                            }
                        ))
                        logoutFunction()

                    }}>
                    <svg
                        className="w-full h-full"
                        fill="#f8f8f8" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" stroke="#f8f8f8">
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                            <path d="M12.207 9H5V7h7.136L11.05 5.914 12.464 4.5 16 8.036l-3.536 3.535-1.414-1.414L12.207 9zM10 4H8V2H2v12h6v-2h2v4H0V0h10v4z" fillRule="evenodd">
                            </path>
                        </g>
                    </svg>
                </button>
            )
        }

    }

    return (
        <>
            <HeaderBar>
                <HeaderBarContent>
                    <a href='' onClick={() => {
                        navigate('/')
                    }} rel="noreferrer">
                        <LogoHeader 
                            src={logoURL}
                        />
                    </a>
                    {returnLogoutButton()}
                </HeaderBarContent>
            </HeaderBar>
        </>
    )
}

export default Header;
