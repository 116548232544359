import { createAsyncThunk } from "@reduxjs/toolkit"
import { CreateAgreementResponse } from "../../../../common/entities/Agreement/Agreement";
import { PreAgreement } from "../../../../common/entities/PreAgreement/PreAgreement";
import { limitsGateway, preAgreementGateway } from "../../../../common/gateways/preAgreementGateway";
import { createAgreementGateway, getAvailablePaymentDatesGateway, getCADGateway, getTCDGateway, putContactInfo } from "../../../../common/gateways/negotiationGateway";
import { paymentGateway } from "../../../../common/gateways/paymentGateway";
import { Payment } from "../../../../common/entities/Receivable/Payment";
import { GatewayError, TGatewayError } from "../../../../common/gateways/errors";
import { delay } from "../../../../common/utils/delay";
import { AppDispatch } from "../../../store";
import { postLogThunk } from "../../logs/logsThunk";

interface IGetPreagreementThunk {
    negotiationOptionUUID: string
    negotiationOptionItemsUuids: string[]
    paymentMethod: string
    numberOfInstallments: number
    paymentDate: string
}

interface IGetNegotiationLimitsThunk {
    negotiationOptionUUID: string
    negotiationOptionItemsUUIDs: string[]
    paymentMethod: string
    documentNumber: string
}

interface ICreateAgreementThunk {
    preAgreement: PreAgreement;
    phone: string | null;
    email: string | null;
    documentNumber: string;
    dispatch: AppDispatch;
    accessUUID: string;
}

interface IgetTCDThunk {
    agreement: CreateAgreementResponse;
    dispatch: AppDispatch;
    accessUUID: string;
}

interface IgetTCDandCADThunk {
    agreement: CreateAgreementResponse;
    clientGeoLocation: {latitude: string, longitude: string};
    documentNumber: string;
    dispatch: AppDispatch;
    accessUUID: string;
}

interface IgetAvailablePaymentDates {
    negotiationOptionUUID: string;
    paymentMethod: string
}

interface IpostNegotiationLimits {
    negotiationOptionUUID: string;
    
}


export const getNegotiationLimitsThunk = createAsyncThunk(
    'negotiation/getNegotiationLimits',
    async ({
        negotiationOptionUUID,
        negotiationOptionItemsUUIDs,
        paymentMethod,
        documentNumber
    }: IGetNegotiationLimitsThunk) => {


        return await limitsGateway(
            {
                negotiationOptionUUID: negotiationOptionUUID,
                negotiationOptionItemsUuids: negotiationOptionItemsUUIDs,
                paymentMethod: paymentMethod,
                document_number: documentNumber
            }
        )
    }
)

export const getPreagreementThunk = createAsyncThunk(
    'negotiation/getPreagreement',
    async ({
        negotiationOptionUUID,
        negotiationOptionItemsUuids, 
        numberOfInstallments,
        paymentMethod,
        paymentDate
    }: IGetPreagreementThunk) => {

        return await preAgreementGateway(
            {
                negotiationOptionUUID: negotiationOptionUUID,
                negotiationOptionItemsUuids: negotiationOptionItemsUuids,
                numberOfInstallments: numberOfInstallments,
                paymentMethod: paymentMethod,
                paymentDate: paymentDate
            }
        )
    }
)

export const CreateAgreementThunk = createAsyncThunk(
    'negotiation/createAgreement',
    async ({
        preAgreement,
        email,
        phone,
        documentNumber,
        dispatch,
        accessUUID
    }: ICreateAgreementThunk) => {
        const preAgreementUUID = preAgreement.uuid
        let correctEmail = null
        let correctPhone = null

        if(email && email.length > 0){
            correctEmail = email
        }

        if(phone && phone.length > 0){
            correctPhone = phone
        }

        const responseContact = await putContactInfo(
            {
                email: correctEmail,
                phone: correctPhone,
                documentNumber: documentNumber
            }
        ).catch(e => {
            return GatewayError('DEV500')
        })

        dispatch(postLogThunk(
            {
                event: 'PUT_CONTACT_INFO_GATEWAY',
                documentNumber: documentNumber,
                formData: {
                    email: correctEmail,
                    phone: correctPhone,
                    documentNumber: documentNumber
                },
                details: {
                    response: responseContact
                },
                accessUUID: accessUUID
            }
        ))

        const responseAgreement = await createAgreementGateway(
            {
                preAgreementUUID
            }
        ).catch(e => {
            return GatewayError('DEV500')
        })

        dispatch(postLogThunk(
            {
                event: 'CREATE_AGREEMENT_GATEWAY',
                documentNumber: documentNumber,
                formData: {
                    preAgreementUUID
                },
                details: {
                    response: responseAgreement
                },
                accessUUID: accessUUID
            }
        ))

        return responseAgreement
    }
)

// export const getTCDThunk = createAsyncThunk(
//     'negotiation/getTCDThunk',
//     async ({
//         agreement,
//     }: IgetTCDThunk) => {
//         const agreementUUID = agreement.uuid
//         return await getTCDGateway(
//             {agreementUUID}
//         )
//     }
// )

export const getTCDandCADThunk = createAsyncThunk(
    'negotiation/getCADThunk',
    async ({
        agreement,
        clientGeoLocation,
        documentNumber,
        dispatch,
        accessUUID
    }: IgetTCDandCADThunk) => {
        const agreementUUID = agreement.uuid
        const responses: {tcd:any, cad: any}  = {tcd:null, cad: null}

        responses.tcd =  await getTCDGateway(
            {agreementUUID}
        )
        delay(100)
        responses.cad = await getCADGateway(
            {agreementUUID, clientGeoLocation}
        )

        dispatch(postLogThunk(
            {
                event: 'GET_CAD_GATEWAY',
                documentNumber: documentNumber,
                formData: {agreementUUID: agreementUUID, clientGeoLocation: clientGeoLocation},
                details: responses.cad,
                accessUUID: accessUUID
            }
        ))
        dispatch(postLogThunk(
            {
                event: 'GET_TCD_GATEWAY',
                documentNumber: documentNumber,
                formData: {agreementUUID: agreementUUID},
                details: responses.tcd,
                accessUUID: accessUUID
            }
        ))

        return responses
    }
)

export const getNegotiationReceivablePaymentThunk = createAsyncThunk(
    'negotiation/getNegotiationReceivablePaymentThunk',
    async ({receivableUUID, installmentNumber}: {receivableUUID: string; installmentNumber: number}): Promise<[string, number, Payment]  | TGatewayError> => {
        return await paymentGateway({receivableUUID, installmentNumber})
    }
)

export const getAvailablePaymentDates = createAsyncThunk(
    'negotiation/getAvailablePaymentDates',
    async ({
        negotiationOptionUUID,
        paymentMethod
    }: IgetAvailablePaymentDates) => {
        return await getAvailablePaymentDatesGateway({negotiationOptionUUID, paymentMethod})
    }
)